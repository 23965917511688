import Icon from "@ant-design/icons";

const icon = () => (
  
<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="45" height="45" rx="12.8571" fill="#DCDEE3"/>
<path d="M30.1423 19.2592C30.1423 19.5869 30.0273 19.8634 29.7971 20.0887L23.5942 26.2229C23.3118 26.5096 23.0084 26.6427 22.6423 26.6427C22.2762 26.6427 21.9624 26.5096 21.6905 26.2331L15.4875 20.0887C15.2574 19.8634 15.1423 19.5869 15.1423 19.2592C15.1423 18.6038 15.6758 18.0713 16.3453 18.0713C16.68 18.0713 16.9833 18.2044 17.2344 18.44L22.6528 23.8265L28.0607 18.44C28.3013 18.2044 28.6047 18.0713 28.9394 18.0713C29.6089 18.0713 30.1423 18.6038 30.1423 19.2592Z" fill="#ACAEB9"/>
</svg>





);

const Icon_Bottom_Order_Disable = (props) => <Icon component={icon} {...props} />;

export default Icon_Bottom_Order_Disable;

