import axios from "axios";
import environment from "../environment/environment";

const url = environment.SERVICE_URL_DASHBOARD;
const SERVICE_URL_EV = environment.SERVICE_URL_EV;

    const getSurveyForm = async (dateType, interval) => {
        try {
        const response = await axios.get(`${url}/survey-form`, {
            params: {
                dateType: dateType,
                interval: interval
            }
        });
        console.log('Response from getSurveyForm:', response.data);
        return response.data;
        } catch (error) {
        console.error('Error fetching data getSurveyForm error:', error);
        throw error;
        }
    };

    const getNonVoiceReport = async (channelId) => {
        try {
        const response = await axios.get(`${url}/non-voice-report`, {
            params: {
                ChannelId: channelId,
            }
        });
        console.log('Response from getNonVoiceReport:', response.data);
        return response.data;
        } catch (error) {
        console.error('Error fetching data getNonVoiceReport error:', error);
        throw error;
        }
    };

    const getVoiceReport = async (channelId) => {
        try {
        const response = await axios.get(`${url}/voice-report`, {
            params: {
                ChannelId: channelId,
            }
        });
        console.log('Response from getVoiceReport:', response.data);
        return response.data;
        } catch (error) {
        console.error('Error fetching data getVoiceReport error:', error);
        throw error;
        }
    };

    const getChannelOption = async () => {
        try {
          // Assuming you're using axios and the API expects SurveyFormId as a query parameter
          const response = await axios.get(`${SERVICE_URL_EV}/survey-channels`);
          console.log('getChannelOption :', response.data);
          return response.data;
        } catch (error) {
          console.error('Error fetching data:', error);
          throw error;
        }
      };

export{
    getSurveyForm,
    getNonVoiceReport,
    getVoiceReport,
    getChannelOption
}