import Icon from "@ant-design/icons";

const icon = () => (
  

<svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 12.8418H6V10.8418H0V12.8418ZM0 5.8418V7.8418H12V5.8418H0ZM0 0.841797V2.8418H18V0.841797H0Z" fill="#667085"/>
</svg>


  


);

const Icon_Textarea_Unselect = (props) => <Icon component={icon} {...props} />;

export default Icon_Textarea_Unselect;

