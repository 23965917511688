import React, { useEffect, useState, useRef, useCallback, createContext } from "react";
import ReactDOM from "react-dom";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Table,
  Dropdown,
  Space,
  Button,
  Form,
  Input,
  Flex,
  Drawer,
  Row,
  Col,
  Select,
  message,
  InputNumber,
  Modal,
  Tooltip,
  Tag,
  Radio,
  Switch,
  Checkbox,
} from "antd";
import {
  ArrowLeftOutlined,
  FormOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import IconEdit from "../assets/icon/icon_edit";
import IconClose from "../assets/icon/close.png";
import IconCopyVersion from "../assets/icon/icon_copy_version_survey";
import IconConfirmSubmit from "../assets/icon/icon_confirm_submit";
import IconSuccessDialog from "../assets/icon/icon_success_dialog";
import IconFailedDialog from "../assets/icon/icon_failed_dialog";
import IconLogo from "../assets/icon/icon_logo";
import IconData from "../assets/icon/icon_no_data";
import SunEditor from "suneditor-react";

import SurveyFormService from "../service/surveyFormService";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
  video,
} from "suneditor/src/plugins";
import "suneditor/dist/css/suneditor.min.css";
import IconArrowLeft from "../assets/icon/arrow_left";
import Utills from "./Utills";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import {arrayMoveImmutable} from 'array-move';
import QuestionCreate from "./QuestionCreate";
import Question from "./Question";
import { getChannel, getVersion, NonVoiceSummaryExport , getSummary} from '../service/reportService';
//  import "suneditor/src/assets/css/suneditor-contents.css"
const surveyFormService = new SurveyFormService();
const utills = new Utills();
export const SurveyFormDetailAppContext = createContext(null);
export const AppContext = createContext(null);

const SurveyFormDetail = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [channelOption, setChannelOption] = useState([]);
  const [versionOption, setVersionOption] = useState([]);
  const [titleError, setTitleError] = useState();
  const editor = useRef();

  const [validationOption, setValidationOption] = useState([]);
  const [questionList, setQuestionList] = useStateCallback([
    {key:1, name:"", sequence:1, questionType:"1", answers:[],
     responseValidationNo:null, responseValidationId:"1", isRequired: true}]);
  const [titleName, setTitleName] = useStateCallback();
  const [description, setDescription] = useState();
  const [surveyChannel, setSurveyChannel] = useState([]);
  const [surveyName, setSurveyName] = useState();
  const desEditor = useRef();
  const [desError, setDesError] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitType, setSubmitType] = useStateCallback();
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [responseDetail, setResponseDetail] = useState();
  const [isModalCopyOpen, setIsModalCopyOpen] = useState(false);
  const [versions, setVersions] = useState([]);
  const [historyDetailResponse, setHistoryDetailResponse] = useStateCallback(undefined);
  let questionOption = {options:[]};
  const [questionState, setQuestionState] = useState(questionOption);
  const [copyDisable, setCopyDisable] = useState(true);
  // const [isDisabled, setIsDisabled] = useState(false);
  const passData = useLocation();
  console.log("Pass data ",passData);
  let version = "-";
  if(passData.state !== null && passData.state.currentVersion !== null){
    version = passData.state.currentVersion;
  }
  const [currentVersion, setCurrentVersion] = useState(version);


  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor) => {
      editor.current = sunEditor;
  };

  const getDesSunEditorInstance = (sunEditor) => {
    desEditor.current = sunEditor;
};

  
  const onSortEnd = ({ oldIndex, newIndex }) => {
    let updatedList = [...questionList];
    let data = arrayMoveImmutable(updatedList, oldIndex, newIndex);
    let newArray = [];
    for (let i = 0; i < data.length; i++) {
      let element = data[i];
      element.sequence = i+1;
      newArray.push(element);
    }
    setQuestionList(newArray, function(updateData){
      console.log("Sort data ", updateData)
    });
  };

  const SortableItem = sortableElement(({value}) => {
    const data = value;
    return (
      <QuestionCreate
      data={data}
      optionEditor={optionEditor}
      passData={passData}
      setDisableButton={setDisableButton}
    />
    );
  });

  const SortableList = sortableContainer(({items}) => {
    return (
      <div >
      {items.map((value, index) => (
        <div id={index}> 
          <SortableItem key={index} index={index} value={value} />
        </div>
        
      ))}
        
      </div>
    );
  });

  function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null); // init mutable ref container for callbacks
  
    const setStateCallback = useCallback((state, cb) => {
      cbRef.current = cb; // store current, passed callback in ref
      setState(state);
    }, []); // keep object reference stable, exactly like `useState`
  
    useEffect(() => {
      // cb.current is `null` on initial render, 
      // so we only invoke callback on state *updates*
      if (cbRef.current) {
        cbRef.current(state);
        cbRef.current = null; // reset callback after execution
      }
    }, [state]);
  
    return [state, setStateCallback];
  }


  const pageSize = 10;
  let currentPage = 1;

  const [state, setState] = useState({ optionSelected: null });

  const onVersionChange = (selected) => {
    let item = versionOption.find((element) => element.value === selected)
    console.log("surverFormId : ",item.id);
    getHistoryDetailData(item.id);
  };

  const handleChange = (selected) => {
    let surveyChannelList = [];
    // if(passData.state !== null && passData.state.id !== null){
    //   surveyChannel.map(item => 
    //     {
    //       if (item.surveyChannelId == selected){
    //         surveyChannelList.push( item); //gets everything that was already in item, and updates "done"
    //       }
    //       // return undefined; // else return unmodified item 
    //     });
    // }else{
    //   selected.map((item) => {
    //     surveyChannelList.push({surveyChannelId: item});
    //   })
    // }
    selected.map((item) => {
      surveyChannelList.push({surveyChannelId: item});
    })
    
    setSurveyChannel(surveyChannelList);
    console.log("Survey Channel : ",surveyChannelList);
    // setState({
    //   optionSelected: selected,
    // });
  };

  const onSurveyNameChange = (e) => {
    setSurveyName(e.target.value);
    console.log("SurveyName : ",e.target.value);
  }

  function getDisplayHtmlTag(html, id){
    let titleElement = document.getElementById(id);
    if(titleElement !== null){
        titleElement.innerHTML = html;
    }
  }

  const optionEditor = {
    showPathLabel: false,
    minHeight: "20vh",
    // width:"100%",
    placeholder: "Enter Display Detail here",
    plugins: [
      align,
      font,
      fontColor,
      fontSize,
      formatBlock,
      hiliteColor,
      horizontalRule,
      lineHeight,
      list,
      paragraphStyle,
      table,
      template,
      textStyle,
      link,
    ],
    buttonList: [
      ["bold", "underline", "italic", "strike"],
    ],
    formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
    font: [
      "Arial",
      "Georgia",
      "Tahoma",
      "Trebuchet MS",
      "Verdana",
      "Prompt",
      "Kanit",
      "Sarabun",
      "Pridi",
      "NotoSansThai",
    ],
  };
  const { Option } = Select;

  const query = useQuery();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [Keywords, setKeywords] = useState("");

  const { Search, TextArea } = Input;

  const onSearch = (value, _e, info) => {
    // console.log(info?.source, value);
    // fetchData();
  };

  const handleSearchChange = (event) => {
    setKeywords(event.target.value);
  };

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState();
  const [LearningPlace, setLearningPlace] = useState({});

  const [disableForm, setDisableForm] = useState();

  //   const [LearningPlaceCreateRequest, setLearningPlaceCreateRequest] = useState({
  //     id: null,
  //     projectId: query.get("projectId"),
  //   });



  // const [filterCampus, setFilterCampus] = useState([]);
  // const [disabledCampus, setDisableCampus] = useState(true);

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 4,
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  
  function getChannel(){
    surveyFormService.getChannel().then((response) => {
      if(response.data != undefined){
        // setResponseList(response);
        console.log(response);
        let option = [];
        response.data.map((item) => {
          if(passData.state !== null && passData.state.id !== null){
            option.push({label:item.name, value:item.id});
          }else{
            if(!item.isExisted){
              option.push({label:item.name, value:item.id});
            }
          }
        })
        setChannelOption(option);
      }
    }).catch(async (err) => {
      console.log("err", err);
      // if (err?.response)
      //   if (err?.response?.status === 401) {
      //     if (await authService.refreshToken()) {
      //     }
      //   }
    });
  }

  function getHistory(id){
    const pageValues = {
      page: 1,
      pageSize: 100,
      // sortBy: sortBy,
      // sortDirection: sortDirection,
      // total: total,
      // keywords: Keywords,
      SurveyFormId: id,
    };
    
    surveyFormService.getSurveyFormHistoryList(pageValues).then((response) => {
      if(response.data != undefined){
        // setResponseList(response);
        console.log(response);
        let option = [{label:"Select Version", value:"0", id: "0"}];
        response.data.items.map((item) => {
          let test = parseFloat(item.version);
          option.push({label:item.version, value:item.version, id: item.id});
        })
        setVersionOption(option);
        if(option.length > 1){
          setCurrentVersion(option[1].value);
        }
      }
    }).catch(async (err) => {
      console.log("err", err);
      // if (err?.response)
      //   if (err?.response?.status === 401) {
      //     if (await authService.refreshToken()) {
      //     }
      //   }
    });
  }

  function setDisableButton(isDisable){
    let saveDraftButton = document.getElementById("saveDraftButton");
    let publishButton = document.getElementById("publishButton");
    if(null != saveDraftButton){
      saveDraftButton.disabled = isDisable
    }
    if(null != publishButton){
      publishButton.disabled = isDisable;
    }
  }

  function getValidation(){
    surveyFormService.getValidation().then((response) => {
      if(response.data != undefined){
        // setResponseList(response);
        console.log("Service validation : ",response);
        let option = [];
        response.data.map((item) => {
          option.push({label:item.name, value:item.id});
        })
        setValidationOption(option);
      }
    }).catch(async (err) => {
      console.log("err", err);
      // if (err?.response)
      //   if (err?.response?.status === 401) {
      //     if (await authService.refreshToken()) {
      //     }
      //   }
    });
  }

  function submitDataToService(request){
    surveyFormService.submitData(request).then((response) => {
      Swal.close();
      if(response.data != undefined){
        // setResponseList(response);
        console.log("Submit response ", response);
        setIsModalSuccessOpen(true);
      }
    }).catch(async (err) => {
      Swal.close();
      console.log("err", err);
      setIsModalFailedOpen(true);
    });
  }

  function updateDataToService(request){
    surveyFormService.updateData(request, request.id).then((response) => {
      Swal.close();
      if(response.data != undefined){
        // setResponseList(response);
        console.log("Update data response ", response);
        setIsModalSuccessOpen(true);
      }
    }).catch(async (err) => {
      Swal.close();
      console.log("err", err);
      setIsModalFailedOpen(true);
    });
  }

  function getDetailData(id){
    surveyFormService.getSurveyDetail(id).then((response) => {
      if(response.data != undefined){
        // setData(response.data.items);
        console.log("Detail : ",response);
        setResponseDetail(response);
        setSurveyName(response.data.surveyForm.name);
        updateQuestionList(response);

        // if(passData !== undefined && passData.state.id !== undefined){
        //   setCurrentVersion(response.data.surveyForm.version);
        // }
        // initData(response);
      }
      // if (response.data.objectValue) setDataList(response.data.objectValue);
      // console.log("ddddd", response.data.objectValue);
    }).catch(async (err) => {
      console.log("err", err);
    });
  }

  function getHistoryDetailData(id){
    if(id === "0"){
      setCopyDisable(true);
      setHistoryDetailResponse(undefined);
      return
    }
    surveyFormService.getSurveyHistoryDetail(id).then((response) => {
      if(passData.state.isCopy !== undefined){
        console.log("Detail : ",response);
        setResponseDetail(response);
        setSurveyName(response.data.surveyForm.name);
        updateQuestionList(response);
        // if(!isReload){
        //   isReload = true;
        //   window.location.reload();
        // }
        
      }else{
        if(response.data != undefined){
          console.log("History Detail : ",response);
          setCopyDisable(false);
          setHistoryDetailResponse(response);
          // setSurveyName(response.data.surveyForm.name);
          // updateQuestionList(response);
        }
      }

     
    }).catch(async (err) => {
      console.log("err", err);
    });
  }

  function onAddQuestion(){
    let lastElement = questionList[questionList.length - 1];
    setQuestionList([...questionList, {key:lastElement.key+1, name:"",sequence:lastElement.sequence+1, 
    questionType:"1", answers:[ {key:1, name:"",isVariantValue:false, sequence:1}], responseValidationNo:null, responseValidationId:"1", 
    isRequired: true}], function(data) {
      
      let id = data.length-1;
      let element = document.getElementById(id);
      if(element !== null){
          // element.scrollIntoView();
      }
;
    });
  }

  function getChannelId (findItem){
    let channelIdList = [];
    findItem.map((item, i) => {
      channelIdList.push(item.surveyChannelId);
    })
    console.log("channel list ", channelIdList);
    return channelIdList;
  }
  

  function useQuery() {
    const { search } = useLocation();

    // return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  

  useEffect(() => {
    
    getChannel()
    getValidation()

    if(passData.state !== null){
      getHistory(passData.state.id)
      let id = passData.state.id;
      if(passData.state.isCopy !== undefined){
        getHistoryDetailData(id);
      }else{
        getDetailData(id);
      }
    }
  }, [passData]);


  const handleTableChange = (pagination, filters, sorter, extra) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };
  const [value, setValue] = useState(1);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const [selectedCities, setSelectedCities] = useState(null);
  const cities = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];

  const onSubmit = async (newSubmitType) => {
    let tempSubmitType = submitType;
    if(tempSubmitType === undefined){
      tempSubmitType = newSubmitType;
    }
    let newChannel = [];
    if(passData.state !== null && passData.state.id !== undefined){
      newChannel = surveyChannel.map((item) => {
        let newId = passData.state.id;
        if(passData.state.isCopy !== undefined){
          newId = item.surveyFormId;
        }
        if(passData.state.surveyFormId !== undefined){
          newId = passData.state.surveyFormId;
        }
        return { ...item, surveyFormId: newId };
      })
      setSurveyChannel(newChannel);
    }else{
      newChannel = surveyChannel;
    }
    
    let request = {name:surveyName, title:titleName, description:description,
      surveyFormStatusId:tempSubmitType, isActivated: true, channels:newChannel,
      questions:[]};
      let questionRequestList = []
    for (let j = 0; j < questionList.length; j++) {
      let question = questionList[j];
      let answerList = []
      // sequence: question.sequence
      let questionRequest = {name: ""+question.name, questionTypeId: ""+question.questionType,
        sequence: j+1, isRequired:question.isRequired, answers:[], };
        if(question.questionType === "2"){
          questionRequest.responseValidationId = question.responseValidationId;
          questionRequest.responseValidationNo = question.responseValidationNo;
        }
        // if(question.questionType === "3"){
        //   answerList = [];
        // }
      for (let i = 0; i < question.answers.length; i++) {
        let answer = question.answers[i];
        let answerRequest = {name:""+answer.name, isVariantValue: answer.isVariantValue, sequence:answer.sequence};
        // const id = "answer"+(i+1);
        // let element = document.getElementById(id);
        // if(element !== null){
        //   answerRequest.name = element.value;
        // }
        if(question.questionType !== "3"){
          answerList.push(answerRequest);
        }
        
      }
      // if(question.questionType === "3"){
      //   answerList = [];
      // }

      questionRequest.answers = answerList;
      questionRequestList.push(questionRequest);
    }
    request.questions = questionRequestList;
    console.log("Request data ", request);
    // Swal.fire({
    //   icon: "info",
    //   title: "Loading",
    //   showConfirmButton: false,
    // });
    Swal.fire({
      title: 'Please Wait !',
      html: 'data uploading',// add html attribute if you want or remove
      allowOutsideClick: false,
      showConfirmButton: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading()
        if(passData.state === null){
          submitDataToService(request);
        }else{
          if(passData.state.surveyFormId !== undefined){
            request.id = passData.state.surveyFormId;
          }else{
            request.id = passData.state.id;
          }
          updateDataToService(request);
          
        }
        
      }
  });
  // setTimeout(function(){
  //   Swal.close();
  //   // setIsModalSuccessOpen(true);
  //   setIsModalFailedOpen(true);
  // }, 1500);
    
  };

  function updateQuestionList (response){
    setTitleName(response.data.surveyForm.title);
    setDescription(response.data.surveyForm.description);
    const questionList = response.data.surveyForm.questions;
    let surveyChannelList = [];
    response.data.surveyForm.channels.map((item) => {
      surveyChannelList.push(item);
    })
    setSurveyChannel(surveyChannelList);
    let questionRequestList = []
    for (let j = 0; j < questionList.length; j++) {
      const questionData = questionList[j];
      let question = {key:j, name: questionData.name, sequence:questionData.sequence, 
    questionType:questionData.questionTypeId, answers:[], responseValidationNo: questionData.responseValidationNo, responseValidationId:questionData.responseValidationId, 
    isRequired: questionData.isRequired};
        
      let answerList = []
      for (let i = 0; i < questionData.answers.length; i++) {
        let answer = questionData.answers[i];
        let answerName = null;
        if(answer.isVariantValue){
          answerName = answer.name;
        }else{
          answerName = parseInt(answer.name);
        }
        let answerRequest = {key:i, name:answerName, isVariantValue:answer.isVariantValue, sequence:answer.sequence};
        // const id = "answer"+(i+1);
        // let element = document.getElementById(id);
        // if(element !== null){
        //   answerRequest.name = element.value;
        // }
        answerList.push(answerRequest);
      }

      question.answers = answerList;
      questionRequestList.push(question);
    }
    console.log("Question List data from service ", questionRequestList);
    setQuestionList(questionRequestList);
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <AppContext.Provider
      value={{
        questionList,
        setQuestionList,
        validationOption,
        responseDetail,
      }}
    >
      <Modal
        centered
        width={800}
        title=""
        open={isModalCopyOpen}
        footer={null}
        closable={false}
        onCancel={() => {
          setIsModalCopyOpen(false);
        }}
        afterClose={(e) => {}}
      >
        <div className="w-full">
          <div className="flex flex-row ">
            <p
              className="flex-1"
              style={{
                fontWeight: 600,
                fontSize: 24,
                marginTop: 0,
              }}
            >
              Copy Version
            </p>
            <Flex
              className="flex-1"
              justify="flex-end"
              style={{ marginRight: 0 }}
            >
              <Button
                className="w-[150px] h-[38px]"
                //loading={loadingExcel}
                // icon={<FileExcelOutlined />}
                type="primary"
                shape="round"
                style={{
                  marginRight: 15,
                  borderRadius: 10.5,
                  border: "1px solid #D9D9D9",
                  //marginTop: 20,
                  color: "#000",
                  backgroundColor: "#FFF",
                }}
                onClick={() => {
                  setIsModalCopyOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={copyDisable}
                id="publishButton"
                className="w-[150px] h-[38px]"
                style={{
                  marginRight: 0,
                  backgroundColor: "#FF7F07",
                  color: "#ffffff",
                  border: "2px solid #FF7F07",
                }}
                onClick={() => {
                  setIsModalCopyOpen(false);
                  navigate(
                    {
                      pathname: `/survey-form-detail`,

                      // pagedata: pageValue,
                    },
                    {
                      state: {
                        id: historyDetailResponse.data.surveyForm.id,
                        isCopy: true,
                        surveyFormId:
                          historyDetailResponse.data.surveyForm.surveyFormId,
                        currentVersion: currentVersion,
                      },
                    }
                  );
                  setTimeout(function myStopFunction() {
                    window.location.reload();
                  }, 700);
                }}
                // type="primary"
              >
                Copy
              </Button>
            </Flex>
          </div>
          <div
            style={{
              width: "100%",
              position: "relative",
              marginLeft: 0,
              marginRight: 0,
              marginBottom: 10,
              // margin: "10px 0px",
              borderBottom: "1px solid #D0D0D0",
            }}
          />
          <div className="flex flex-col mt-[20px] mb-[30px]">
            <label
              style={{
                fontWeight: 600,
                fontSize: 16,
                color: "#1C2538",
              }}
            >
              Version
              {/* <span style={{ color: "red" }}> *</span> */}
            </label>
            <Select
              className="h-[38px] ms-[0px] w-[200px] mt-[6px]"
              defaultValue={"0"}
              allowClear
              // className="mt-2"
              options={versionOption}
              style={{ marginRight: 0 }}
              placeholder="Version"
              showSearch
              maxTagCount="responsive"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              //to do change this
              onChange={onVersionChange}
              //   value={data.responseValidationId}
            ></Select>
          </div>
          <div
            style={{
              width: "100%",
              position: "relative",
              marginLeft: 0,
              marginRight: 0,
              marginBottom: 10,
              marginTop: 10,
              // margin: "10px 0px",
              borderBottom: "1px solid #D0D0D0",
            }}
          />
          <p
            className=""
            style={{
              fontWeight: 600,
              fontSize: 16,
              marginTop: 0,
            }}
          >
            Preview
          </p>
          {historyDetailResponse === undefined ? (
            <div
              className="h-[300px] flex flex-col items-center justify-center"
              style={{
                backgroundColor: "#fff",
                padding: "15px 0px 8px 0px",
                marginBottom: 0,
                borderRadius: 10,
                // minHeight:580,
              }}
            >
              <IconData className="" />
              <p className="font-semibold text-[20px]">No data</p>
            </div>
          ) : (
            <SurveyFormDetailAppContext.Provider
              value={{ questionState, setQuestionState }}
            >
              <div
                className="flex w-full justify-start pointer-events-none"
                style={{
                  backgroundColor: "#fff",
                  padding: "15px 0px 8px 0px",
                  border: "1px solid #D0D5DD",
                  marginBottom: 0,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 15,
                  borderRadius: 10,
                  minHeight: 580,
                }}
              >
                <div className="w-full">
                  <Row>
                    <Col span={12} style={{ padding: "5px 15px" }}>
                      <div className="mt-3 ms-3">
                        {/* <IconLogo /> */}
                        <img width="50%" height="25%" src="https://samsunglife.co.th/wp-content/uploads/2023/07/Logo-Samsung-Life-Insurance-Eng-no-clear-space.png"></img>
                      </div>
                    </Col>
                    <Col span={12}></Col>
                  </Row>
                  <Row>
                    <Col span={24} style={{ padding: "5px 15px" }}>
                      <div
                        id={"title" + historyDetailResponse.data.surveyForm.id}
                        className="mt-8 ms-3 me-3"
                        style={{
                          marginTop: 10,
                          fontWeight: 700,
                          fontSize: 24,
                          color: "#1C2538",
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            historyDetailResponse !== undefined
                              ? historyDetailResponse.data.surveyForm.title
                              : "",
                        }}
                      >
                        {/* {getDisplayHtmlTag(
                          historyDetailResponse.data.surveyForm.title,
                          "title" + historyDetailResponse.data.surveyForm.id
                        )} */}
                        {/* <label
                        className="mt-8 ms-3"
                        style={{
                          fontWeight: 700,
                          fontSize: 24,
                          color: "#1C2538",
                        }}
                      >
                        Call Center Feedback Survey
                      </label> */}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <div
                        style={{
                          width: "100%",
                          position: "relative",
                          // marginLeft: "-12px",
                          margin: "10px 0px",
                          borderBottom: "2px solid #D0D0D0",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={22} style={{ padding: "5px 15px" }}>
                      <div
                        id={"des" + historyDetailResponse.data.surveyForm.id}
                        style={{
                          marginTop: 0,
                          fontWeight: 400,
                          fontSize: 14,
                          color: "#667085",
                        }}
                        className="mt-8 ms-3 me-3"
                        dangerouslySetInnerHTML={{
                          __html:
                            historyDetailResponse !== undefined
                              ? historyDetailResponse.data.surveyForm
                                  .description
                              : "",
                        }}
                      >
                        {/* <label
                        style={{
                          fontWeight: 400,
                          fontSize: 14,
                          color: "#667085",
                        }}
                      >
                        Thank you for choosing. Your feedback will help us
                        improve our work and our service standard, so we would
                        greatly appreciate you filling out this form. Thank you
                      </label> */}
                        {/* {getDisplayHtmlTag(
                          historyDetailResponse.data.surveyForm.description,
                          "des" + historyDetailResponse.data.surveyForm.id
                        )} */}
                      </div>
                    </Col>
                    <Col span={2}>
                      <div style={{ marginTop: 0 }}></div>
                    </Col>
                  </Row>
                  <Row className="mt-2 ms-7 me-7">
                    <Col span={24}>
                      <div
                        style={{
                          width: "100%",
                          position: "relative",
                          // marginLeft: "-12px",
                          margin: "10px 0px",
                          borderBottom: "2px solid #ECF1F6",
                        }}
                      />
                    </Col>
                  </Row>
                  {/* ----------------------- */}
                  <Question
                    response={historyDetailResponse}
                    isDetailMode={true}
                    isTouchable={false}
                    page="surveyFormDetail"
                  />
                  {/* ----------------------- */}

                  {/* <Row className="ms-3 me-3">
                  <Col span={24} style={{ padding: 15 }}>
                    <div style={{ marginBottom: 0 }}>
                      <label style={{ fontWeight: 500, color: "#1C2538" }}>
                        Additional Comments:
                      </label>
                    </div>
                    <TextArea
                      className="mt-2"
                      defaultValue={comment}
                      value={comment}
                    />
                  </Col>
                </Row> */}
                </div>
              </div>
            </SurveyFormDetailAppContext.Provider>
          )}
        </div>
      </Modal>

      <Modal title="" open={isModalOpen} footer={null} closable={false} centered>
        <div className="flex flex-col justify-center items-center">
          <IconConfirmSubmit />
          <p
            style={{
              fontWeight: 600,
              fontSize: 22,
              marginTop: 12,
            }}
          >
            Confirm Save & Publish
          </p>
          <p
            style={{
              fontWeight: 500,
              fontSize: 16,
              marginTop: 8,
              color: "#475467",
            }}
          >
            Released as version:{" "}
            {responseDetail !== undefined ? currentVersion : "-"}
          </p>
          <div className="flex flex-row items-center justify-center mt-[20px]">
            <Button
              className="w-[150px] h-[38px]"
              //loading={loadingExcel}
              // icon={<FileExcelOutlined />}
              type="primary"
              shape="round"
              style={{
                marginRight: 15,
                borderRadius: 10.5,
                border: "1px solid #D9D9D9",
                //marginTop: 20,
                color: "#000",
                backgroundColor: "#FFF",
              }}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              className="w-[150px] h-[38px]"
              style={{
                marginRight: 10,
                backgroundColor: "#FF7F07",
                color: "#ffffff",
                border: "2px solid #FF7F07",
              }}
              onClick={() => {
                handleCancel();
                onSubmit();
                // navigate("/survey-form-list");
              }}
              // type="primary"
            >
              Publish
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        centered
        title=""
        open={isModalSuccessOpen}
        footer={null}
        closable={false}
        destroyOnClose={true}
        onCancel={() => {
          setIsModalSuccessOpen(false);
        }}
        afterClose={(e) => {
          navigate(
            {
              pathname: `/survey-form-list`,

              // pagedata: pageValue,
            },
            { state: { updateDate: new Date() } }
          );
        }}
      >
        <div className="flex flex-col justify-center items-center">
          <IconSuccessDialog />
          <p
            style={{
              fontWeight: 600,
              fontSize: 22,
              marginTop: 12,
            }}
          >
            Success
          </p>
          <p
            style={{
              fontWeight: 500,
              fontSize: 16,
              marginTop: 8,
            }}
          >
            Successfully saved data.
          </p>
        </div>
      </Modal>

      <Modal
        centered
        title=""
        open={isModalFailedOpen}
        footer={null}
        closable={false}
        destroyOnClose={true}
        onCancel={() => {
          setIsModalFailedOpen(false);
        }}
        onClose={(e) => {
          e.stopPropagation();
          setIsModalFailedOpen(false);
        }}
      >
        <div className="flex flex-col justify-center items-center">
          <IconFailedDialog />
          <p
            style={{
              fontWeight: 600,
              fontSize: 22,
              marginTop: 12,
            }}
          >
            Failed
          </p>
          <p
            style={{
              fontWeight: 500,
              fontSize: 16,
              marginTop: 8,
            }}
          >
            Something went wrong. Please try again.
          </p>
          <Button
            className="w-[150px] h-[38px] mt-[20px]"
            style={{
              marginRight: 10,
              backgroundColor: "#FF7F07",
              color: "#ffffff",
              border: "2px solid #FF7F07",
            }}
            onClick={() => {
              setIsModalFailedOpen(false);
              onSubmit();
              // navigate("/survey-form-list");
            }}
            // type="primary"
          >
            Try Again
          </Button>
        </div>
      </Modal>

      <div style={{ backgroundColor: "#F8F8F8" }}>
        <div>
          <Row>
            <Col span={10}>
              <div className="flex flex-row">
                <div>
                  <IconArrowLeft
                    style={{ marginRight: 10, marginTop: 4 }}
                    onClick={() => {
                      navigate(-1);
                    }}
                    twoToneColor="#98A2B3"
                    onMouseEnter={() => {
                      // alert("I am an alert box!");
                    }}
                  />
                </div>
                <div>
                  <label
                    style={{
                      marginLeft: 7,
                      marginTop: 0,
                      fontSize: 24,
                      fontWeight: 600,
                    }}
                  >
                    {passData.state !== null ? "Edit" : "Add Form"}
                  </label>
                </div>
              </div>
            </Col>
            <Col span={14}>
              <Flex justify="flex-end" style={{ marginRight: 0 }}>
                <Button
                  // disabled={isDisabled}
                  id="saveDraftButton"
                  type="link"
                  onClick={() => {
                    setSubmitType("1");
                    onSubmit("1");
                    // showModal();
                  }}
                >
                  Save Draft
                </Button>
                <div
                  className=""
                  style={{
                    height: 30,
                    marginTop: 3,
                    marginRight: "10px",
                    // paddingTop: 23,
                    borderRight: "1px solid #D0D5DD",
                  }}
                />
                <Button
                  onClick={() => {
                    navigate(
                      {
                        pathname: `/survey-form-list`,

                        // pagedata: pageValue,
                      },
                      { state: { updateDate: new Date() } }
                    );
                  }}
                >
                  Cancel
                </Button>
                
                <Button
                  // disabled={isDisabled}
                  className="ml-4"
                  id="publishButton"
                  size="large"
                  onClick={() => {
                    setSubmitType("2");
                    showModal();
                    // navigate("/survey-form-list");
                  }}
                  type="primary"
                >
                  Save & Publish
                </Button>
              </Flex>
            </Col>
          </Row>
        </div>

        <Form
          form={form}
          className="search_inline w-full py-6"
          onFinish={onSubmit}
        >
          <Row>
            <Col span={24}>
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "0px 0px 0px 0px",
                  marginBottom: 15,
                  marginLeft: 0,
                  borderRadius: 10,
                  border: "1px solid #D0D5DD",
                }}
              >
                <div style={{ padding: 0 }}>
                  <Row>
                    <Col span={16}>
                      <div
                        style={{
                          marginBottom: 16,
                          fontSize: 14,
                          color: "#65707A",
                          //   minHeight:580
                        }}
                      >
                        <Flex
                          justify="flex-start"
                          style={{
                            marginRight: 0,
                            marginTop: 24,
                            marginLeft: 16,
                          }}
                        >
                          <label>
                            Version :{" "}
                            {currentVersion !== undefined
                              ? currentVersion
                              : "-"}
                          </label>
                        </Flex>
                      </div>
                    </Col>
                    <Col span={8}>
                      <Flex justify="flex-end">
                        {passData.state !== null &&
                        passData.state.isCopy === undefined ? (
                          <Button
                            className="mt-[20px] mr-[20px] primary-outline"
                            //loading={loadingExcel}
                            icon={<IconCopyVersion />}
                            size="large"
                            onClick={() => {
                              // navigate("/survey-form-list");
                              setIsModalCopyOpen(true);
                            }}
                          >
                            Copy Version
                          </Button>
                        ) : (
                          ""
                        )}
                      </Flex>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={11}>
                      <div
                        style={{
                          marginBottom: 5,
                          marginLeft: 16,
                          marginTop: 8,
                        }}
                      >
                        <label
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1C2538",
                          }}
                        >
                          Survey Channel<span style={{ color: "red" }}> *</span>
                        </label>
                      </div>
                      <Form.Item
                        //  label="Name"
                        name="surveyChannel"
                        colon={false}
                        rules={[
                          {
                            required: true,
                            message: "This information is required.",
                          },
                        ]}
                      >
                        {passData.state !== null &&
                        responseDetail !== undefined ? (
                          <Select
                            className="h-[38px] ms-[16px]"
                            mode="multiple"
                            defaultValue={getChannelId(
                              responseDetail.data.surveyForm.channels
                            )}
                            // defaultValue={['1']}
                            allowClear
                            // className="mt-2"
                            options={channelOption}
                            style={{ marginRight: 10 }}
                            placeholder="Channel"
                            showSearch
                            maxTagCount="responsive"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={handleChange}
                          ></Select>
                        ) : (
                          ""
                        )}

                        {passData.state === null ? (
                          <Select
                            className="h-[38px] ms-[16px]"
                            mode="multiple"
                            // defaultValue={['1']}
                            allowClear
                            // className="mt-2"
                            options={channelOption}
                            style={{ marginRight: 10 }}
                            placeholder="Channel"
                            showSearch
                            maxTagCount="responsive"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={handleChange}
                          ></Select>
                        ) : (
                          ""
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={12}>
                      <div style={{ marginBottom: 5, marginTop: 8 }}>
                        <label
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1C2538",
                          }}
                        >
                          Survey Form Name
                          <span style={{ color: "red" }}> *</span>
                        </label>
                      </div>
                      <Form.Item
                        //  label="Name"
                        className="me-[16px]"
                        name="name"
                        colon={false}
                        rules={[
                          {
                            required: true,
                            message: "This information is required.",
                          },
                        ]}
                      >
                        {passData.state !== null &&
                        responseDetail !== undefined ? (
                          <Input
                            className="h-[38px]"
                            placeholder="Survey Channel.."
                            allowClear
                            onChange={onSurveyNameChange}
                            defaultValue={responseDetail.data.surveyForm.name}
                          />
                        ) : (
                          ""
                        )}

                        {passData.state === null ? (
                          <Input
                            className="h-[38px]"
                            placeholder="Survey Channel.."
                            allowClear
                            onChange={onSurveyNameChange}
                          />
                        ) : (
                          ""
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row>
                  <div
                    style={{
                      width: "96%",
                      position: "absolute",
                      margin: "auto",
                      // paddingTop: 23,
                      borderBottom: "1px solid #D0D0D0",
                    }}
                  />
                </Row> */}
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "15px 0px 8px 0px",
                  marginBottom: 15,
                  marginLeft: 0,
                  borderRadius: 10,
                  minHeight: 80,
                  border: "1px solid #D0D5DD",
                }}
              >
                <div style={{ padding: 15 }}>
                  <Row>
                    <Col span={24}>
                      <div style={{ marginBottom: 5 }}>
                        <label
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1C2538",
                          }}
                        >
                          Title<span style={{ color: "red" }}> *</span>
                        </label>
                      </div>
                      <Form.Item
                        //  label="Name"
                        name="title"
                        colon={false}
                        rules={[
                          {
                            required: true,
                            message: "This information is required.",
                          },
                        ]}
                      >
                        {passData.state !== null &&
                        responseDetail !== undefined ? (
                          <SunEditor
                            // setContents={editorStateTh}
                            defaultValue={responseDetail.data.surveyForm.title}
                            getSunEditorInstance={getSunEditorInstance}
                            setDefaultStyle={"font-size:16px; font-family: Noto Sans Thai;"}
                            setOptions={optionEditor}
                            onChange={(content) => {
                              let text = editor.current.getText();
                              setTitleName(content);
                              if (text.length <= 0) {
                                setTitleError(
                                  <div class="ant-form-item-explain-error">
                                    This information is required.
                                  </div>
                                );
                              } else {
                                setTitleError();
                              }
                            }}
                          />
                        ) : (
                          ""
                        )}

                        {passData.state === null ? (
                          <SunEditor
                            // setContents={editorStateTh}
                            getSunEditorInstance={getSunEditorInstance}
                            setDefaultStyle={"font-size:16px; font-family: Noto Sans Thai;"}
                            setOptions={optionEditor}
                            onChange={(content) => {
                              let text = editor.current.getText();
                              setTitleName(content);
                              if (text.length <= 0) {
                                setTitleError(
                                  <div class="ant-form-item-explain-error">
                                    This information is required.
                                  </div>
                                );
                              } else {
                                setTitleError();
                              }
                            }}
                          />
                        ) : (
                          ""
                        )}

                        {titleError}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <div style={{ marginBottom: 5 }}>
                        <label
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1C2538",
                          }}
                        >
                          Description<span style={{ color: "red" }}> *</span>
                        </label>
                      </div>
                      <Form.Item
                        //  label="Name"
                        name="title"
                        colon={false}
                        rules={[
                          {
                            required: true,
                            message: "This information is required.",
                          },
                        ]}
                      >
                        {passData.state !== null &&
                        responseDetail !== undefined ? (
                          <SunEditor
                            // style={{}}
                            // setContents={editorStateTh}
                            // onChange={(html) => setEditorStateTh(html)}
                            defaultValue={
                              responseDetail.data.surveyForm.description
                            }
                            getSunEditorInstance={getDesSunEditorInstance}
                            setDefaultStyle={"font-size:16px; font-family: Noto Sans Thai;"}
                            setOptions={optionEditor}
                            onChange={(content) => {
                              let text = desEditor.current.getText();
                              setDescription(content);
                              if (text.length <= 0) {
                                setDesError(
                                  <div class="ant-form-item-explain-error">
                                    This information is required.
                                  </div>
                                );
                              } else {
                                setDesError();
                              }
                            }}
                          />
                        ) : (
                          ""
                        )}

                        {passData.state === null ? (
                          <SunEditor
                            // style={{}}
                            // setContents={editorStateTh}
                            // onChange={(html) => setEditorStateTh(html)}
                            getSunEditorInstance={getDesSunEditorInstance}
                            setDefaultStyle={"font-size:16px; font-family: Noto Sans Thai;"}
                            setOptions={optionEditor}
                            onChange={(content) => {
                              let text = desEditor.current.getText();
                              setDescription(content);
                              if (text.length <= 0) {
                                setDesError(
                                  <div class="ant-form-item-explain-error">
                                    This information is required.
                                  </div>
                                );
                              } else {
                                setDesError();
                              }
                            }}
                          />
                        ) : (
                          ""
                        )}

                        {desError}
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              {/* <SortableList
                useDragHandle
                items={questionList}
                onSortEnd={onSortEnd}
                useWindowAsScrollContainer
              /> */}
              {questionList.map((value, index) => (
                <div id={index}>
                  <QuestionCreate
                    data={value}
                    optionEditor={optionEditor}
                    passData={passData}
                    setDisableButton={setDisableButton}
                  />
                </div>
              ))}
            </Col>
            <Col span={24}>
              {questionList.length < 10 ? (
                <Form.Item>
                  <div className="flex justify-start">
                    <>
                      <Button
                        className="items-center primary-outline"
                        
                        icon={<PlusOutlined style={{ color: "#FF7F07" }} />}
                        onClick={() => {
                          onAddQuestion();
                        }}
                      >
                        Add Question
                      </Button>
                    </>
                  </div>
                </Form.Item>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Form>
      </div>
    </AppContext.Provider>
  );
};

export default SurveyFormDetail;
