import React, { useState, useEffect } from 'react';
import { DatePicker, Space, Button, Select } from 'antd';
import { SyncOutlined , DownloadOutlined } from '@ant-design/icons';
import { getChannel, getName, getSummary , getVersion , getTransaction , NonVoiceTransactionExport} from '../service/reportService';
import TransactionTable from './TableNonVioceTransaction';
import nodata from "../assets/img/NoData.png";



const { Option } = Select;
const { RangePicker } = DatePicker;

const Transaction = () => {
  const [dateRange, setDateRange] = useState([]);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedChannelId, setSelectedChannelId] = useState(null);
  const [channels, setChannels] = useState([]);
  const [versions, setVersions] = useState([]);
  const [Failed, setFailed] = useState([]);
  const [name, setName] = useState([]);

  useEffect(() => {
    fetchData();
  }, [selectedName]);

  useEffect(() => {
  }, [dateRange, selectedChannelId, selectedVersion, selectedName]);
  
  useEffect(() => {
    console.log('selectedChannel:', selectedChannel);
    console.log('selectedVersion:', selectedVersion);
    console.log('selectedName:', selectedName);
    console.log('selectedChannelId:', selectedChannelId);
  }, [selectedChannel, selectedVersion, selectedName, selectedChannelId]);
  


  const fetchData = async () => {
    try {
      const nameResponse = await getName();
      setName(nameResponse);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates || []); 
  };

  const handleClear = () => {
    setDateRange([]);
    setSelectedChannel(null);
    setSelectedVersion(null);
    setDataAvailable(false);
    setDataList([]);
    setFailed([]);
  };

  const handleSubmit = async () => {
    if (dateRange.length > 0 && selectedChannelId && selectedVersion && selectedName) {
      const start = dateRange[0].format('YYYY-MM-DD');
      const end = dateRange[1].format('YYYY-MM-DD');
      const page = 1;
      const pagesize = 10;
      try {
        const responseData = await getTransaction(start, end, selectedChannelId, selectedVersion, page, pagesize);
        if (responseData && responseData.items && responseData.items.length > 0) {
          setDataAvailable(true);
          setDataList(responseData);
          setFailed(false); // ตั้งค่าให้ไม่มีข้อมูลที่ล้มเหลว
        } else {
          console.log('No data found');
          setDataAvailable(false); // ตั้งค่าให้ไม่พร้อมใช้งาน
          setDataList([]); // เคลียร์ข้อมูลที่แสดง
          setFailed(true); // ตั้งค่าให้มีข้อมูลที่ล้มเหลว
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setDataAvailable(false); // ตั้งค่าให้ไม่พร้อมใช้งาน
        setDataList([]); // เคลียร์ข้อมูลที่แสดง
        setFailed(true); // ตั้งค่าให้มีข้อมูลที่ล้มเหลว
      } finally {
      }
    } else {
      console.log('Please select date range, channel, version, and name');
      setFailed(true); // ตั้งค่าให้มีข้อมูลที่ล้มเหลว
      setDataAvailable(false); // ตั้งค่าให้ไม่พร้อมใช้งาน
      setDataList([]); // เคลียร์ข้อมูลที่แสดง
    }
  };
  
  
  const handleVersionChange = (value) => {
    setSelectedVersion(value);
  };

  const handleFunctionExport = () => {
    if (dateRange && dateRange.length > 1) {
      const start = dateRange[0]?.format('YYYY-MM-DD');
      const end = dateRange[1]?.format('YYYY-MM-DD');
      if (start && end) {
        NonVoiceTransactionExport(start, end, selectedName , selectedChannelId, selectedVersion);
      }
    }
  };

  const handleNameChange = async (value) => {
    setSelectedName(value);
    try {
      const channelsResponse = await getChannel(value);
      const uniqueChannels = [...new Set(channelsResponse.map(channel => channel.surveyChannelName))];
      setChannels(uniqueChannels.map(channelName => ({
        surveyChannelName: channelName,
        surveyChannelId: channelsResponse.find(channel => channel.surveyChannelName === channelName).surveyChannelId,
        versions: channelsResponse.filter(channel => channel.surveyChannelName === channelName).map(channel => channel.version)
      })));
      setSelectedChannel(null);
      setSelectedVersion(null);
      setSelectedChannelId(null);
    } catch (error) {
      console.error('Error fetching channels:', error);
      setChannels([]);
    }
  };

  const handleChannelChange = async (value) => {
    setSelectedChannel(value);
    setSelectedVersion(null);
    try {
      const selectedChannelData = channels.find(channel => channel.surveyChannelName === value);
      if (selectedChannelData) {
        setSelectedChannelId(selectedChannelData.surveyChannelId);
        if (selectedChannelData.surveyChannelId) {
          setVersions(selectedChannelData.versions);
        } else {
          setVersions([]);
        }
      }
    } catch (error) {
      console.error('Error fetching versions:', error);
      setVersions([]);
    }
  };


  return (
    <>
<div className="bg-white rounded-lg shadow-md p-6 flex flex-col" >
<Space direction="vertical" size={12} style={{ width: '30%' }}>
            <span>Date</span>
            <RangePicker
              value={dateRange}
              style={{ width: '500px' }}
              onChange={handleDateRangeChange}
              //className="rounded"
            />
          </Space>
<br />
<div className="row" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
  <span style={{ flex: '1', marginRight: '50px' }}>Name</span>
  <span style={{ flex: '1', marginRight: '50px' }}>Channel Name</span>
  <span style={{ flex: '1' }}>Version</span>
</div>

<div className="flex justify-between items-center mb-4">
              <Select
                value={selectedName}
                style={{ width: '500px' }}
                onChange={handleNameChange}
              >
                <Option value={null}>Select Name</Option>
                {name.map((names) => (
                  <Option key={names.id} value={names.id}>
                    {names.name}
                  </Option>
                ))}
              </Select>

              <Select
                value={selectedChannel}
                style={{ width: '500px' }}
                onChange={handleChannelChange}
                disabled={!selectedName}
              >
                <Option value={null}>Select Channel</Option>
                {channels.map((channel) => (
                  <Option key={channel.surveyChannelId} value={channel.surveyChannelName}>
                    {channel.surveyChannelName}
                  </Option>
                ))}
              </Select>

              <Select
                value={selectedVersion}
                style={{ width: '500px' }}
                onChange={handleVersionChange}
                disabled={!selectedChannel}
              >
                <Option value={null}>Select Version</Option>
                {versions.map((version) => (
                  <Option key={version} value={version}>
                    {version}
                  </Option>
                ))}
              </Select>
</div>
<div className='row' style={{ display: 'flex', justifyContent: 'flex-end' }}>
  <Button
    size='large'
    type="default"
    shape="default"
    icon={<SyncOutlined />}
    onClick={handleClear}
  >
    Clear
  </Button>

  <Button
    className='ml-4'
    type="primary"
    size='large'
    onClick={handleSubmit}
    disabled={
      !(dateRange.length > 0 && selectedChannelId && selectedVersion && selectedName)
    }
  >
    Submit
  </Button>
</div>
</div>

      <br />
      <div className="bg-white rounded-lg shadow-md p-6 flex flex-col relative">
        <div className="flex justify-between items-center mb-4">
          <Space direction="vertical" size={12} style={{ width: '100%' }}>
            <span className="text-lg font-bold">Non Voice Transaction Report</span>
          </Space>
          <Button
          className={dataAvailable && "primary-outline"}
          type="primary"
          size='large'
          icon={<DownloadOutlined />}
          onClick={handleFunctionExport}
          disabled={!dataAvailable}
        >
          Export
        </Button>
        </div>
       {Failed ? (
            <div style={{ textAlign: 'center', padding: '20px', color: '#666' }}>
              <img style={{ margin: "auto"}} src={nodata}></img>
            </div>
          ) : (
            <TransactionTable data={dataList} onPageChange={setDataList} dateRange={dateRange} selectedName={selectedName} selectedChannel={selectedChannelId} selectedVersion={selectedVersion} />
          )}

      </div>
      <br />
    </>
  );
}

export default Transaction;

