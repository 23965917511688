
import React, { useEffect, useState, useRef, useContext } from "react";
import {
    Table,
    Dropdown,
    Space,
    Button,
    Form,
    Input,
    Flex,
    Drawer,
    Row,
    Col,
    Select,
    message,
    InputNumber,
    Modal,
    Switch,
    Tooltip,
    ConfigProvider,
    Radio,
    Checkbox
  } from "antd";
  import { AppContext } from "./CustomerSurveyForm";
  import { DetailAppContext } from "./SurveyTransactionDetail";
  import { SurveyFormListAppContext } from "./SurveyFormList";
  import { SurveyFormHistoryDetailAppContext } from "./SurveyFormHistoryDetail";
  import { SurveyFormDetailAppContext } from "./SurveyFormDetail";
  const { TextArea } = Input;

//   let defaultValue = "";
  let defaultCheckedBox = [];
  let answerText = "";
  let oldSubmit = 0;

export default function Question({response, isDetailMode, submitIndex, onSendData, isTouchable, page}){
let questionOption = {options:[]};
let isClick = oldSubmit !== submitIndex;
// const [questionState, setQuestionState] = useState(questionOption);
// let isDetail = false
let context = AppContext;
// if(isDetailMode){
if(page === "surveyTransactionDetail"){
    // isDetail = true; 
    context = DetailAppContext;
}else if(page === "surveyFormList"){
    context = SurveyFormListAppContext;
}else if(page === "surveyFormHistoryDetail"){
    context = SurveyFormHistoryDetailAppContext;
}else if(page === "surveyFormDetail"){
    context = SurveyFormDetailAppContext;
}else{
    context = AppContext
}
// const [ defaultCheckedBox, setDefaultCheckedBox] = useState();
const { questionState, setQuestionState, setIsDisabled } = useContext(context);
// const [isCurrentSelectisVariantValue, setIsCurrentSelectisVariantValue] = useState(false);
// const [defaultValue, setDefaultValue] = useState();


    useEffect(() => {
        if(isClick){
            const { options } = questionState;
            if(!isInValid(options)){
                if(onSendData !== undefined){
                    onSendData();
                }
            }
        }
        oldSubmit = submitIndex;
        validate (questionState)
        console.log("Select answer : ",questionState);
        console.log("Click total : ", submitIndex);
        // updateItem(questionState);
    }, [submitIndex])

    // useEffect(() => {
    //     console.log("Send data");
    //     // updateItem(questionState);
    // }, [submitIndex])

    const onCheckBoxChange = (checkedValues, id, responseValidationNo) => {
        console.log('id = ', id);
        console.log('checked = ', checkedValues);
        console.log('Check at least = ', responseValidationNo);
        // setQuestionState(questionOption);
        const { options } = questionState;
        const nextState = options.map((opt) => {

            let questionData = findQuestion(
                response.data.surveyForm.questions,
                opt.questionId
              );
              //    console.log("Answers : ", answerList);
                let isVariantValue = false;
                let currentAnswer = undefined;
              if(checkedValues.length > 0){
                let lastData = checkedValues[checkedValues.length-1];
                currentAnswer = findCurrentAnswer(
                    questionData.answers,
                    lastData
                );
                if(currentAnswer != undefined){
                    isVariantValue = currentAnswer.isVariantValue;
                }
                // let isCheckboxiIsVariantValue = findCheckboxiIsVariantValue(questionData.answers, checkedValues);
                // setIsCurrentSelectisVariantValue(isCheckboxiIsVariantValue);
              }

            //   return { ...opt, selected: checkedValues, isVariantValue: isVariantValue, surveyFormAnswerHistoryId: currentAnswer !== undefined ? currentAnswer.id : null , surveyFormQuestionHistoryId: currentAnswer !== undefined ? currentAnswer.surveyFormQuestionHistoryId : null  };
            if (opt.questionId !== id) {
                // return { ...opt, isVariantValue: isVariantValue, value:""  };
                return { ...opt, };
            } else {
              return { ...opt, selected: checkedValues, isVariantValue: isVariantValue, value:"",surveyFormAnswerHistoryId: currentAnswer !== undefined ? currentAnswer.id : null , surveyFormQuestionHistoryId: currentAnswer !== undefined ? currentAnswer.surveyFormQuestionHistoryId : null  };
            }
          });
    
          setQuestionState({ ...questionState, options: nextState}, function(data) {
            validate(data);
          });
        //   console.log("Select answer : ",questionState);
      };

    const onRadioChange = (e) => {
        console.log("radio checked", e.target.value);
        //   setValue(e.target.value);
        // setQuestionState(questionOption);
        const { options } = questionState;
        const { name, value } = e.target;
        console.log(name, value);
  
        const nextState = options.map((opt) => {
            let questionData = findQuestion(
                response.data.surveyForm.questions,
                opt.questionId
              );
              //    console.log("Answers : ", answerList);
              let isVariantValue = false;
              let currentAnswer = findCurrentAnswer(
                questionData.answers,
                value
              );
              if(currentAnswer != undefined){
                isVariantValue = currentAnswer.isVariantValue;
                // setIsCurrentSelectisVariantValue(currentAnswer.isVariantValue);
              }
          if (opt.questionId !== name) {
            // return { ...opt, isVariantValue: isVariantValue, value:"", };
            return { ...opt,  };
          } else {
            return { ...opt, selected: value, isVariantValue: isVariantValue, value:"", surveyFormAnswerHistoryId: currentAnswer !== undefined ? currentAnswer.id : null, surveyFormQuestionHistoryId: currentAnswer !== undefined ? currentAnswer.surveyFormQuestionHistoryId : null };
          }
        // return { ...opt, selected: value, isVariantValue: isVariantValue, surveyFormAnswerHistoryId: currentAnswer !== undefined ? currentAnswer.id : null, surveyFormQuestionHistoryId: currentAnswer !== undefined ? currentAnswer.surveyFormQuestionHistoryId : null };
        });
  
        setQuestionState({ ...questionState, options: nextState}, function(data) {
            validate(data);
          });
        
        // console.log("Select answer : ",questionState);
      };

      const onTextChange = (e) => {
        console.log("text change : ", e.target.value);
        const { options } = questionState;
        const { name, value } = e.target;
        console.log(name, value);
  
        const nextState = options.map((opt) => {
          if (opt.questionId !== name) {
            return { ...opt };
          } else {
            if(opt.isVariantValue || opt.questionType === "3"){
                return { ...opt, value: value };
            }
            return { ...opt, };
            // return { ...opt, selected: value };
          }
        });
  
        setQuestionState({ ...questionState, options: nextState}, function(data) {
            validate(data);
          });
      };

    if(response == undefined){
        return;
    }
    // if(response.data.surveyForm.questions.length > 0){
    //   response.data.surveyForm.questions.map((data) => {
    //     questionOption.options.push({
    //       questionId: data.surveyFormQuestionId,
    //       selected: "",
    //       questionType: data.questionTypeId,
    //       responseValidationNo: data.responseValidationNo,
    //       isValid: false,
    //     });
    //   });
    //   console.log("Question total : ",questionOption);
    // //   setQuestionState(questionOption);
    // }
    console.log("Response : ",response);
        //   setQuestionState(questionOption);
   
    //   questionState.options.push({ questionName: "6", selected: "" });

   

     const dataList = response.data.surveyForm.questions;

     function isCheckboxHasVariantValue(options, questionId){
        let questionData = findQuestion(
            response.data.surveyForm.questions,
            questionId
          );
        let isInValid = false;
        let data = findCurrentAnswerHasVariantValue(questionData.answers)
        if(undefined == data){
            isInValid = false;
            return isInValid;
        }
        let selectAnswer = findCurrentSelectedAnswer(options, data.surveyFormQuestionId);
        if(selectAnswer.isVariantValue && selectAnswer.value == ""){
            isInValid = true;
        }
        // options.map((opt) => {
        //     if(opt.isVariantValue && opt.value == ""){
        //         isInValid = true;
        //         return isInValid;
        //     }
        // });
        return isInValid;
     }

     function validate (questionState) {

        const { options } = questionState;
        const nextState = options.map((opt) => {
            if(opt.questionType === '1'){
                if(opt.selected !== ""){
                    if(opt.isVariantValue && opt.value == ""){
                        return { ...opt, isValid: false }
                    }else{
                        return { ...opt, isValid: true }
                    }
                }else{
                    return { ...opt, isValid: false }
                }

            }else if(opt.questionType === '2'){
                if(opt.selected !== ""){
                    if(isCheckboxHasVariantValue(options, opt.questionId)){
                        return { ...opt, isValid: false }
                    }
                    if(opt.responseValidationId === "2"){
                        if(opt.selected.length >= opt.responseValidationNo){
                            return { ...opt, isValid: true }
                        }else{
                            return { ...opt, isValid: false }
                        }
                    }

                    if(opt.responseValidationId === "3"){
                        if(opt.selected.length <= opt.responseValidationNo){
                            return { ...opt, isValid: true }
                        }else{
                            return { ...opt, isValid: false }
                        }
                    }

                    if(opt.responseValidationId === "4"){
                        if(opt.selected.length == opt.responseValidationNo){
                            return { ...opt, isValid: true }
                        }else{
                            return { ...opt, isValid: false }
                        }
                    } 
                    if(opt.responseValidationId === "1"){
                        return { ...opt, isValid: true }
                    }       
                }else{
                    return { ...opt, isValid: false }
                }
            }else{
                if(opt.value !== ""){
                    return { ...opt, isValid: true }
                }else{
                    return { ...opt, isValid: false }
                }
            }
          });
        //   setQuestionState({ ...questionState, options: nextState });
        if(submitIndex > 0){
            if(isInValid(nextState)){
                setIsDisabled(true);
            }else{
                setIsDisabled(false);
            }
        }
        setQuestionState({ ...questionState, options: nextState}, function(data) {
            console.log(data);
          });

       
     }

     function findCheckboxiIsVariantValue(dataList ,checkValues){
        let result = false;
        dataList.map((data) => {
            checkValues.map((checkData) => { 
                if(checkData == data.surveyFormAnswerId && data.isVariantValue){
                    result = true;
                }
            });
        });
        return result;
     }

     function findAnswer(dataList, questionId){
        let answerList = [];
        if(dataList === undefined){
            return answerList;
        }
        dataList.map((data) => {
            if(data.surveyFormQuestionId === questionId){
                answerList.push(data);
            }
        });
        return answerList;
     }

     function findCurrentAnswer(dataList, answerId){
        if(dataList.length == 0){
            return undefined
        }
        let answer = undefined;
        dataList.map((data) => {
            if(data.surveyFormAnswerId === answerId){
                answer = data;
            }
        });
        return answer;
     }

     function findCurrentSelectedAnswer(dataList, questionId){
        if(dataList.length == 0){
            return undefined
        }
        let answer = undefined;
        dataList.map((data) => {
            if(data.questionId === questionId){
                answer = data;
            }
        });
        return answer;
     }

     function findQuestion(dataList, questionId){
        if(dataList.length == 0){
            return undefined
        }
        let question = undefined;
        dataList.map((data) => {
            if(data.surveyFormQuestionId === questionId){
                question = data;
            }
        });
        return question;
     }

     function findCurrentAnswerHasVariantValue(dataList){
        if(dataList.length == 0){
            return undefined
        }
        let answer = undefined;
        dataList.map((data) => {
            if(data.isVariantValue){
                answer = data;
            }
        });
        return answer;
     }


    function isInValid (options){
        let isInValid = false;
        options.map((data) => {
            if(!data.isValid){
                isInValid = true;
                return isInValid;
            }
        });
        return isInValid;
    }

    function getQuestionName(data, index){
        let id = "question"+index;
        let titleElement = document.getElementById(id);
        if(titleElement !== null){
            titleElement.innerHTML = data.name;
        }
        
    }

    function getDefaultRadio(data){
        let defaultValue = "";
        data.answers.map((answer, index) => {
            let answerList = findAnswer(
                response.data.answers,
                answer.surveyFormQuestionId
              );
              //    console.log("Answers : ", answerList);
              let currentAnswer = findCurrentAnswer(
                answerList,
                answer.surveyFormAnswerId
              );
              let currentAnswerValue = null;
              if(currentAnswer !== undefined){
                defaultValue = answer.surveyFormAnswerId;
              }
        })
        return defaultValue;
    }
    
    return (
      <div>
        {dataList.map((data, index) => {
            let isValid = false;
            if(questionState.options.length > 0){
                let selectAnswer = questionState.options[index];
                if(selectAnswer != undefined){
                    isValid = selectAnswer.isValid;
                }
            }
            let questionDisplay = "";
            if(data.questionTypeId === '1'){
                let defaultValue = getDefaultRadio(data);
                let otherDisplay = "";
                let isCurrentSelectIsVariantValue = false;
                questionDisplay = (
                    <>
                      <Radio.Group
                        style={{ width: "100%", marginBottom: 10 }}
                        className="flex flex-row min-[320px]:max-[600px]:flex-col"
                        onChange={onRadioChange}
                        name={data.surveyFormQuestionId}
                        defaultValue={defaultValue}
                      >
                        {data.answers.map((answer, index) => {
                          let answerList = findAnswer(
                            response.data.answers,
                            answer.surveyFormQuestionId
                          );
                          //    console.log("Answers : ", answerList);
                          let currentAnswer = findCurrentAnswer(
                            answerList,
                            answer.surveyFormAnswerId
                          );
                          let currentAnswerValue = null;
                          if(currentAnswer !== undefined){
                            // defaultValue = answer.surveyFormAnswerId;
                            // if(defaultValue === undefined){
                            //     setDefaultValue(answer.surveyFormAnswerId)
                            // }
                            currentAnswerValue = currentAnswer.value;
                       }

                          let lastIndex =  data.answers.length-1;
                          //defaultValue = answerName.name.trim();
                          
                          if(!isDetailMode){
                            let selectedAnswer = findCurrentSelectedAnswer(
                                questionState.options,
                                answer.surveyFormQuestionId
                              );
                             let currentChoice = findCurrentAnswer(data.answers, answer.surveyFormAnswerId); 
                            let currentElement = document.getElementById(answer.surveyFormAnswerId);
                            var ele = document.getElementsByName(answer.surveyFormQuestionId);
 
                                
                            try{
                                for (let k = 0; k < ele.length; k++) {
                                    if (ele[k].checked){
                                        let result = ele[k].value;
                                        let resultAnswer = findCurrentAnswer(
                                            data.answers,
                                            result
                                          );
                                        if(resultAnswer.isVariantValue){
                                            isCurrentSelectIsVariantValue = true;
                                        }
                                    }
                                        
                                }
                            }catch(err){
                                console.log(err);
                            }
                            
                            if(null != currentAnswer){
                                console.log(currentElement.value);
                            }
                            // && selectedAnswer.selected != "" && selectedAnswer.isVariantValue 
                              if (index == lastIndex && answer.isVariantValue && isCurrentSelectIsVariantValue) {
                                otherDisplay = (
                                  <TextArea
                                    className="mt-2 mb-2 w-full"
                                    defaultValue={currentAnswerValue}
                                    disabled={isTouchable}
                                    name={data.surveyFormQuestionId} onChange={onTextChange}
                                  />
                                );
                              }else{
                                otherDisplay = "";
                              }
                              
                          }else{
                            // otherDisplay = "";
                            if (index == lastIndex && answer.isVariantValue && undefined != currentAnswerValue) {
                                otherDisplay = (
                                  <TextArea
                                    className="mt-2 mb-2 w-full"
                                    defaultValue={currentAnswerValue}
                                    disabled={isTouchable}
                                    name={data.surveyFormQuestionId} onChange={onTextChange}
                                  />
                                );
                              }else{
                                otherDisplay = "";
                              }
                          }
                          return (
                            <Radio
                              key={answer.surveyFormAnswerId}
                              id={answer.surveyFormAnswerId}
                              className="flex-1 mb-[8px]"
                              // value={answer.name.trim()}
                              value={answer.surveyFormAnswerId}
                              disabled={isTouchable}
                            >
                              {answer.name.trim()}
                            </Radio>
                          );
                        })}
                      </Radio.Group>
                      {otherDisplay}
                      {submitIndex > 0 && !isValid ?  <p
                        style={{
                          fontWeight: 700,
                          fontSize: 14,
                          color: "#FF4233",
                        }}
                      >
                        {otherDisplay == "" ? "Please select answer" : "Please input answer"}
                      </p> : ""}
                    </>
                  );

            }else if(data.questionTypeId === '2'){
                let isCurrentSelectIsVariantValue = false;
                let responseValidationNo = data.responseValidationNo;
                let errorMessage = ""
                if(data.responseValidationId === "2"){
                    errorMessage = "Please select answer at least "+responseValidationNo;
                }else  if(data.responseValidationId === "3"){
                    errorMessage = "Please select answer at most "+responseValidationNo;
                }else  if(data.responseValidationId === "4"){
                    errorMessage = "Please select answer at exactly "+responseValidationNo;
                }
                let tempCheckedBox = [];
                let otherDisplay = "";
                questionDisplay = <>
                <Checkbox.Group
                onChange={(event) => onCheckBoxChange(event, data.surveyFormQuestionId, responseValidationNo)}
                className="flex flex-row min-[320px]:max-[600px]:flex-col"
                name={data.surveyFormQuestionId}
                defaultValue={defaultCheckedBox}
                style={{ width: "100%", marginBottom: 10 }}
                >
                     {data.answers.map((answer, index) => {

                let answeredList = findAnswer(
                    response.data.answers,
                    answer.surveyFormQuestionId
                  );
                  //    console.log("Answers : ", answerList);
                  let currentAnswer = findCurrentAnswer(
                    answeredList,
                    answer.surveyFormAnswerId
                  );
                  let currentAnswerValue = null;
                  if(currentAnswer !== undefined){
                    defaultCheckedBox.push(answer.surveyFormAnswerId);
                    currentAnswerValue = currentAnswer.value;
                    let tempLastIndex = data.answers[index-1];
                    // if(defaultCheckedBox === undefined && tempLastIndex === index){
                    //     setDefaultCheckedBox(tempCheckedBox);
                    // }
                }

                  let lastIndex =  data.answers.length-1;
                  //defaultValue = answerName.name.trim();

                  if(!isDetailMode){
                    let selectedAnswer = findCurrentSelectedAnswer(
                      questionState.options,
                      answer.surveyFormQuestionId
                    );
                    let currentChoice = findCurrentAnswer(data.answers, answer.surveyFormAnswerId);
                    var ele = document.getElementsByName(answer.surveyFormQuestionId);
 
                                
                    try{
                        for (let k = 0; k < ele.length; k++) {
                            if (ele[k].checked){
                                let result = ele[k].value;
                                let resultAnswer = findCurrentAnswer(
                                    data.answers,
                                    result
                                  );
                                if(resultAnswer.isVariantValue){
                                    isCurrentSelectIsVariantValue = true;
                                }
                            }
                                
                        }
                    }catch(err){
                        console.log(err);
                    }
                      if (index == lastIndex && answer.isVariantValue && isCurrentSelectIsVariantValue) {
                        otherDisplay = (
                          <TextArea
                            className="mt-2 mb-2 w-full"
                            defaultValue={currentAnswerValue}
                            disabled={isTouchable}
                            name={data.surveyFormQuestionId} onChange={onTextChange}
                          />
                        );
                      }else{
                        otherDisplay = "";
                       
                      }
                      
                  }else{
                    // if (index == lastIndex && answer.isVariantValue) {
                    //     otherDisplay = (
                    //       <TextArea
                    //         className="mt-2 mb-2 w-full"
                    //         defaultValue={currentAnswerValue}
                    //         disabled={isTouchable}
                    //         name={data.surveyFormQuestionId} onChange={onTextChange}
                    //       />
                    //     );
                    //   }else{
                    //     otherDisplay = "";
                    //   }
                    if (index == lastIndex && answer.isVariantValue && undefined != currentAnswerValue) {
                        otherDisplay = (
                          <TextArea
                            className="mt-2 mb-2 w-full"
                            defaultValue={currentAnswerValue}
                            disabled={isTouchable}
                            name={data.surveyFormQuestionId} onChange={onTextChange}
                          />
                        );
                      }else{
                        otherDisplay = "";
                      }
                    // otherDisplay = "";
                  }

                    //    let lastIndex =  data.answers.length-1;
                    //    let lastData = data.answers[lastIndex];
                    //    if (index == lastIndex && lastData.isVariantValue) {
                    //     otherDisplay = <TextArea type="" className="mt-2 mb-2 w-full" defaultValue={currentAnswerValue} disabled={isDisable} name={data.surveyFormQuestionId} onChange={onTextChange}/>
                    //    }

                    return (
                      <Checkbox  key={answer.surveyFormAnswerId}
                        className="flex-1  mt-[14px] mb-[14px]"
                        //min-[320px]:max-[600px]:mb-[14px]
                        value={answer.surveyFormAnswerId}
                        disabled={isTouchable}>
                        {answer.name.trim()}
                    </Checkbox>
                    );
                })}
                
                </Checkbox.Group>
                {otherDisplay}
                {/* submitIndex > 0 && */}
                {submitIndex > 0 && !isValid   ?  <p
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        color: "#FF4233",
                      }}
                    >
                    {otherDisplay == "" ? errorMessage : errorMessage+" and input answer"}
                     
                    </p> : ""}
                </>
            }else if(data.questionTypeId === '3'){
                let answerList = findAnswer(response.data.answers, data.surveyFormQuestionId);
                console.log("Answers : ", answerList);
                if(answerList.length > 0){
                    answerText = answerList[0].value;
                }
                
                // let currentAnswer = findCurrentAnswer(answerList, answer.surveyFormAnswerId)
                // if(currentAnswer !== undefined){
                //     answerText = currentAnswer.value.trim();
                // }
                questionDisplay = <>
                <TextArea className="mt-2 mb-2" defaultValue={answerText} disabled={isTouchable} name={data.surveyFormQuestionId} onChange={onTextChange}/>
                {submitIndex > 0 && !isValid ?  <p
                    style={{
                      fontWeight: 700,
                      fontSize: 14,
                      color: "#FF4233",
                    }}
                  >
                    Please input answer
                  </p> : ""} </>;
                
            }
          return (
            <Row
              key={data.id}
              style={{ backgroundColor: "#F8F8F9", borderRadius: 10 }}
              className="mt-3 ms-7 me-7"
            >
                {/* style={{ padding: "5px 15px" } */}
              <Col span={24} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <Row>
                  <div id={"question"+index} style={{ marginTop: 0, fontWeight: 400,
                        fontSize: 14,
                        color: "#667085", }} dangerouslySetInnerHTML={{__html: data.name}}>
                    {/* <label
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        color: "#667085",
                      }}
                    >
                      {data.name}
                    </label> */}
                    {/* {getQuestionName(data, index)} */}
                  </div>
                </Row>
                <Row>
                  <Col span={24}>
                    <div
                      style={{
                        // backgroundColor: "#fff",
                        // padding: "15px 10px 8px 0px",
                        border: "1px solid #D0D5DD",
                        marginBottom: 5,
                        marginTop: 0,
                        borderRadius: 10,
                        // minHeight: 580,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                    {questionDisplay}
                   
                  {/* <Radio.Group
            style={{ width: "100%",marginBottom: 10, }} 
            className="flex flex-row"
            onChange={onRadioChange}
            name="1"
          >
            <Radio className="flex-1" value={1} disabled={false}>A</Radio>
            <Radio className="flex-1" value={2}>B</Radio>
            <Radio className="flex-1" value={3}>C</Radio>
            <Radio className="flex-1" value={4}>D</Radio>
          </Radio.Group>
          <Radio.Group
            style={{ width: "100%",marginBottom: 10, }} 
            className="flex flex-row"
            onChange={onRadioChange}
            name="2"
          >
            <Radio className="flex-1" value={1} disabled={false}>A</Radio>
            <Radio className="flex-1" value={2}>B</Radio>
            <Radio className="flex-1" value={3}>C</Radio>
            <Radio className="flex-1" value={4}>D</Radio>
          </Radio.Group>
          <Checkbox.Group  onChange={(event) =>
                                  onCheckBoxChange(
                                  event,
                                  "test1"
                                )
                              } className="flex flex-row" style={{ width: "100%",marginBottom: 10, }} >
            <Checkbox className="flex-1" value={1}>1</Checkbox>
            <Checkbox className="flex-1" value={2}>2</Checkbox>
            <Checkbox className="flex-1" value={3}>3</Checkbox>
            <Checkbox className="flex-1" value={4}>4</Checkbox>
          </Checkbox.Group>
          <Checkbox.Group onChange={(event) =>
                                  onCheckBoxChange(
                                  event,
                                  "test2"
                                )
                              } className="flex flex-row" style={{ width: "100%",marginBottom: 10, }} >
            <Checkbox className="flex-1" value={1}>1</Checkbox>
            <Checkbox className="flex-1" value={2}>2</Checkbox>
            <Checkbox className="flex-1" value={3}>3</Checkbox>
            <Checkbox className="flex-1" value={4}>4</Checkbox>
          </Checkbox.Group> */}
                </Row>
              </Col>
            </Row>
          );
        })}
      </div>
    );
}

