import Icon from "@ant-design/icons";

const icon = () => (
  
<svg width="126" height="126" viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="8" y="8" width="110" height="110" rx="42" fill="#FFEDCA"/>
<rect x="8" y="8" width="110" height="110" rx="42" stroke="#FFF8EB" stroke-width="15"/>
<g clip-path="url(#clip0_1723_51436)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M38.6245 63C38.6245 49.5381 49.5376 38.625 62.9995 38.625C76.4615 38.625 87.3745 49.5381 87.3745 63C87.3745 76.4619 76.4615 87.375 62.9995 87.375C49.5376 87.375 38.6245 76.4619 38.6245 63ZM62.9995 53.625C64.035 53.625 64.8745 54.4645 64.8745 55.5V64.875C64.8745 65.9105 64.035 66.75 62.9995 66.75C61.964 66.75 61.1245 65.9105 61.1245 64.875V55.5C61.1245 54.4645 61.964 53.625 62.9995 53.625ZM62.9995 74.25C64.035 74.25 64.8745 73.4105 64.8745 72.375C64.8745 71.3395 64.035 70.5 62.9995 70.5C61.964 70.5 61.1245 71.3395 61.1245 72.375C61.1245 73.4105 61.964 74.25 62.9995 74.25Z" fill="#FFC044"/>
</g>
<defs>
<clipPath id="clip0_1723_51436">
<rect width="60" height="60" fill="white" transform="translate(32.9995 33)"/>
</clipPath>
</defs>
</svg>

);

const icon_exclamationmark_dialog = (props) => <Icon component={icon} {...props} />;

export default icon_exclamationmark_dialog;

