
const env_dev = "https://api-web-survey.adaonline.me";
const env_dev_not_gateway = "https://api.onion-solutions.com";
//const env_dev = "https://localhost:7285";

const environment = {
  SERVICE_URL_EV: env_dev,
  SERVICE_URL_AUTH: env_dev + "/service-auth",
  SERVICE_URL_USER_PROFILE: env_dev + "/service-userprofile",
  SERVICE_URL_LANDLORD: env_dev + "/service-landlord",
  SERVICE_URL_MERCHANT: env_dev + "/service-merchant",
  SERVICE_URL_ADMIN: env_dev + "/service-admin",
  SERVICE_URL_BOOKING: env_dev + "/service-booking",
  SERVICE_URL_UPLOAD: env_dev_not_gateway + "/service-file-storage",
  SERVICE_URL_PROMOTION: env_dev + "/service-promotion",
  SERVICE_URL_REPORT: env_dev + "/reports",
  SERVICE_URL_DASHBOARD: env_dev + "/dashboard",
  

  production: false,
  // SERVICE_URL_AUTH: env_dev,
  // SERVICE_URL_LOGOUT: env_dev,
  SERVICE_URL: env_dev,


  // ### AUTH ###
  API_AUTH_ENDPOINT: "/v1/auth",
  API_AUTH_LOGIN:"/auth/login",
  API_AUTH_LOGOUT:"/auth/logout",
  API_AUTH_REFRESH:"/auth/refresh-token1",
  API_AUTH_SENDMAIL:"/auth/SendMail",
  API_AUTH_CHANGE_PASSWORD:"/users",

  // ### LOGIN ###
 
  //API_LOGIN_WEB: "/v1/s/web/user/login",

  // ### USER ###
  API_GET_USER_BY_ID: "/users/",
  API_SEARCH_USER: "/users",
  API_NEW_USER: "/users",
  API_EDIT_USER: "/users",
  API_ACTION_USER: "/users",
  API_USER_CHANGE_PASSWORD: "/users/ChangePassword/",
  API_RESET_USER: "/user-accounts",
  

  // ### ROLE ###
  API_GET_ROLE: "/roles",
  API_GET_ROLE_ID: "/roles",
  API_UPDATE_ROLE: "/roles",



  // ### SURVEY TRANSACTION ###
  API_SURVEY_TRANSACTION: "/survey-form-transactions",

  // ### IVR TRANSACTION ###
  API_IVR_TRANSACTION: "/ivr/ivr-hit-transactions",

   // ### CUSTOMER SURVEY FORM ###
   API_CUSTOMER_SURVEY_FORM: "/customer-survey-form-transactions",

   // ### SURVEY FORM ###
   API_SURVEY_FORM: "/survey-forms",
   API_SURVEY_FORM_HISTORY: "/survey-form-histories",
};

export default environment;
