import React, { useState, useEffect } from 'react';
import { DatePicker, Space, Button } from 'antd';
import { DownloadOutlined, SyncOutlined } from '@ant-design/icons';
import { getVoiceTransaction, VoiceTransactionExport } from '../service/reportService';
import VoiceTransactionReportTable from './TableVoiceTransactionReport';
import nodata from "../assets/img/NoData.png";

const { RangePicker } = DatePicker;

const TransactionReport = () => {
  const [dateRange, setDateRange] = useState([]);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [Failed, setFailed] = useState(null);

  useEffect(() => {
    if (dateRange && dateRange.length > 0) {
      console.log('Selected date range:', dateRange);
    }
  }, [dateRange]);

  const handleDateRangeChange = (dates) => {
    setDateRange(dates || []);
  };

  const handleClear = () => {
    setDateRange([]);
    setDataList([]);
    setFailed(null);
    setDataAvailable(false);
  };

  const handleSubmit = async () => {
    if (dateRange && dateRange.length > 1) {
      const start = dateRange[0]?.format('YYYY-MM-DD');
      const end = dateRange[1]?.format('YYYY-MM-DD');
      const page = 1;
      const pagesize = 10;
      if (start && end) {
        try {
          const responseData = await getVoiceTransaction(start, end, page, pagesize);
          if (responseData && responseData.items && responseData.items.length > 0) {
            setDataAvailable(true);
            setDataList(responseData);
            setFailed(false);
          } else {
            setFailed(true);
            setDataAvailable(false);
          }
        } catch (error) {
          console.error('เกิดข้อผิดพลาดในการดึงข้อมูล:', error);
          setFailed(true);
          setDataAvailable(false);
        }
      }
    } else {
      setFailed(true);
      setDataAvailable(false);
    }
  };

  const handleFunctionExport = () => {
    if (dateRange && dateRange.length > 1) {
      const start = dateRange[0]?.format('YYYY-MM-DD');
      const end = dateRange[1]?.format('YYYY-MM-DD');
      if (start && end) {
        VoiceTransactionExport(start, end);
      }
    }
  }

  return (
    <>
   <div className="bg-white rounded-lg shadow-md p-6 flex flex-col">
  <div className="flex justify-between items-center mb-4">
    <Space direction="vertical" size={12} style={{ width: '100%' }}>
      <span>Date</span>
      <div className="flex items-center">
        <RangePicker
          value={dateRange}
          onChange={handleDateRangeChange}
          className="rounded"
          style={{ width: '50%' }}
        />
        <div className="flex ml-4">
          <Button
            size='large'
            type="default"
            shape="default"
            icon={<SyncOutlined />}
            onClick={handleClear}
          >
            Clear
          </Button>
          <Button
            className='ml-4'
            type="primary"
            onClick={handleSubmit}
            disabled={dateRange.length === 0}
          >
            Submit
          </Button>
        </div>
      </div>
    </Space>
  </div>
</div>


      <br />

      <div className="bg-white rounded-lg shadow-md p-6 flex flex-col relative">
        <div className="flex justify-between items-center mb-4">
          <Space direction="vertical" size={12} style={{ width: '100%' }}>
            <span className="text-lg font-bold">Voice Transaction Report</span>
          </Space>
          <Button
            className={dataAvailable && "primary-outline"}
            type="primary"
            size='large'
            icon={<DownloadOutlined />}
            onClick={handleFunctionExport}
            disabled={!dataAvailable}
          >
            Export
          </Button>
        </div>
        {Failed === null || Failed ? (
          <div style={{ textAlign: 'center', padding: '20px', color: '#666' }}>
            <img style={{ margin: "auto" }} src={nodata} alt="No data" />
          </div>
        ) : (
          <VoiceTransactionReportTable
            data={dataList}
            onPageChange={setDataList}
            dateRange={dateRange}
            getVoiceSummaryReporst={getVoiceTransaction}
          />
        )}
      </div>
    </>
  );
}

export default TransactionReport;
