import environment from "../environment/environment";
// import httpRequest from "./httpService";
import axios from "axios";

export default class PermissionService {
  url = environment.SERVICE_URL_EV;
  urllandlord = environment.SERVICE_URL_EV;

  getPermissionList(value) {
    
    return axios.get( environment.SERVICE_URL_EV + environment.API_GET_ROLE);
  }

  getPermissionDetail(id) {
    return axios.get(environment.SERVICE_URL_EV + environment.API_GET_ROLE_ID+"/" + id);
  }

  updatePermission(detail,value) {
    let requestObject = {
      id: detail.id,
      name: detail.name,
      description:  detail.description,
      permissions: value,
    };
    return axios.put(environment.SERVICE_URL_EV + environment.API_UPDATE_ROLE+"/"+detail.id ,requestObject);
  }

  getPermissionForuserId(id){
    return axios.get( environment.SERVICE_URL_EV + "/users/" + id);
  }
}
