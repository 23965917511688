import React, { useEffect,useState, } from "react";
import { useNavigate  } from "react-router-dom";
import { Col, Row, Card,Flex, Tag ,Select,Radio,Button,Progress,Tooltip } from 'antd';
import landingSVG from '../assets/img/landing.svg';





const Landing = () => {

  const navigate = useNavigate();
  const _ = require("lodash");

 

  const monthYearStr = new Date().toLocaleString('en-US', { month: 'long' }) +" "+ new Date().getFullYear();


  useEffect(() => {
  }, []);

  

  return (
    
       <div className="bg-white rounded-lg p-6 flex flex-col relative " style={{height:"50em"}}>
        <div className="flex justify-between items-center mb-5 pl-3 pr-3" style={{display: "flex",alignItems: "center",height: "100vh",justifyContent:"center"}}>
          <div className="" style={{ textAlign: 'center'}}>
            <img src={landingSVG} alt="Your SVG Image" />
            <h2 style={{color:"#667085"}}>Please Select Activities Side Menu to Begin Your Process</h2>
          </div>
          <br/>
          
        </div>
        
        </div>


        
   
  );
};

export default Landing;
