import environment from "../environment/environment";
import httpRequest from "./httpService";
import axios from "axios";

export default class UserListService {
  url = environment.SERVICE_URL_EV;

  getUserList(value, page) {
    console.log("value", value);
    console.log("page", page);
    let requestObject = {
      keywords: value.keywords || "", 
      isActivated: value.isActivated !== undefined ? value.isActivated : "",
    };
    let pageValue = {
      page: page.page,
      pageSize: page.pageSize,
      sortBy: page.sortBy,
      sortDirection: page.sortDirection,
    };

    return httpRequest.get(
      this.url +
        environment.API_SEARCH_USER +
        "?Keywords=" +
        requestObject.keywords +
        "&isActivated=" +
        requestObject.isActivated +
        "&Pagination.Page=" +
        pageValue.page +
        "&Pagination.PageSize=" +
        pageValue.pageSize
    );
}


  lockUser(id) {
    if (typeof id === 'object') {
      if (id.hasOwnProperty('id')) {
          id = id.id; 
      } else {
          console.error("Invalid id object:", id);
          return; 
      }
      }
    var formData = {
      id: id,
      newPassword: null,
    };
    return httpRequest.patch(
      this.url + "/" +"users" + "/" + id + "/reset-password",formData
     );
}

  activatedUser(id, status) {
    console.log("id", id, "status", status);
    var formData = {
      id: id,
      isActivated: !status,
    };

    return httpRequest.put(
      this.url + environment.API_EDIT_USER + "/" + id + "/activated-status",
      formData
    );
  }

  getUserDetail(id) {
    // return axios.get(
    //   environment.SERVICE_URL_EV + environment.API_GET_USER_BY_ID + id, {
    //     headers: {
    //       'Access-Control-Allow-Origin' : '*',
    //     },
    //     responseType: "json",
    //   }
    // );
    return httpRequest.get(environment.SERVICE_URL_EV + environment.API_GET_USER_BY_ID + id);
  }

  updateUser(value) {
    let requestObject = {
      id: value.id ? `${value.id}` : "",
      firstName: value.firstName ? `${value.firstName}` : "",
      lastName: value.lastName ? `${value.lastName}` : "",
      email: value.email ? `${value.email}` : "",
      tel: value.tel ? `${value.tel}` : "",
      //isLocked: false,
      isActivated: value.status,
      roleId: value.roleId,
    };

    console.log("ttttt", requestObject);

    return axios.put(
      environment.SERVICE_URL_EV + environment.API_EDIT_USER + "/" + value.id,
      requestObject
    );
  }
  createUser(value) {
    let requestObject = {
      firstName: value.firstName ? `${value.firstName}` : "",
      lastName: value.lastName ? `${value.lastName}` : "",
      email: value.email ? `${value.email}` : "",
      tel: value.tel ? `${value.tel}` : "",
      isLocked: false,
      isActivated: value.status,
      roleId: value.roleId,
    
   };
   console.log("data" + requestObject.isActivated)
    return axios.post(
      environment.SERVICE_URL_EV + environment.API_NEW_USER,
      requestObject
    );
  }
}
