import environment from "../environment/environment";
import httpRequest from "./httpService";
import axios from "axios";

export default class SurveyTractionService {
  url = environment.SERVICE_URL_EV;
  urldev = environment.API_SURVEY_TRANSACTION;

  getSurveyList(value) {
    let keywords = value.keywords
    if(keywords === undefined){
      keywords = "";
    }
    let sortBy = value.sortBy
    if(sortBy === undefined){
      sortBy = "";
    }
    let sortDestination = value.sortDirection
    if(sortDestination === undefined){
      sortDestination = "";
    }
    // return axios.get("https://localhost:7285/users");
    // return httpRequest.get(this.url + this.urldev+"?Keywords=&Pagination.Page=1&Pagination.PageSize=10");
    return httpRequest.get(this.url + this.urldev+"?Keywords="
    +keywords+"&Pagination.Page="+value.page+"&Pagination.PageSize="
    +value.pageSize+"&Pagination.SortBy="+sortBy+"&Pagination.SortDestination="+sortDestination);
   
  }

  getSurveyDetail(id) {
    return httpRequest.get(this.url + this.urldev+"/" + id);
  }

  updateUser(value) {
    let requestObject = {
      id: value.id ? `${value.id}` : "",
      firstName: value.firstName ? `${value.firstName}` : "",
      lastName: value.lastName ? `${value.lastName}` : "",
      email: value.email ? `${value.email}` : "",
      tel: value.tel ? `${value.tel}` : "",
      isLocked: value.isLocked,
      isActivated: value.isActivated,
      roleId: value.roleId,
    };
    var formData = {
      requestObject: requestObject,
    };

    return httpRequest.get("https://localhost:7285/users",formData);
  }
}
