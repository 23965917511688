import React, { useEffect, useState } from 'react';
import { UserApi } from "../service/UserApi";
import { Button } from 'antd';

const User = () => {
  const [users, setUsers] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await UserApi.search({}, true);
        setUsers(response.items);
      } catch (error) {
        // Handle error or redirect to login
      }
    };

    fetchProfile();
  }, []);

  // if (!user) {
  //   return <div>Loading...</div>;
  // }

  return (
    // <div>
    //   <h2>Welcome, {user.name}!</h2>
    //   <p>Email: {user.email}</p>
    //   {/* Render other user details */}
    // </div>
    <>{JSON.stringify(users)}
    <Button type="primary">Submit</Button>
    <Button type="primary" danger size="large">
      Primary
    </Button>
    </>
  );
};

export default User;