import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DatePicker, Space, Button ,Tabs } from 'antd';
import { ReloadOutlined, SearchOutlined, SyncOutlined, FileOutlined, DownloadOutlined } from '@ant-design/icons';
import { getIvrHit, IVRHITExport } from '../service/reportService';
import SimpleTable from './TableIvrHit';
import NonVoice from './NonVoiceSummaryReport';
import Transaction from './NonVoiceTransactionReport';
import TransactionReport from './VoiceTransactionReport';
import VoiceSummaryReport from './VoiceSummaryReport';
import Failed_img from "../assets/img/Failed.png";
import Nodata from "../assets/img/NoData.png";
const { RangePicker } = DatePicker;

const ReportList = () => {
  const [dateRange, setDateRange] = useState([]);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [Failed, setFailed] = useState([]);
  const [showReport, setShowReport] = useState(true);
  const [showNonVoiceReport, setShowNonVoiceReport] = useState(false);
  const [transactionReport, setShowTransactionReport] = useState(false);
  const [voiceSummaryReport, setShowVoiceSummaryReport] = useState(false);
  const [voiceTransactionReport, setShowVoiceTransactionReport] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [exportDisabled, setExportDisabled] = useState(true);
  const location = useLocation();

  const { TabPane } = Tabs;



  useEffect(() => {
    if (dateRange && dateRange.length === 2) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    

      console.log("location.state", location.state);
      if (location.state && location.state.reportName) {
        if (location.state.reportName === "nonVoiceSummary") {
          handleMenuClick2();
          setActiveKey(2)
        } else if (location.state.reportName === "voiceSummary") {
          handleMenuClick4();
          setActiveKey(4)
        }
      }

  }, [dateRange]);

  useEffect(() => {
    if (dataAvailable && dataList.length > 0) {
      setExportDisabled(false);
    } else {
      setExportDisabled(true);
    }
  }, [dataAvailable, dataList]);

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const handleClear = () => {
    setDateRange([]);
    setDataList([]);
    setFailed([]);
    setDataAvailable(false);
  };

  const handleSubmit = async () => {
    if (dateRange && dateRange.length > 1) {
      const start = dateRange[0]?.format('YYYY-MM-DD');
      const end = dateRange[1]?.format('YYYY-MM-DD');
      const page = 1;
      const pagesize = 10;
      if (start && end) {
        try {
          const responseData = await getIvrHit(start, end, page, pagesize);
          console.log('Data fetched successfully:', responseData);
          setDataAvailable(true);
          setDataList(responseData);
          setFailed("0");
        } catch (error) {
          console.error('Error fetching data:', error);
          setFailed(null);
        }
      }
    } else {
      console.error('Please select date range.');
      setFailed(null);
    }
  };

  const handleMenuClick = () => {
    setShowReport(true);
    setShowNonVoiceReport(false);
    setShowTransactionReport(false);
    setShowVoiceSummaryReport(false);
    setShowVoiceTransactionReport(false);
  };

  const handleMenuClick2 = () => {
    setShowReport(false);
    setShowNonVoiceReport(true);
    setShowTransactionReport(false);
    setShowVoiceSummaryReport(false);
    setShowVoiceTransactionReport(false);
  };

  const handleMenuClick3 = () => {
    setShowReport(false);
    setShowNonVoiceReport(false);
    setShowTransactionReport(true);
    setShowVoiceSummaryReport(false);
    setShowVoiceTransactionReport(false);
  };

  const handleMenuClick4 = () => {
    setShowReport(false);
    setShowNonVoiceReport(false);
    setShowTransactionReport(false);
    setShowVoiceSummaryReport(true);
    setShowVoiceTransactionReport(false);
  };

  const handleMenuClick5 = () => {
    setShowReport(false);
    setShowNonVoiceReport(false);
    setShowTransactionReport(false);
    setShowVoiceSummaryReport(false);
    setShowVoiceTransactionReport(true);
  };

  const handleFunctionExport = () => {
    if (dateRange && dateRange.length > 1) {
      const start = dateRange[0]?.format('YYYY-MM-DD');
      const end = dateRange[1]?.format('YYYY-MM-DD');
      if (start && end) {
        IVRHITExport(start, end);
      }
    }
  };
  const [activeKey, setActiveKey] = useState('1');
  const handleTabClick = (key, event) => {
    console.log('Tab clicked:', key);
    if(key == 1){
      handleMenuClick();
    }else if(key == 2){
      handleMenuClick2();
    }else if(key == 3){
      handleMenuClick3();
    }else if(key == 4){
      handleMenuClick4();
    }else if(key == 5){
      handleMenuClick5();
    }
  };

  return (
    <>
      <div className="bg-white rounded-lg shadow-md pl-6 pr-6 flex flex-col">
        <div className="flex justify-between items-center">
          <p className="text-lg font-bold" style={{fontSize: 24, fontWeight: 700}}>Report</p>
        </div>
        <div className="flex space-x-4 flex-wrap">
        <Tabs defaultActiveKey="1" Tabs activeKey={activeKey} onChange={setActiveKey} onTabClick={handleTabClick} className="report-tabs">
          <TabPane tab="IVRHIT Report" key="1" />
          <TabPane tab="Non Voice Summary Report" key="2" />
          <TabPane tab="Non Voice Transaction Report" key="3" />
          <TabPane tab="Voice Summary Report" key="4" />
          <TabPane tab="Voice Transaction Report" key="5" />
        </Tabs>
        </div>
      </div>

      <br />

      {showReport && (
        <>
 <div className="bg-white rounded-lg shadow-md p-6 flex flex-col">
  <div className="flex justify-between items-center mb-4">
    <Space direction="vertical" size={12} style={{ width: '100%' }}>
      <span>Date</span>
      <div className="flex items-center">
        <RangePicker
          value={dateRange}
          onChange={handleDateRangeChange}
          style={{ width: '50%' }}
        />
        <div className="">
          <Button
            className="ml-4"
            size='large'
            type="default"
            shape="default"
            icon={<SyncOutlined />}
            onClick={handleClear}
          >
            Clear
          </Button>
          <Button
            className="ml-4"
            size='large'
            type="primary"
            onClick={handleSubmit}
            disabled={submitDisabled}
          >
            Submit
          </Button>
        </div>
      </div>
    </Space>
  </div>
</div>

          <br />

          <div className="bg-white rounded-lg shadow-md p-6 flex flex-col relative">
            <div className="flex justify-between items-center mb-4">
              <Space direction="vertical" size={12} style={{ width: '100%' }}>
                <span className="text-lg font-bold">IVRHIT Report</span>
              </Space>
              <Button
                className={dataAvailable && "primary-outline"}
                type="primary"
                // style={{
                //   backgroundColor: dataAvailable ? '#ffffff' : '#DDDDDD',
                //   borderColor: dataAvailable ? '#FF7F07' : '#D9D9D9',
                //   color: dataAvailable ? '#FF7F07' : '#888888', 
                //   fontSize: 15,
                //   borderWidth: 2,
                //   borderRadius: 5,
                //   padding: '5px 10px',
                //   width: 120, 
                //   height: 40 
                // }}
                icon={<DownloadOutlined />}
                onClick={handleFunctionExport}
                disabled={!dataAvailable}
              >
                Export
              </Button>


            </div>

            {Failed === null ? (
            <div style={{ textAlign: 'center', padding: '20px', color: '#666' }}>
              <img style={{ margin: "auto" }} src={Nodata} alt="No Data"/>
            </div>
          ) : (
            <SimpleTable data={dataList} onPageChange={setDataList} dateRange={dateRange} />
          )}

          </div>
        </>
      )}

      {showNonVoiceReport && <NonVoice />}
      {transactionReport && <Transaction />}
      {voiceSummaryReport && <VoiceSummaryReport />}
      {voiceTransactionReport && <TransactionReport />}
    </>
  );
}

export default ReportList;
