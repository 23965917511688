import Icon from "@ant-design/icons";

const icon = () => (
  

<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="0.420898" width="24" height="24" rx="5" fill="#F8F8F9"/>
<path d="M6 18.4209L18 6.4209M6 6.4209L18 18.4209" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>




  


);

const Icon_Delete_Answer = (props) => <Icon component={icon} {...props} />;

export default Icon_Delete_Answer;

