import React, { useState, useEffect } from 'react';
import { DatePicker, Space, Button } from 'antd';
import { ReloadOutlined, SyncOutlined , DownloadOutlined } from '@ant-design/icons';
import { getVoiceSummaryReporst , VoiceSummaryExport } from '../service/reportService';
import VoiceSummaryReportTable from './TableVoiceSummaryReport';
import { Select } from 'antd';
import nodata from "../assets/img/NoData.png";

const { Option } = Select;

const { RangePicker } = DatePicker;

const VoiceSummaryReport = () => {
  const [dateRange, setDateRange] = useState([]);
  const [activeMenu, setActiveMenu] = useState('last-7-days');
  const [dataAvailable, setDataAvailable] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [showNonVoiceReport, setShowNonVoiceReport] = useState(true);
  const [Failed, setFailed] = useState([]);
  

  useEffect(() => {
    if (dateRange.length > 0) {
      console.log('Selected date range:', dateRange);
    }
  }, [dateRange]);


  const handleDateRangeChange = (dates) => {
    setDateRange(dates || []);
  };

  const handleClear = () => {
    setDateRange([]);
    setDataList([]);
    setFailed([]);
  };

  const handleSubmit = async () => {
    if (dateRange.length > 0) {
      const start = dateRange[0].format('YYYY-MM-DD');
      const end = dateRange[1].format('YYYY-MM-DD');
      const page = 1;
      const pagesize = 10;
      try {
        const responseData = await getVoiceSummaryReporst(start, end, page, pagesize);
        if (responseData && responseData.items && responseData.items.length > 0) {
          console.log('Data fetched successfully:', responseData);
          setDataAvailable(true);
          setDataList(responseData);
          setFailed([]); // Clear failed message if data fetch is successful
        } else {
          console.log("No data found");
          setDataAvailable(false);
          setDataList([]);
          setFailed("No data found"); // Set failed message
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setDataAvailable(false);
        setDataList([]);
        setFailed("Failed to fetch data"); // Set failed message
      }
    } else {
      console.error('Please select date range.');
      setDataAvailable(false);
      setDataList([]);
      setFailed(null);
    }
  };
  

  const handleFunctionExport = () => {
    if (dateRange && dateRange.length > 1) {
      const start = dateRange[0]?.format('YYYY-MM-DD');
      const end = dateRange[1]?.format('YYYY-MM-DD');
      if (start && end) {
        VoiceSummaryExport(start, end);
      }
    }
  };

  return (
    <>
  <div className="bg-white rounded-lg shadow-md p-6 flex flex-col">
  <div className="flex justify-between items-center mb-4">
    <Space direction="vertical" size={12} style={{ width: '100%' }}>
      <span>Date</span>
      <div className="flex items-center">
        <RangePicker
          value={dateRange}
          onChange={handleDateRangeChange}
          //className="rounded"
          style={{ width: '50%' }}
        />
        <div className="flex ml-4">
          <Button
            size='large'
            type="default"
            shape="default"
            icon={<SyncOutlined />}
            onClick={handleClear}
          >
            Clear
          </Button>
          <Button
            className='ml-4'
            type="primary"
            size='large'
            onClick={handleSubmit}
            disabled={dateRange.length === 0}
          >
            Submit
          </Button>
        </div>
      </div>
    </Space>
  </div>
</div>

      <br />
      <div className="bg-white rounded-lg shadow-md p-6 flex flex-col relative">
        <div className="flex justify-between items-center mb-4">
          <Space direction="vertical" size={12} style={{ width: '100%' }}>
            <span className="text-lg font-bold">Voice Summary Report</span>
          </Space>
          <Button
          className={dataAvailable && "primary-outline"}
          type="primary"
          icon={<DownloadOutlined />}
          onClick={handleFunctionExport}
          disabled={!dataAvailable}
        >
          Export
        </Button>
        </div>
        {Failed === null ? (
            <div style={{ textAlign: 'center', padding: '20px', color: '#666' }}>
              <img style={{ margin: "auto"}} src={nodata}></img>
            </div>
          ) : (
            <VoiceSummaryReportTable
              data={dataList}
              onPageChange={setDataList}
              dateRange={dateRange}
              getVoiceSummaryReporst={getVoiceSummaryReporst} // Add this line
            />

        )}
      </div>
      <br />
    </>
  );
}

export default VoiceSummaryReport;
