import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Row, Col, Layout , Modal ,Flex} from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import Logo from "../assets/img/logo.png";
import AuthService from "../service/authService";
import Swal from "sweetalert2";
import Background from "../assets/img/background.jpg";
import Timeup from "../assets/img/Timeup.png";
import Successfully from "../assets/img/successfully.png";
import IconSuccessDialog from "../assets/icon/icon_success_dialog";
import IconFailedDialog from "../assets/icon/icon_failed_dialog";

const authService = new AuthService();
const { Footer } = Layout;

const ChangePasswordEmail = () => {
  const history = useNavigate();
  const [rePassword, setRePassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const [uperLow, setUperLow] = useState(false);
  const [checkNum, setCheckNum] = useState(false);
  const [passThai, setPassThai] = useState(false);
  const [englishOnly, setEnglishOnly] = useState(false); 
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [tokenValid, setTokenValid] = useState(true); 
  const [showSuccessImage, setShowSuccessImage] = useState(false);
  const [isUsed, setIsUsed] = useState();
  const [email, setEmail] = useState("");
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);

  useEffect(() => {
    const url = window.location.href;
    const tokenMatch = url.match(/[?&]token=([^&]+)/);
    console.log("showSuccessImage" , showSuccessImage)
    if (tokenMatch) {
      const token = tokenMatch[1];
      validateToken(token);
    } else {
      console.log("Token not found in URL");
    }
  }, []);

  const validateToken = (token) => {
    authService.userResetPasswordToken(token)
      .then((response) => {
        console.log("response", response.data);
        var isUsed = response.data.isUsed;
        var email = response.data.email;
        setEmail(email);
        console.log("isUsed", isUsed);
        console.log("email", email);
        console.log("tokenValid", tokenValid);
      })
      .catch((error) => {
        console.log("Error:", error.data.errorCode);
        if (error.data.errorCode === "err006") {
          console.log("show successfully");
          setTokenValid(false);
          setShowSuccessImage(true); 
        } else if (error.data.errorCode === "err007") {
          console.log("Timeup");
          console.log("Error:", error);
          setTokenValid(false);
          console.log("tokenValid", tokenValid);
        } else if(error.data.errorCode === "err500"){
          console.log("Error 500");
          console.log("Error:", error);
        }
      });
  };

  const onChangePasswod = (e) => {
    if (e.target.value.length === 0) {
      setEnglishOnly(false);
    } else {
      setEnglishOnly(/^[a-zA-Z0-9!@#$%^&*()_+<>?]+$/.test(e.target.value));
    }
    
    setPassThai(e.target.value.length <= 7);
    setUperLow(/[a-z]/.test(e.target.value) && /[A-Z]/.test(e.target.value));
    setCheckNum(/[0-9]/.test(e.target.value));
    setRePassword(e.target.value);
  };
  

  const onChangeConfirm = (e) => {
    setConPassword(e.target.value);
  };

  const onFinish = (values , token) => {
    const url = window.location.href;
    const tokenMatch = url.match(/[?&]token=([^&]+)/);
    if (tokenMatch) {
      const token = tokenMatch[1];
    console.log("changePasswordEmail", token);
   
    if (values.password !== values.newPassword) {
      Swal.fire({
        icon: "error",
        title: "Password mismatch",
        text: "New Password and Confirm Password do not match.",
      });
      return; 
    }
    authService.changePasswordEmail(values, token).then((response) => {
      if (response.status === 204) {
        setIsModalSuccessOpen(true);
        localStorage.setItem("accessToken", JSON.stringify(""));
        localStorage.setItem("refreshToken", JSON.stringify(""));
      } else {
        setIsModalFailedOpen(true);
      }
    });
   }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const closeModal = () => {
    setIsModalSuccessOpen(false);
    setIsModalFailedOpen(false);
  };

  useEffect(() => {
    let timeoutId;
    if (isModalSuccessOpen) {
        timeoutId = setTimeout(closeModal, 800);
    }else if(isModalFailedOpen){
        timeoutId = setTimeout(closeModal, 800);
    }
    return () => clearTimeout(timeoutId);
}, [isModalSuccessOpen]);

  return (
    <>
    {/* Modal After Save - Success */}
<Modal
    title=""
    open={isModalSuccessOpen} 
    footer={null}
    closable={false}
    destroyOnClose={true}
    afterClose={() => {
        history(
            {
                pathname: `/login`,
            },
            { state: { updateDate: new Date() } }
        );
    }}
    centered
>
    <div className="flex flex-col justify-center items-center">
        <IconSuccessDialog />
        <p
            style={{
                fontWeight: 600,
                fontSize: 22,
                marginTop: 12,
            }}
        >
            Success
        </p>
        <p
            style={{
                fontWeight: 500,
                fontSize: 16,
                marginTop: 8,
            }}
        >
            Successfully saved data.
        </p>
    </div>
</Modal>

{/* Modal After Save - Failed */}
<Modal
    title=""
    open={isModalFailedOpen} 
    footer={null}
    closable={false}
    destroyOnClose={true}
    afterClose={() => {
        history(
            {
                pathname: `/login`,
            },
            { state: { updateDate: new Date() } }
        );
    }}
    centered
      >
        <div className="flex flex-col justify-center items-center">
          <IconFailedDialog />
          <p
            style={{
              fontWeight: 600,
              fontSize: 22,
              marginTop: 12,
            }}
          >
            Failed
          </p>
          <p
            style={{
              fontWeight: 500,
              fontSize: 16,
              marginTop: 8,
            }}
          >
            Something went wrong. Please try again.
          </p>
          <Button
            className="w-[150px] h-[38px] mt-[20px]"
            style={{
              marginRight: 10,
              backgroundColor: "#FF7F07",
              color: "#ffffff",
              border: "2px solid #FF7F07",
            }}
            onClick={() => {
              setIsModalFailedOpen(false);
              // navigate("/survey-form-list");
            }}
            // type="primary"
          >
            Try Again
          </Button>
        </div>
</Modal>
      <div>
        <img
          className="fixed"
          style={{ zIndex: "0", width: "100%", height: "100%" }}
          src={Background}
        />
      </div>
      {tokenValid ? (
        <Layout>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <Row>
              <Col sm={24} md={24} lg={24}>
                <div
                  className="ml-2 mt-5"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <img style={{ margin: "auto" }} src={Logo} alt="Logo" />
                </div>
              </Col>
              <Col sm={24} md={24} lg={24}>
                <br />
                <div
                  style={{
                    width: 600,
                    boxShadow: "0px 0px 15px #F3C9A3",
                    marginBottom: "2rem",
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    padding: "70px 95px",
                    boxSizing: "border-box",
                    marginLeft: "400px" 
                  }}
                 >
                  
                  <Form
                    name="login-form"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className="mt-5 mx-5"
                  >
                    <div style={{ textAlign: "left", marginBottom: 20 }}>
                      <label style={{ fontSize: 28, fontWeight: 600 }}>
                        {tokenValid ? "New password" : "Time's up"}
                      </label>
                    </div>

                    <div style={{ textAlign: "left", marginBottom: 20 }}>
                      <label
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#667085",
                        }}
                      >
                        Please create a new password to access the account.<br />
                        Your Email:{" "}
                        {email}
                      </label>
                    </div>

                    <Form.Item
                      name="password"
                      rules={[
                        { required: true, message: "Please input your new password!" },
                      ]}
                    >
                      <Input.Password
                        placeholder="Enter New Password"
                        onChange={onChangePasswod}
                      />
                    </Form.Item>

                    <Form.Item
                      name="newPassword"
                      rules={[
                        { required: true, message: "Please input your confirm password!" },
                      ]}
                    >
                      <Input.Password
                        placeholder="Enter Confirm password"
                        onChange={onChangeConfirm}
                      />
                    </Form.Item>
                    <div className=" mb-0">
                      <Row>
                        <Col sm={24} md={24} lg={24}>
                          <div style={{ textAlign: "left", marginBottom: 0 }}>
                            <label
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: `${englishOnly ? "#12B76A" : "#667085"}`,
                              }}
                            >
                              <CheckCircleOutlined /> English characters only.
                            </label>
                          </div>
                        </Col>
                        <Col sm={24} md={24} lg={24}>
                          <div style={{ textAlign: "left", marginBottom: 0 }}>
                            <label
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: `${rePassword?.length > 7 ? "#12B76A" : "#667085"}`,
                              }}
                            >
                              <CheckCircleOutlined /> Your password must be at least 8 -
                              20 characters.
                            </label>
                          </div>
                        </Col>
                        <Col sm={24} md={24} lg={24}>
                          <div style={{ textAlign: "left", marginBottom: 0 }}>
                            <label
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: `${uperLow ? "#12B76A" : "#667085"}`,
                              }}
                            >
                              <CheckCircleOutlined /> It consists of uppercase and
                              lowercase.
                            </label>
                          </div>
                        </Col>
                        <Col sm={24} md={24} lg={24}>
                          <div style={{ textAlign: "left", marginBottom: 0 }}>
                            <label
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: `${checkNum ? "#12B76A" : "#667085"}`,
                              }}
                            >
                              <CheckCircleOutlined /> Contains at least 1 number. [0-9]
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <Form.Item>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          disabled={((rePassword === conPassword) && englishOnly && rePassword?.length > 7 && uperLow && checkNum) ? false : true}
                          type="primary"
                          htmlType="submit"
                          style={{
                            width: "95%",
                            borderRadius: 10.5,
                            border: "1px solid",
                            marginTop: 20,
                            backgroundColor: "#FF7F07",
                            backgroundColor: ((rePassword === conPassword) && passThai && rePassword?.length > 7 && uperLow && checkNum) ? "#FF7F07" : "F5F5F5",
                          }}
                        >
                          Change Password
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </div>
                <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                  </div>
              </Col>
            </Row>
            <Footer
                    style={{
                      width:"100%",
                      backgroundColor: "transparent",
                      textAlign: "center",
                      bottom:"0",
                      position: "fixed",
                      paddingBottom :"30px"
                    }}
                  >
                    <Flex gap="small" justify={"center"} align={"center"}>
                      <span>
                        Web Survey Copyright © 
                      </span>
                      <span style={{ color: "#FF7F07" }}>2024 G-Able Public Company Limited. All rights reserved.</span>
                    </Flex>
                  </Footer>
          </div>
        </Layout>
      ) : (
        <Layout>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <Row>
              <Col sm={24} md={24} lg={24}>
                <div
                  className="ml-2 mt-5"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <img style={{ margin: "auto" }} src={Logo} alt="Logo" />
                </div>
              </Col>
              <Col sm={24} md={24} lg={24}>
                <br />
                <div
                  style={{
                    width: 900,
                    height: 500,
                    boxShadow: "0px 0px 15px #F3C9A3",
                    borderRadius: 10,
                    margin: "auto",
                    backgroundColor: "#fff",
                  }}
                >
                  {showSuccessImage ? (
                    <div style={{ 
                      width: "100%", 
                      height: "auto", 
                      background: "linear-gradient(to bottom, #ffffff 0%, #ffffff 100%)", 
                      borderRadius: "30px",
                      overflow: "hidden",
                      borderTopLeftRadius: "30px", 
                      borderTopRightRadius: "30px", 
                  }}>
                      <div style={{ 
                          width: "100%", 
                          height: "auto", 
                          background: "linear-gradient(to bottom, #ffffff 0%, #ffffff 100%)",
                          display: "flex",
                          flexDirection: "column", 
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          padding: "20px"
                      }}>
                          <img src={Successfully} alt="Successfully" style={{ width: '350px', height: 'auto' }} />
                          <h1 style={{ fontSize: '2rem', marginBottom: '20px' }}>You have successfully reset your password</h1>
                          <span style={{ fontSize: '16px' }}>"The password reset link has expired. Please contact the administrator for further assistance."</span>
                      </div>
                  </div>                  
                ) : (
                    <div style={{ 
                        width: "100%", 
                        height: "auto", 
                        background: "linear-gradient(to bottom, #ffffff 0%, #ffffff 100%)", 
                        borderRadius: 30, 
                        overflow: "hidden" 
                    }}>
                        <div style={{ 
                            width: "100%", 
                            height: "auto", 
                            background: "linear-gradient(to bottom, #ffffff 0%, #ffffff 100%)",
                            display: "flex",
                            flexDirection: "column", 
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            padding: "20px", 
                            borderRadius: 30 
                        }}>
                            <img src={Timeup} alt="Time's Up" style={{ width: '350px', height: 'auto' }} />
                            <h1 style={{ fontSize: '2rem', marginBottom: '20px' }}>Time's up</h1>
                            <span style={{ fontSize: '16px' }}>"The password reset link has expired. Please contact the administrator again to request a new password reset."</span>
                        </div>
                    </div>
                  )}
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                  </div>
                  
                </div>
              </Col>
            </Row>
            <Footer
                    style={{
                      width:"100%",
                      backgroundColor: "transparent",
                      textAlign: "center",
                      bottom:"0",
                      position: "fixed",
                      paddingBottom :"30px"
                    }}
                  >
                    <Flex gap="small" justify={"center"} align={"center"}>
                      <span>
                        Web Survey Copyright © 
                      </span>
                      <span style={{ color: "#FF7F07" }}>2024 G-Able Public Company Limited. All rights reserved.</span>
                    </Flex>
                  </Footer>
          </div>
          
        </Layout>
      )}
    </>
  );
};

export default ChangePasswordEmail;
