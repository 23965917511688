/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Flex,
  Layout,
} from "antd";
// import { Checkbox } from '@chakra-ui/react'
import {
  CheckCircleOutlined,

} from "@ant-design/icons";
import Logo from "../assets/img/logo.png";

import AuthService from "../service/authService";
import UserListService from "../service/userListService";
import Swal from "sweetalert2";

import Background from "../assets/img/background.jpg";

const authService = new AuthService();

const { Header, Sider, Content, Footer } = Layout;

const mockupData = [
  {
    username: "user1",
    password: "password1",
  },
  {
    username: "admin",
    password: "admin1234",
  },
];

const ChangePassword = () => {
  const userListService = new UserListService();
  const history = useNavigate();

  const [form] = Form.useForm();
  const location = useLocation();

  const [rePassword, setRePassword] = useState();
  const [conPassword, setConPassword] = useState();
  
  const [uperLow, setUperLow] = useState(false);
  const [checkNum, setCheckNum] = useState(false);
  const [passThai, setPassThai] = useState(false);
  // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [userInfo, setUserInfo] = useState();
  

  let userDetailId = location.pathname.replace("/change-password/", "");
  let oldPassword = location.search.replace("?", "");
  console.log("userDetailId", userDetailId);
  console.log("userDetailId", oldPassword);

  function containsThai(str) {
    return /[ก-ฮ]/.test(str);
  }

  function containsUppercase(str) {
    return /[A-Z]/.test(str);
  }

  function containsLowercase(str) {
    return /[a-z]/.test(str);
  }

  function containsNumberic(num) {
    return /[0-9]/.test(num);
  }

  function containsSpecialChars(str) {
    return /[-+_!@#$%^&*.,?]/.test(str);
  }

  function getUserInfo(){
    let userId = localStorage.getItem("userId");
    userListService
      .getUserDetail(userId)
      .then((response) => {
        // console.log("loginValue", response);
        setUserInfo(response.data);

      })
      .catch(async (err) => {
        console.log(err);
      });
  }

  useEffect(() => {
      getUserInfo();
    }, []);


  const onFinish = (values) => {
    console.log("Received values:", values);
    console.log(
      "Received values Uppercase: ",
      containsUppercase(values.newPassword)
    );
    // navigate("/");
    authService
      .changePassword(values, userDetailId, oldPassword)
      .then((response) => {
        console.log("loginValue", response);

        if (response.status === 204) {
          // onOk();
          Swal.fire({
            icon: "success",
            title: "Success !",
            text: "Successfully saved data.",
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.setItem("accessToken", JSON.stringify(""));
          localStorage.setItem("refreshToken", JSON.stringify(""));
          history({
            pathname: "/",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed !",
            text: "Duplicated Data.",
          });
        }
      });
  };
  const onChangePasswod = (e) => {
    console.log("password", e.target.value);
    console.log("password1", containsThai(e.target.value));
    console.log("password", containsUppercase(e.target.value));
    if(containsUppercase(e.target.value) &&containsLowercase(e.target.value)){
      setUperLow(true)
    }else{
      setUperLow(false)
    }
    if(containsUppercase(e.target.value) || containsLowercase(e.target.value)){
      
      if(containsThai(e.target.value)){
        setPassThai(false);
      }else{
        setPassThai(true);
      }
    }else{
      setPassThai(false);
    }

    // setPassThai(containsThai(e.target.value));
    setCheckNum(containsNumberic(e.target.value));
    setRePassword(e.target.value);

    
  }
  const onChangeConfirm = (e) => {
  
    setConPassword(e.target.value);

    
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
    <div>
        <img
          className="fixed"
          style={{ zIndex: "0", width: "100%", height: "100%" }}
          src={Background}
        />
      </div>
    <Layout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <Row>
          <Col sm={24} md={24} lg={24}>
          <div
          className="ml-2 mt-5"
            style={{
              textAlign: "center",
              //height:100,
              // margin: "20px 0px ",

              fontSize: 12,
              color: "#0C0C0C",
              //backgroundColor:'#313A46'
            }}
          >
            <img style={{margin:"auto"}} src={Logo}></img>
            {/* <h2 style={{marginTop:0,paddingTop:10,color:"#fff" }}>Sign in to your account</h2> */}
          </div>
          </Col>
          <Col sm={24} md={24} lg={24}>
          <div
          style={{
            width: 400,
            height: 420,
            boxShadow: "0px 0px 15px #F3C9A3",
            // border: "1px solid #313A46",
            borderRadius: 10,
            margin:"auto",
            backgroundColor:"#fff"
            //   padding: 16,
          }}
        >
         

          <Form
            name="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="mt-5 mx-5"
            //style={{ margin: "0px 25px 15px 25px" }}
          >
            <div style={{ textAlign: "left", marginBottom: 20 }}>
              <label style={{ fontSize: 28, fontWeight: 600 }}>
                New password
              </label>
            </div>
            <div style={{ textAlign: "left", marginBottom: 20 }}>
              <label
                style={{ fontSize: 14, fontWeight: 400, color: "#667085" }}
              >
                Please create new password to access account. Your Email:
                {userInfo !== undefined ? userInfo.email : ""}
              </label>
            </div>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input.Password placeholder="Enter New Password" onChange={onChangePasswod} />
            </Form.Item>

            <Form.Item
              name="newPassword"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password placeholder="Enter Confirm password" onChange={onChangeConfirm}/>
            </Form.Item>
            <div className=" mb-0">
             
              <Row>
                <Col sm={24} md={24} lg={24}>
                  <div style={{ textAlign: "left", marginBottom: 0 }}>
                    <label
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        // color: "#667085",
                        color: `${
                          passThai ? "#12B76A":  "#667085"
                        }`,
                      }}
                    >
                      <CheckCircleOutlined /> English characters only.
                    </label>
                  </div>
                </Col>
                <Col sm={24} md={24} lg={24}>
                  <div style={{ textAlign: "left", marginBottom: 0 }}>
                    <label
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        // color: "#667085",
                        color: `${
                          rePassword?.length > 7 ? "#12B76A" : "#667085"
                        }`,
                      }}
                    >
                      <CheckCircleOutlined /> Your password must be at least 8 -
                      20 characters.
                    </label>
                  </div>
                </Col>
                <Col sm={24} md={24} lg={24}>
                  <div style={{ textAlign: "left", marginBottom: 0 }}>
                    <label
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        // color: "#667085",
                        color: `${
                          uperLow ? "#12B76A" : "#667085"
                        }`,
                      }}
                    >
                      <CheckCircleOutlined /> It consists of uppercase and
                      lowercase
                    </label>
                  </div>
                </Col>
                <Col sm={24} md={24} lg={24}>
                  <div style={{ textAlign: "left", marginBottom: 0 }}>
                    <label
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        // color: "#667085",
                        color: `${
                          checkNum ? "#12B76A" : "#667085"
                        }`,
                        
                      }}
                    >
                      <CheckCircleOutlined /> Contains at least 1 number [0-9]
                    </label>
                  </div>
                </Col>
              </Row>

              {/* <div
              className="underline cursor-pointer hover:opacity-80"
              onClick={() =>
                props.setCurrentPageAction({
                  name: "Forgot Password?",
                  mail: "",
                })
              }
            >
              Forgot Password?
            </div> */}
            </div>

            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                disabled={((rePassword === conPassword) && passThai && rePassword?.length > 7 && uperLow && checkNum)?false: true}
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "95%",
                    borderRadius: 10.5,
                    border: "1px solid",
                    marginTop: 20,
                    backgroundColor: "#FF7F07",
                  }}
                >
                  Change Password
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
          </Col>
        </Row>
         
        
      </div>
      <Footer
            style={{
              backgroundColor: "transparent",
              textAlign: "center",
              zIndex: "100",
              bottom:"0",
              position: "fixed",
              paddingBottom :"40px"
            }}
          >
            <Flex gap="small" justify={"center"} align={"center"}>
              <span>
                Web Survey Copyright © 
              </span>
              <span style={{ color: "#FF7F07" }}>2024 G-Able Public Company Limited. All rights reserved.</span>
              {/* <span>|</span>
            <span style={{ color: "#FF7F07" }}>Terms And Conditions </span> */}
            </Flex>
          </Footer>
    </Layout>
    </>
    
  );
};

export default ChangePassword;
