import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NoData from "../assets/img/NoData.png";
import reset from "../assets/img/reset.png";
import { Link } from "react-router-dom";
import ExclamationMark from "../assets/img/ExclamationMark.png"; 
import IconConfirmSubmit from "../assets/icon/icon_confirm_submit";
import IconSuccessDialog from "../assets/icon/icon_success_dialog";
import IconFailedDialog from "../assets/icon/icon_failed_dialog";
import IconExclamationmarkDialog from "../assets/icon/icon_exclamationmark_dialog";

import {
  Table,
  Button,
  Form,
  Input,
  Flex,
  Row,
  Col,
  Select,
  message,
  Switch,
  Tooltip,
  ConfigProvider,
  notification,
  Tag,
  Modal,
  Pagination,
} from "antd";

import UserListService from "../service/userListService";
import AuthService from "../service/authService";

import { PlusOutlined, SearchOutlined, SyncOutlined,LeftOutlined,RightOutlined } from "@ant-design/icons";
import IconEdit from "../assets/icon/icon_edit";
import Swal from "sweetalert2";
import save from "../assets/img/save.png";


const userListService = new UserListService();
const authService = new AuthService();



const UserList = () => {
  const navigate = useNavigate();
  const history = useNavigate();
  const pageSize = 10;
  let currentPage = 1;
  let currentUser = localStorage.getItem("userId");
  const [isModalDiscardChangeOpen, setIsModalDiscardChangeOpen] = useState(false);
  const [isModalChangePasswordOpen, setIsModalChangePasswordOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const columns = [
    {
      title: "No.",
      width: '5%',
      //render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
      render: (_, __, index) => (pageValue.page - 1) * pageValue.pageSize + index + 1,
    },
    {
      title: "Name - Surname",
      dataIndex: "fullName",
      width: '35%',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
    },
    // {
    //   title: "Mobile no.",
    //   width: 150,
    //   dataIndex: "tel",
    // },
    {
      title: "Email",
      width: '20%',
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Role",
      width: '10%',
      dataIndex: "roleName",
      sorter: (a, b) => a.roleName.localeCompare(b.roleName),
      tag: <span style={{ color: 'green' }}>Another Tag</span>,
      render: (text, record) => {
          let colorStr = "";
          let roleNameStr = "";
          if(record.roleName == "Editer"){
            colorStr ="warning";
            roleNameStr = "Editor"
          } else if(record.roleName == "Observer"){
            colorStr ="default";
            roleNameStr = record.roleName
          } else if(record.roleName == "Moderator"){
            colorStr ="green";
            roleNameStr = record.roleName
          } else if(record.roleName == "Administrator"){
            colorStr ="blue";
            roleNameStr = record.roleName
          }
          return <Tag color={colorStr} ><b>{roleNameStr}</b></Tag>
        }
    },
    {
      title: "Lock",
      width: '10%',
      dataIndex: "isLocked",
      render: (text, record) =>
        record.isLocked ? (
          <Tag color="#ff4d4f" ><b>Lock</b></Tag>
        ) : (
          <div>-</div>
        )
    },
    {
      title: "Status",
      width: '10%',
      dataIndex: "isActivated",
      render: (e, record) => (
        <Tooltip placement="bottom" title={e ? "Active" : "Inactive"}>
          <Switch
            style={{ backgroundColor: e === true ? "#1890FF" : "#d9d9d9" }}
            checked={e}
            onChange={() => {
              onChangeSwitch(record.id, e);
            }}
            disabled={record.id === currentUser}
          />
        </Tooltip>
      ),
    },
    {
      title: "Action",
      width: '10%',
      render: (text, record) => (
        <span style={{ whiteSpace: "nowrap" }}>
        {/* Reset Password Button */}
        <Tooltip placement="bottom" title="Reset Password">
        <button
            style={{
              marginRight: -4,
              opacity: record.isActivated && record.id !== currentUser ? 1 : 0.5,
              cursor: record.isActivated && record.id !== currentUser ? "pointer" : "not-allowed",
              border: "none", // Remove button border
              background: "none", // Remove button background
              top: 10,
              pointerEvents: record.isActivated && record.id !== currentUser ? "auto" : "none", 
            }}
            onClick={() => {
              if (record.isActivated && record.id !== currentUser) {
                setIsModalChangePasswordOpen(true);
                setConfirmData({ id: record.id });
              } else {
                message.error("User is inactive. Activate the user to reset password.");
              }
            }}
            disabled={record.id === currentUser || !record.isActivated}
          >
  <img
    src={reset}
    alt="Reset Icon"
    style={{
      width: "40.5px",
      height: "auto",
      marginBottom: "-3px",
      filter: record.isActivated && record.id !== currentUser ? "none" : "grayscale(100%)", 
    }}
  />
</button>

</Tooltip>
<Tooltip placement="bottom" title="View / Edit">
  <IconEdit
    onClick={() => {
      history({
        pathname: `/user-detail/${record.id}`,
      });
    }}
  />
</Tooltip>

      </span>
      ),
    },
  ];
  const { Option } = Select;
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [Keywords, setKeywords] = useState("");
  const [confirmData, setConfirmData] = useState(null); 
  const handleSearchChange = (event) => {
    setKeywords(event.target.value);
  };

  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageValue, setPageValue] = useState({
    page: 1,
    pageSize: 10,
  });
  const [searchValue, setSearchValue] = useState({
    keywords: "",
    isActivated: "",
  });

  const getUserList = (value, page) => {
    userListService
      .getUserList(value, page)
      .then((response) => {
        console.log('Fetched data:', response.data.items);  
        setDataList(response.data.items);
        setPageValue({
          page: response.data.pagination.page,
          pageSize: response.data.pagination.pageSize,
          total: response.data.pagination.totalCount,
        });
      })
      .catch(async (err) => {
        console.log("err", err);
        if (err.status === 401) {
          authService.refreshToken();
          getUserList(value, page);
          console.log("token time");
        }

        history({
          pathname: `/login`,
        });
      });
  };
  const onChangeSwitch = (id, status) => {
    setIsModalDiscardChangeOpen(true);
    setConfirmData({ id, status }); 
  };
  

  
  const handleConfirm = () => {
    if (!confirmData) return; 
    const { id, status } = confirmData;
    submit(id, status);
    setIsModalDiscardChangeOpen(false); 
  };

  const handleConfirmPassword = () => {
    if (!confirmData) return; 
    const { id } = confirmData;
    onChangeLock(id);
    setIsModalChangePasswordOpen(false); // ปิด Modal
  };
  
  
  function submit(id, status) {
    userListService
      .activatedUser(id, status)
      .then((response) => {
        console.log("response", response);
        if (response.status === 204) {
          setIsModalSuccessOpen(true); 
        } else {
          setIsModalFailedOpen(true);
        }
        getUserList(searchValue, pageValue);
      })
      .catch(async (err) => {
        console.log("err", err);
        if (err?.response)
          if (err?.response?.status === 401) {
            if (await authService.refreshToken()) {
              // return getRolePermission(access_token);
            }
          }
      });
  }


  const onChangeLock = (id) => {
    console.log(id)
    userListService
      .lockUser(id)
      .then((response) => {
        console.log("response0", response);
        if (response.status === 204) {
          setIsModalSuccessOpen(true); 
          getUserList(searchValue, pageValue);
        } else {
          setIsModalFailedOpen(true);
        }
      })
      .catch((err) => {
        console.log("response1", err);
      })
      .finally((data) => {
        console.log("response2", data);
      });
  };

  const pagination = {
    //size: "small",
    current: parseInt(pageValue.page),
    itemSize: "small",
    position: ["bottomCenter"],
    total: parseInt(pageValue.total),
    showQuickJumper: false,
    showSizeChanger: true,
  };

  const onPageChange = async (pagination, filters, sorter, currentPage) => {
    console.log(currentPage)
    const pageValues = {
      page: currentPage,
      pageSize: pagination.pageSize,
      sortBy: sorter && sorter.order ? sorter.columnKey : "createDate",
      sortDirection:
        sorter && sorter.order
          ? sorter.order.substring(0, 3).toUpperCase() === "ASC"
            ? "asc"
            : "desc"
          : "asc",
      total: pageValue.total,
    };
    setPageValue(pageValues);

    getUserList(searchValue, pageValues);
  };


  const onSearch = (e) => {
    setSearchValue({
      keywords: e.keywords,
      isActivated: e.isActivated,
    });
    setPageValue({ ...pageValue, page: 1 }); 
    getUserList(e, { ...pageValue, page: 1 });
  };
  
  function capitalize(str){
    if(str == undefined){
      return;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleSortBy = async (pagination, filters, sorter) => {
    let sortBy = capitalize(sorter.field);
    if("createdDateDisplay" === sorter.field){
      sortBy = "CreateDate";
    }
    const pageValues = {
      keywords: Keywords,
      page: pageValue.page,
      pageSize: pageValue.pageSize,
      sortBy: sortBy,
      sortDirection:
        sorter && sorter.order
          ? sorter.order.substring(0, 3).toUpperCase() === "ASC"
            ? "asc"
            : "desc"
          : "asc",
      total: pageValue.total,
    };
    setPageValue(pageValues);
    getUserList(searchValue, pageValues);
    //fetchData(pageValues);
  }

  const handlePageChange = async (page, pageSize) => {

    console.log("handlePageChange >>> page",page)
    console.log("handlePageChange >>> pageSize",pageSize)
    const pageValues = {
      keywords: Keywords,
      page: page,
      pageSize: pageSize,
      sortBy: pageValue.sortBy,
      sortDirection:pageValue.sortDirection,
      total: pageValue.total,
    };
    setPageValue(pageValues);
    console.log("handlePageChange >>> pageValue",pageValues)
    getUserList(searchValue, pageValues);
    //fetchData(pageValues);
  };

  const itemRender = (_, type, originalElement) => {
    const { page, total, pageSize } = pageValue;
    const totalPages = Math.ceil(pageValue?.total / pageSize);
    if (type === "prev") {
      if (page === 1) {
        return (
          <a style={{ pointerEvents: "none" }}>
            <LeftOutlined
              style={{
                color: "#d9d9d9",
              }}
            />
          </a>
        );
      }
      return (
        <a>
          <LeftOutlined
            style={{
              color: "#FF7F07",
            }}
          />
        </a>
      );
    }
    if (type === "next") {
      if (page === totalPages) {
        return (
          <a style={{ pointerEvents: "none" }}>
            <RightOutlined
              style={{
                // fontSize: "",
                color: "#d9d9d9",
              }}
            />
          </a>
        );
      }
      return (
        <a>
          <RightOutlined
            style={{
              // fontSize: "",
              color: "#FF7F07",
            }}
          />
        </a>
      );
    }
    return originalElement;
  };

  const handlePageSizeChange = (current, pageSize) => {
    const pageValues = {
      keywords: Keywords,
      page: 1,
      pageSize: pageSize,
      sortBy: pageValue.sortBy,
      sortDirection:pageValue.sortDirection,
      total: pageValue.total,
    };
    setPageValue(pageValues);
    //fetchData(pageValues);
    getUserList(searchValue, pageValues);
  };




  useEffect(() => {
    getUserList(searchValue, pageValue);
  }, []);

  const handleisModalDiscardChangeOpenCancel = () => {
    setIsModalDiscardChangeOpen(false);
  };

  const handleisModalChangePasswordOpenCancel= () => {
    setIsModalChangePasswordOpen(false);
  };
  
  const closeModal = () => {
    setIsModalSuccessOpen(false);
  };
     useEffect(() => {
      let timeoutId;
      if (isModalSuccessOpen) {
          timeoutId = setTimeout(closeModal, 800);
      }
      return () => clearTimeout(timeoutId);
  }, [isModalSuccessOpen]);


  return (
    <>


{/* Modal Confirm Reset Password*/}
<Modal title="" visible={isModalChangePasswordOpen} footer={null} closable={false} onCancel={handleisModalChangePasswordOpenCancel} centered>
  <div className="flex flex-col justify-center items-center">
  <IconExclamationmarkDialog />
    <p style={{ fontWeight: 600, fontSize: 22, marginTop: 12 }}>Confirm Reset Password</p>
    <p style={{ fontSize: 15, marginTop: -10 }}>Do you want to reset password ?</p>
    <div className="flex flex-row items-center justify-center mt-[20px]">
      <Button
        size="large"
        type="default"
        shape="default"
        onClick={handleisModalChangePasswordOpenCancel}
      >
        Cancel
      </Button>
      <Button
        className="ml-4"
        size="large"
        type="primary"
        shape="default"
        onClick={handleConfirmPassword} // Call handleConfirm function instead
      >
        Confirm
      </Button>
    </div>
  </div>
</Modal>

{/* Modal Confirm Status Change*/}
<Modal title="" open={isModalDiscardChangeOpen} footer={null} closable={false} centered>
  <div className="flex flex-col justify-center items-center">
    <IconConfirmSubmit />
    <p style={{ fontWeight: 600, fontSize: 22, marginTop: 12 }}>Confirm Status Change</p>
    <p style={{ fontSize: 15, marginTop: -10 }}>Do you want to change your status to "Inactive" ?</p>
    <div className="flex flex-row items-center justify-center mt-[20px]">
      <Button
        size="large"
        type="default"
        shape="default"
        onClick={handleisModalDiscardChangeOpenCancel}
      >
        Cancel
      </Button>
      <Button
        className="ml-4"
        size="large"
        type="primary"
        shape="default"
        onClick={handleConfirm} // Call handleConfirm function instead
      >
        Confirm
      </Button>
    </div>
  </div>
</Modal>


{/* Modal After Save - Success */}
<Modal
    title=""
    open={isModalSuccessOpen} 
    footer={null}
    closable={false}
    destroyOnClose={true}
    afterClose={() => {
        navigate(
            {
                pathname: `/user-list`,
            },
            { state: { updateDate: new Date() } }
        );
    }}
  centered
>
    <div className="flex flex-col justify-center items-center">
        <IconSuccessDialog />
        <p
            style={{
                fontWeight: 600,
                fontSize: 22,
                marginTop: 12,
            }}
        >
            Success
        </p>
        <p
            style={{
                fontWeight: 500,
                fontSize: 16,
                marginTop: 8,
            }}
        >
            Successfully saved data.
        </p>
    </div>
</Modal>

{/* Modal After Save - Failed */}
<Modal
    title=""
    open={isModalFailedOpen} 
    footer={null}
    closable={false}
    destroyOnClose={true}
    afterClose={() => {
        navigate(
            {
                pathname: `/user-list`,
            },
            { state: { updateDate: new Date() } }
        );
    }}
    centered
      >
        <div className="flex flex-col justify-center items-center">
          <IconFailedDialog />
          <p
            style={{
              fontWeight: 600,
              fontSize: 22,
              marginTop: 12,
            }}
          >
            Failed
          </p>
          <p
            style={{
              fontWeight: 500,
              fontSize: 16,
              marginTop: 8,
            }}
          >
            Something went wrong. Please try again.
          </p>
          <Button
            className="w-[150px] h-[38px] mt-[20px]"
            style={{
              marginRight: 10,
              backgroundColor: "#FF7F07",
              color: "#ffffff",
              border: "2px solid #FF7F07",
            }}
            onClick={() => {
              setIsModalFailedOpen(false);
              // navigate("/survey-form-list");
            }}
            // type="primary"
          >
            Try Again
          </Button>
        </div>
</Modal>
      
        {contextHolder}
<label style={{ fontSize: 24, fontWeight: 700 }}>User management</label>

<div className="bg-white rounded-lg pt-4 pl-5 pr-5 mb-3 mt-10">
        
          <Form form={form} onFinish={onSearch} layout="vertical">
            <div className="flex">
              <div className="flex-1 mr-4">
              <Form.Item label="Search" name="keywords" colon={false}>
                <Input className="input-custom rounded" placeholder="Search Name, Surname, Mobile No., Email..." allowClear  />
              </Form.Item>
              </div>
              <div className="flex-1 ">
              <Form.Item label="Status" name="isActivated" colon={false}>
                <Select
                  defaultValue="All"
                  allowClear
                  placeholder="Status"
                  showSearch
                  maxTagCount="responsive"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  style={{ width: '100%' }}
                >
                  <Option key={""} value={""}>All</Option>
                  <Option key={true} value={true}>Active</Option>
                  <Option key={false} value={false}>Inactive</Option>
                </Select>
              </Form.Item>
              </div>
              <div className="mt-12 ml-4">
              <Button shape="default" className="" icon={<SyncOutlined />} onClick={() => {
                  form.resetFields();
                  const searchValues = { keywords: "", isActivated: "" };
                  const pageValues = { page: 1, pageSize: 10, sortBy: "serviceDate", sortDirection: "desc" };
                  getUserList(searchValues, pageValues);
                }}>
                  Clear
              </Button>
              </div>
              <div className="mt-12 ml-4">
              <Button size="large" className="" shape="default" htmlType="submit" type="primary" icon={<SearchOutlined />} >
                Search
              </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="bg-white rounded-lg p-5 ">
          <div>
            <Row className="mb-6 mt-2">
              <Col span={20} style={{ marginRight: 0 }}>
                <label style={{fontSize: 20, fontWeight: 700 }}>User list</label>
              </Col>
              <Col span={4} style={{ marginRight: 0 }}>
                <Flex justify="flex-end" style={{ marginRight: 0 }}>
                  <Button
                    onClick={() => {
                      history({
                        pathname: `/user-detail`,
                      });
                    }}
                    type="primary"
                    shape="default"
                    htmlType="submit"
                    icon={<PlusOutlined />}
                  >
                    Add User
                  </Button>
                </Flex>
              </Col>
            </Row>
          </div>
          {dataList && dataList.length > 0 ? (
            <div>
            <Table
              style={{
                margin: "8px 0",
              }}
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={dataList}
              //pagination={pagination}
              pagination={false}
              loading={loading}
              onChange={(pagination, filters, sorter) => handleSortBy(pagination, filters, sorter, pagination.current)}
              scroll={{
                x: 1024,
              }}
            />
            <Row justify="space-between" align="middle" style={{ marginTop: 16 }}>
            <Col>
              <span
                style={{
                  color: "#667085",
                }}
              >
                <b>Total : {pagination?.total} items</b>
              </span>
            </Col>
            <Col>
              <Pagination
                style={{ pointerEvents: loading ? "none" : "auto" }}
                current={pageValue?.page}
                pageSize={pageValue?.pageSize}
                total={pagination?.total}
                onChange={handlePageChange}
                itemRender={itemRender}
              />
            </Col>
            <Col>
              <Select
                size="middle"
                value={pageValue.pageSize}
                onChange={(pageSize) =>
                  handlePageSizeChange(
                    pagination?.current,
                    pageSize
                  )
                }
                style={{
                  width: "130px",
                }}
              >
                <Option value={10}>10 / page</Option>
                <Option value={25}>25 / page</Option>
                <Option value={50}>50 / page</Option>
                <Option value={100}>100 / page</Option>
              </Select>
            </Col>
          </Row>
            </div>
          ) : (
            <div style={{ textAlign: 'center', padding: '20px', color: '#666' }}>
              <img style={{ margin: "auto" }} src={NoData} alt="No data" />
            </div>
          )}
        </div>
      
    </>
  );
};

export default UserList;
