import React, { useEffect, useState, useRef, useContext, useCallback, createRef } from "react";
  import {
    sortableHandle
  } from "react-sortable-hoc";

import IconDragSurvey from "../assets/icon/icon_drag_survey";
import IconRadioUnSelect from "../assets/icon/icon_radio_unselect";
import IconRadioSelected from "../assets/icon/icon_radio_selected";
import IconCheckboxUnSelect from "../assets/icon/icon_checkbox_unselect";
import IconCheckboxSelected from "../assets/icon/icon_checkbox_selected";
import IconTextareaUnSelect from "../assets/icon/icon_textarea_unselect";
import IconTextareaSelected from "../assets/icon/icon_textarea_selected";
import IconDeleteAnswer from "../assets/icon/icon_delete_answer";
import IconBinDelete from "../assets/icon/icon_bin_delete";
import SunEditor from "suneditor-react";

import IconTopOrder from "../assets/icon/icon_top_order";
import IconTopOrderDisable from "../assets/icon/icon_top_order_disable";
import IconBottomOrder from "../assets/icon/icon_bottom_order";
import IconBottomOrderDisable from "../assets/icon/icon_bottom_order_disable";
import { AppContext } from "./SurveyFormDetail";
import {arrayMoveImmutable} from 'array-move';
import {
Table,
Dropdown,
Space,
Button,
Form,
Input,
Flex,
Drawer,
Row,
Col,
Select,
message,
InputNumber,
Modal,
Tooltip,
Tag,
Radio,
Switch,
Checkbox,
} from "antd";
import {
ArrowLeftOutlined,
FormOutlined,
MoreOutlined,
PlusOutlined,
} from "@ant-design/icons";
const { TextArea } = Input;
  

let isFirstTime = true;
export default function QuestionCreate({data, optionEditor, passData, setDisableButton}){
    let context = AppContext;

    const { questionList, setQuestionList, validationOption, responseDetail } = useContext(context);
    const [isHaveOther, setIsOther] = useState(false);
    const [checkboxMinimum, setCheckboxMinimum] = useState(0);
    const [checkboxValidationType, setCheckboxValidationType] = useState("1");
    const [questionType, setQuestionType] = useStateCallback(1);
    const [answerList, setAnswerList] = useStateCallback([
        {key:1, name:"",isVariantValue:false, sequence:1}]);
    const questionTypeTextArea = useRef();
    const [questionNameError, setQuestionNameError] = useState();
    const editor = useRef();
    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    };

    if(isFirstTime){
        let currentElement = questionList.find((element) => element.key === data.key);
        currentElement.answers = data.answers;
        let updatedList = questionList.map(item => 
        {
            if (item.key == currentElement.key){
            return {...item, answers:answerList}; //gets everything that was already in item, and updates "done"
            }
            return item; // else return unmodified item 
        });
        setQuestionList(updatedList);
        isFirstTime = false;
        
    }

    useEffect(() => {
        if(responseDetail !== undefined){
            setAnswerList(data.answers);
            if(null !== data.responseValidationNo){
                setCheckboxMinimum(data.responseValidationNo);
                setCheckboxValidationType(data.responseValidationId);
            }
        }
    }, [responseDetail])
    

    useEffect(() => {
        console.log("Resume question list : ",questionList);
        resumeData()
    }, [questionList])



  const DragHandle = sortableHandle(({ active }) => (
    <div
      className="flex justify-center"
      style={{
        backgroundColor: "#EAEDFA",
        padding: "0px 0px 0px 0px",
        marginBottom: 15,
        marginLeft: 0,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        height: 40,
        border: "0px solid #D0D5DD",
      }}
    >
      <IconDragSurvey />
    </div>
  ));

  function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null); // init mutable ref container for callbacks
  
    const setStateCallback = useCallback((state, cb) => {
      cbRef.current = cb; // store current, passed callback in ref
      setState(state);
    }, []); // keep object reference stable, exactly like `useState`
  
    useEffect(() => {
      // cb.current is `null` on initial render, 
      // so we only invoke callback on state *updates*
      if (cbRef.current) {
        cbRef.current(state);
        cbRef.current = null; // reset callback after execution
      }
    }, [state]);
  
    return [state, setStateCallback];
  }


  function handleOnQuestionTypeChange(type){
    setQuestionType(type, function(type){
        let currentElement = questionList.find((element) => element.key === data.key);
        let updatedList = questionList.map(item => 
            {
              if (item.key == currentElement.key){
                return {...item, questionType:type}; //gets everything that was already in item, and updates "done"
              }
              return item; // else return unmodified item 
            });
        setQuestionList(updatedList, function(data) {
            console.log("Data after change question type : ",data);
          });
    });
   
  }

  function onAddAnswer(dataIsVariantValue){
    let findVariant = answerList.find((element) => element.isVariantValue === true);
    let index = 1;
    if(findVariant !== undefined){
      if(answerList.length >= 2){
        index = 2;
      }
    }
    let lastElement = answerList[answerList.length - index];
    let newAnswer = {key:lastElement.key+1, name:"",isVariantValue:dataIsVariantValue, sequence:lastElement.sequence+1}
    if(dataIsVariantValue){
        newAnswer = {key:lastElement.key+1, name:"Other",isVariantValue:dataIsVariantValue, sequence:lastElement.sequence+1}
    }
    setAnswerList([...answerList, newAnswer], function(updateAnswer) {

    // if(findVariant !== undefined && answerList.length >= 2){
    //   let variantElement = data[data.length - 2];
    //   let updatedList = data.map(item => 
    //     {
    //       if (item.key == variantElement.key && item.isVariantValue){
    //         return {...item, key: item.key+1, sequence:item.sequence+1, name:"Other"}; //gets everything that was already in item, and updates "done"
    //       }
    //       return item; // else return unmodified item 
    //     });
    //     const dataToSort = [...updatedList];
    //       dataToSort.sort((a, b) => Number(a.key) - Number(b.key)); // or b.money - a.money to invert order
    //       setAnswerList(dataToSort);
    // }else{
    //   const dataToSort = [...data];
    //   dataToSort.sort((a, b) => Number(a.key) - Number(b.key)); // or b.money - a.money to invert order
    //   setAnswerList(dataToSort);
    // }
    const dataToSort = [...updateAnswer];
      dataToSort.sort((a, b) => Number(a.key) - Number(b.key)); // or b.money - a.money to invert order
      let updatedList2 = questionList.map(item => 
        {
            if (item.key == data.key){
            return {...item, answers:dataToSort}; //gets everything that was already in item, and updates "done"
            }
            return item; // else return unmodified item 
        });
        setQuestionList(updatedList2);
    //   setAnswerList(dataToSort, function(updateData){
    //     console.log("Input data ",updateData);
        
    // });
      
    });
    
  }

  function onRemove(key){
    let findAnswer = answerList.find((element) => element.key === key);
    if(findAnswer.isVariantValue){
      setIsOther(false);
    }
    // answerList.remove(findAnswer);
    setAnswerList(l => l.filter(item => item.key !== key));
    
  }

  function onQuestionRemove(key){
    let findQuestion = questionList.find((element) => element.key === key);
    setQuestionList(l => l.filter(item => item.key !== key));
    
  }

  function onCheckBoxAtlestChange(e, key){
    setCheckboxMinimum(e.target.value)
    let value = e.target.value;
    if(e.target.value > 5){
        // document.getElementById("answer1").value = "5";
        value = "5";
    }

    let updatedList = questionList.map(item => 
        {
          if (item.key == key){
            return {...item, responseValidationNo:value}; //gets everything that was already in item, and updates "done"
          }
          return item; // else return unmodified item 
        });
        setQuestionList(updatedList, function(data){
            console.log("Input data ",data);
        });
  }


  const handleCheckboxValidationChange = (selected) => {
    console.log("Checkbox Validation : ",selected);
    setCheckboxValidationType(selected);
    let updatedList = questionList.map(item => 
        {
          if (item.key == data.key){
            return {...item, responseValidationId:selected}; //gets everything that was already in item, and updates "done"
          }
          return item; // else return unmodified item 
        });
        setQuestionList(updatedList, function(data){
            console.log("Input data ",data);
        });
    // setState({
    //   optionSelected: selected,
    // });
  };

  function resumeData (){
    let currentElement = questionList.find((element) => element.key === data.key);
    setQuestionType(currentElement.questionType);
    if(data.answers.length > 0){
        setAnswerList(data.answers);
    }
    if(data.questionType === "2"){
        setCheckboxValidationType(data.responseValidationId);
        setCheckboxMinimum(data.responseValidationNo);
    }
  }

  function onInputAnswerChanger(e, key){
    let value = parseInt(e.target.value);
    if(value > 5){
        // document.getElementById("answer1").value = "5";
        value = "5"
    }
    let updatedList = answerList.map(item => 
        {
          if (item.key == key){
            return {...item, name:value}; //gets everything that was already in item, and updates "done"
          }
          return item; // else return unmodified item 
        });
        setAnswerList(updatedList, function(updateData){
            console.log("Input data ",updateData);

            // let currentElement = questionList.find((element) => element.key === data.key);
            // currentElement.answers = updateData;
            let updatedList2 = questionList.map(item => 
            {
                if (item.key == data.key){
                return {...item, answers:updateData}; //gets everything that was already in item, and updates "done"
                }
                return item; // else return unmodified item 
            });
            setQuestionList(updatedList2);
        });
    // return false;
  }

  const onswitchChange = (checked) => {
    console.log(`switch to ${checked}`);
    let updatedList = questionList.map(item => 
        {
          if (item.key == data.key){
            return {...item, isRequired:checked}; //gets everything that was already in item, and updates "done"
          }
          return item; // else return unmodified item 
        });
        setQuestionList(updatedList, function(data){
            console.log("Input data ",data);
        });
  };

  function findOrderIndex(data){
    let findIndex = -1;
    questionList.map((item, index) => {
      if (item.key == data.key) {
        findIndex = index; //gets everything that was already in item, and updates "done"
      }// else return unmodified item
    });
    return findIndex;
  }

  function isCanTop (index){
    let result = false
    if(index > 0){
        result = true;
    }
    return result;
  }

  function isCanBottom (index){
    let result = false
    if(index < questionList.length-1){
        result = true;
    }
    return result;
  }

  function getTopIndex (data){
    let index = findOrderIndex(data);
    if(isCanTop(index)){
        index--;
    }
    return index;
  }

  function getBottomIndex (data){
    let index = findOrderIndex(data);
    if(isCanBottom(index)){
        index++;
    }
    return index;
  }

  function moveOrder(direction, data){
    let oldIndex = findOrderIndex(data);
    let updatedList = [...questionList];
    let orderIndex = -1;
    if("top" === direction){
        orderIndex = getTopIndex(data);
    }else{
        orderIndex = getBottomIndex(data);
    }
    let newArray = arrayMoveImmutable(updatedList, oldIndex, orderIndex);
    // let updatedSequnce = newArray.map(item => 
    //     {
    //       if (item.key == data.key){
    //         return {...item, sequence:orderIndex}; //gets everything that was already in item, and updates "done"
    //       }
    //       return item; // else return unmodified item 
    //     });
    //     setQuestionList(updatedSequnce, function(updateData){
    //         console.log("Sort data ",updateData);
    //     });
    setQuestionList(newArray, function(updateData){
        console.log("Sort data ", updateData)
      });
  }

  let checkboxMinimumContent = "";
  if(checkboxMinimum > 0 &&
    answerList.length < checkboxMinimum){
        checkboxMinimumContent = <div
        class="ant-form-item-explain-error"
        style={{ color: "red" }}
      >
        {"This information is required at least " +
          checkboxMinimum +
          "."}
      </div>
      setDisableButton(true);
  }else{
    checkboxMinimumContent = "";
    setDisableButton(false);
  }

//   let topIndex = getTopIndex(data);
//   let bottomIndex = getBottomIndex(data);
//   let lastIndex = questionList.length-1
    let index = findOrderIndex(data);
    let boolIsCanTop = isCanTop(index);
    let boolIsCanBottom = isCanBottom(index);

    return (
        <div
          key={data.key}
          style={{
            backgroundColor: "#fff",
            padding: "0px 0px 0px 0px",
            marginBottom: 15,
            marginLeft: 0,
            borderRadius: 10,
            minHeight: 80,
            border: "1px solid #D0D5DD",
          }}
          className="flex flex-row"
        >
          {/* <DragHandle active={data} /> */}
          <div className="w-100 flex flex-col ms-[12px] mt-[20px]">
            {!boolIsCanTop ? <IconTopOrderDisable/> : <IconTopOrder onClick={() => {
                              moveOrder("top", data);}}/>}
            {!boolIsCanBottom ? <IconBottomOrderDisable className="mt-[18px]"/> : <IconBottomOrder className="mt-[18px]" onClick={() => {
                              moveOrder("bottom", data);}}/>}
            
          </div>
          <div
            style={{ 
              paddingTop: 8,
              paddingLeft: 15,
              paddingRight: 15,
              paddingBottom: 15,
            }}
          >
            <Col span={24} style={{ marginTop: 10 }}>
              <div>
                <Row gutter={[48, 0]}>
                  <Col span={24}>
                    <div>
                      <label
                        style={{
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#1C2538",
                        }}
                      >
                        Question
                        <span style={{ color: "red" }}> *</span>
                      </label>
                    </div>
                    <Form.Item
                      colon={false}
                      className="mt-2"
                      rules={[
                        {
                          required: true,
                          message: "Token is required.",
                        },
                      ]}
                    >
                      <SunEditor
                        className="sun-editor-text"
                        // style={{}}
                        // setContents={editorStateTh}
                        // onChange={(html) => setEditorStateTh(html)}
                        getSunEditorInstance={getSunEditorInstance}
                        setDefaultStyle={"font-size:16px; font-family: Noto Sans Thai;"}
                        setOptions={optionEditor}
                        defaultValue={data.name}
                        onChange={(content) => {
                          let text = editor.current.getText();
                          let currentElement = questionList.find(
                            (element) => element.key === data.key
                          );
                          currentElement.name = content;
                          data.name = content;
                          // setQuestionList({...questionList, currentElement}, function(updateData){
                          //     console.log("Question name ",updateData);
                          // });
                          // setQuestionName(content);
                          if (text.length <= 0) {
                            setQuestionNameError(
                              <div class="ant-form-item-explain-error">
                                This information is required.
                              </div>
                            );
                          } else {
                            setQuestionNameError();
                          }
                        }}
                      />

                      {/* 
                    {passData.state !== null && responseDetail !== undefined ? 
                       <SunEditor
                       className="sun-editor-text"
                       // style={{}}
                       // setContents={editorStateTh}
                       // onChange={(html) => setEditorStateTh(html)}
                       getSunEditorInstance={getSunEditorInstance}
                       setDefaultStyle={"font-size:16px;"}
                       setOptions={optionEditor}
                       defaultValue={data.name}
                       onChange={(content) => {
                         let text = editor.current.getText();
                         let currentElement = questionList.find((element) => element.key === data.key);
                         currentElement.name = content;
                         // setQuestionList({...questionList, currentElement}, function(updateData){
                         //     console.log("Question name ",updateData);
                         // });
                         // setQuestionName(content);
                         if (text.length <= 0) {
                           setQuestionNameError(
                             <div class="ant-form-item-explain-error">
                               This information is required.
                             </div>
                           );
                         } else {
                             setQuestionNameError();
                         }
                       }}
                     />: ""} 
                        
                        {passData.state === null ?  
                           <SunEditor
                           className="sun-editor-text"
                           // style={{}}
                           // setContents={editorStateTh}
                           // onChange={(html) => setEditorStateTh(html)}
                           getSunEditorInstance={getSunEditorInstance}
                           setDefaultStyle={"font-size:16px;"}
                           setOptions={optionEditor}
                           onChange={(content) => {
                             let text = editor.current.getText();
                             let currentElement = questionList.find((element) => element.key === data.key);
                             currentElement.name = content;
                             // setQuestionList({...questionList, currentElement}, function(updateData){
                             //     console.log("Question name ",updateData);
                             // });
                             setQuestionName(content);
                             if (text.length <= 0) {
                               setQuestionNameError(
                                 <div class="ant-form-item-explain-error">
                                   This information is required.
                                 </div>
                               );
                             } else {
                                 setQuestionNameError();
                             }
                           }}
                         /> : ""} */}

                      {questionNameError}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      // label={"Response Validation"}
                      colon={false}
                      className="mt-2"
                      rules={[
                        {
                          required: true,
                          message: "Connector Type is required.",
                        },
                      ]}
                    >
                      <div className=" flex flex-row justify-start items-center cursor-pointer">
                        {questionType === "1" ? (
                          <div
                            className="w-fit min-w-[160px] h-[44px] flex flex-row justify-center items-center cursor-pointer"
                            style={{
                              backgroundColor: "#EEFAFF",
                              padding: "10px 17px 10px 10px",
                              marginLeft: 0,
                              borderRadius: 8,
                              border: "1px solid #69B1FF",
                              marginRight: 8,
                            }}
                            onClick={() => {
                              handleOnQuestionTypeChange("1");
                            }}
                          >
                            <IconRadioSelected />
                            <p
                              className="ms-[8px]"
                              style={{ color: "#1677FF" }}
                            >
                              Single Choice
                            </p>
                          </div>
                        ) : (
                          <div
                            className="w-fit min-w-[160px] h-[44px] flex flex-row justify-center items-center cursor-pointer"
                            style={{
                              backgroundColor: "#fff",
                              padding: "10px 17px 10px 10px",
                              marginLeft: 0,
                              borderRadius: 8,
                              border: "1px solid #D0D5DD",
                              marginRight: 8,
                            }}
                            onClick={() => {
                              handleOnQuestionTypeChange("1");
                            }}
                          >
                            <IconRadioUnSelect />
                            <p className="ms-[8px]">Single Choice</p>
                          </div>
                        )}

                        {questionType === "2" ? (
                          <div
                            className="w-fit min-w-[160px] h-[44px] flex flex-row justify-center items-center cursor-pointer"
                            style={{
                              backgroundColor: "#EEFAFF",
                              padding: "10px 17px 10px 10px",
                              marginLeft: 0,
                              borderRadius: 8,
                              border: "1px solid #69B1FF",
                              marginRight: 8,
                            }}
                            onClick={() => {
                              handleOnQuestionTypeChange("2");
                            }}
                          >
                            <IconCheckboxSelected />
                            <p
                              className="ms-[8px]"
                              style={{ color: "#1677FF" }}
                            >
                              Multiple Choice
                            </p>
                          </div>
                        ) : (
                          <div
                            className="w-fit min-w-[160px] h-[44px] flex flex-row justify-center items-center cursor-pointer"
                            style={{
                              backgroundColor: "#fff",
                              padding: "10px 17px 10px 10px",
                              marginLeft: 0,
                              borderRadius: 8,
                              border: "1px solid #D0D5DD",
                              marginRight: 8,
                            }}
                            onClick={() => {
                              handleOnQuestionTypeChange("2");
                            }}
                          >
                            <IconCheckboxUnSelect />
                            <p className="ms-[8px]">Multiple Choice</p>
                          </div>
                        )}

                        {questionType === "3" ? (
                          <div
                            className="w-fit min-w-[160px] h-[44px] flex flex-row justify-center items-center cursor-pointer"
                            style={{
                              backgroundColor: "#EEFAFF",
                              padding: "10px 17px 10px 10px",
                              marginLeft: 0,
                              borderRadius: 8,
                              border: "1px solid #69B1FF",
                              marginRight: 8,
                            }}
                            onClick={() => {
                              handleOnQuestionTypeChange("3");
                            }}
                            ref={questionTypeTextArea}
                          >
                            <IconTextareaSelected />
                            <p
                              className="ms-[8px]"
                              style={{ color: "#1677FF" }}
                            >
                              Text Area
                            </p>
                          </div>
                        ) : (
                          <div
                            className="w-fit min-w-[160px] h-[44px] flex flex-row justify-center items-center cursor-pointer"
                            style={{
                              backgroundColor: "#fff",
                              padding: "10px 17px 10px 10px",
                              marginLeft: 0,
                              borderRadius: 8,
                              border: "1px solid #D0D5DD",
                              marginRight: 8,
                            }}
                            onClick={() => {
                              handleOnQuestionTypeChange("3");
                            }}
                            ref={questionTypeTextArea}
                          >
                            <IconTextareaUnSelect />
                            <p className="ms-[8px]">Text Area</p>
                          </div>
                        )}
                      </div>
                    </Form.Item>

                    {questionType === "2" ? (
                      <div
                        className="flex flex-col justify-start"
                        style={{ marginBottom: 8 }}
                      >
                        <label
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1C2538",
                          }}
                        >
                          Response Validation
                          <span style={{ color: "red" }}> *</span>
                        </label>
                        <div className="flex flex-row">
                          <Select
                            className="h-[38px] ms-[0px] w-[200px]"
                            defaultValue={checkboxValidationType}
                            allowClear
                            // className="mt-2"
                            options={validationOption}
                            style={{ marginRight: 0 }}
                            placeholder="Validation"
                            showSearch
                            maxTagCount="responsive"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            //to do change this
                            onChange={handleCheckboxValidationChange}
                            //   value={data.responseValidationId}
                          ></Select>
                          {data.responseValidationId != undefined &&
                          data.responseValidationId !== "1" ? (
                            <Input
                              id={"checkboxValidation" + data.key}
                              type="number"
                              className="h-[38px] w-[115px] ms-[8px]"
                              onChange={(e) => {
                                onCheckBoxAtlestChange(e, data.key);
                              }}
                              max={5}
                              value={data.responseValidationNo}
                            ></Input>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col span={24}>
                    {questionType === "3" ? (
                      ""
                    ) : (
                      <div>
                        {answerList.map((data) => {
                          let displayInput = "";
                          if (data.isVariantValue) {
                            displayInput = (
                              <p
                                style={{
                                  fontWeight: 600,
                                  fontSize: 16,
                                  color: "#1C2538",
                                  marginBottom: 4,
                                  marginLeft: 8,
                                }}
                              >
                                Other
                              </p>
                            );
                          } else {
                            displayInput = (
                              <Input
                                id={"answer" + data.key}
                                max={5}
                                className="font-[500] text-[16px] ms-[8px]"
                                type="number"
                                onChange={(e) => {
                                  onInputAnswerChanger(e, data.key);
                                }}
                                value={data.name}
                              ></Input>
                            );
                          }
                          return (
                            <>
                              <div className="flex flex-row">
                                <div span={14}>
                                  <div className="flex flex-row items-center justify-start">
                                    {questionType === "1" ? (
                                      <Radio className="mt-[-28px] pointer-events-none"></Radio>
                                    ) : (
                                      ""
                                    )}
                                    {questionType === "2" ? (
                                      <Checkbox className="mt-[-28px] pointer-events-none"></Checkbox>
                                    ) : (
                                      ""
                                    )}
                                    <div className="flex flex-col">
                                      <Form.Item
                                        // label={"Response Validation"}
                                        colon={false}
                                        className="mt-[0px] w-[320px]"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "This information is required.",
                                          },
                                        ]}
                                      >
                                        {displayInput}
                                      </Form.Item>
                                    </div>
                                  </div>
                                </div>
                                <div span={8}>
                                  {answerList.length > 1 && (
                                    <div className="flex justify-start mt-[4px]">
                                      <div
                                        className="cursor-pointer hover:opacity-80 ml-[18px]"
                                        // onClick={() => {
                                        // }}
                                      >
                                        <IconDeleteAnswer
                                          onClick={() => {
                                            onRemove(data.key);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          );
                        })}
                        <Form.Item>
                          {checkboxMinimumContent}
                          <div className="flex justify-start items-center">
                            <>
                              {answerList.length < 5 ? (
                                <Button
                                  className="w-[150px] h-[44px] items-center"
                                  style={{
                                    marginRight: 15,
                                    borderRadius: 8,
                                    border: "1px solid #D9D9D9",
                                    //marginTop: 20,
                                    color: "#000",
                                    backgroundColor: "#FFF",
                                  }}
                                  icon={
                                    <PlusOutlined
                                      style={{ color: "#1C2538" }}
                                    />
                                  }
                                  shape="round"
                                  onClick={() => onAddAnswer(false)}
                                >
                                  Add Answer
                                </Button>
                              ) : (
                                ""
                              )}

                              {answerList.length < 5 && !isHaveOther ? (
                                <div className="flex flex-row items-center">
                                  <p
                                    className=""
                                    style={{
                                      fontWeight: 600,
                                      fontSize: 16,
                                      color: "#1C2538",
                                    }}
                                  >
                                    Or
                                  </p>
                                  <span
                                    className="ms-[8px] underline cursor-pointer"
                                    style={{
                                      fontWeight: 600,
                                      fontSize: 16,
                                      color: "#1677FF",
                                    }}
                                    onClick={() => {
                                      onAddAnswer(true);
                                      setIsOther(true);
                                    }}
                                  >
                                    + Add “other”
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          </div>
                        </Form.Item>
                      </div>
                    )}
                  </Col>

                  <Col span={24}>
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                        // marginLeft: "-12px",
                        margin: "10px 0px",
                        borderBottom: "1px solid #D0D0D0",
                      }}
                    />
                    <div className="flex justify-end mt-[12px]">
                      <label className="font-[600] text-[16px] mt-[4px]">
                        Required
                      </label>{" "}
                      <Switch
                        className="ml-2 mt-[5px]"
                        defaultChecked
                        onChange={onswitchChange}
                        checked={data.isRequired}
                      />
                      {questionList.length > 1 ? (
                        <>
                          <div
                            className=""
                            style={{
                              height: 30,
                              marginTop: 0,
                              marginLeft: 15,
                              marginRight: 15,
                              // paddingTop: 23,
                              borderRight: "1px solid #D0D5DD",
                            }}
                          />
                          <div
                            className="cursor-pointer hover:opacity-80 ml-0"
                            onClick={() => {
                              onQuestionRemove(data.key);
                              // Swal.fire({
                              //   title: "Delete Connector",
                              //   text: `Do you want to delete this connector ?`,
                              //   //imageUrl: discardChanges,
                              //   showCancelButton: true,
                              //   confirmButtonColor: "#3085d6",
                              //   cancelButtonColor: "#d33",
                              //   confirmButtonText: "Confirm",
                              //   showLoaderOnConfirm: true,
                              //   reverseButtons: true,
                              //   preConfirm: async () => {
                              //     remove(name);
                              //   },
                              // });
                            }}
                          >
                            <IconBinDelete />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </div>
        </div>
    );
  }