import Icon from "@ant-design/icons";

const icon = () => (
  

<svg width="126" height="126" viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="8" y="8" width="110" height="110" rx="42" fill="#FFEDCA"/>
<rect x="8" y="8" width="110" height="110" rx="42" stroke="#FFF8EA" stroke-width="15"/>
<path d="M40.5 52.5C40.5 48.2996 40.5 46.1994 41.3175 44.5951C42.0365 43.1839 43.1839 42.0365 44.5951 41.3175C46.1994 40.5 48.2996 40.5 52.5 40.5H69.6863C70.9092 40.5 71.5207 40.5 72.0962 40.6382C72.6063 40.7606 73.0941 40.9627 73.5414 41.2368C74.046 41.546 74.4784 41.9784 75.3431 42.8431L83.1569 50.6569C84.0216 51.5216 84.454 51.954 84.7632 52.4586C85.0373 52.9059 85.2394 53.3937 85.3619 53.9038C85.5 54.4793 85.5 55.0908 85.5 56.3137V73.5C85.5 77.7004 85.5 79.8006 84.6825 81.4049C83.9635 82.8161 82.8161 83.9635 81.4049 84.6825C79.8006 85.5 77.7004 85.5 73.5 85.5H52.5C48.2996 85.5 46.1994 85.5 44.5951 84.6825C43.1839 83.9635 42.0365 82.8161 41.3175 81.4049C40.5 79.8006 40.5 77.7004 40.5 73.5V52.5Z" fill="#FFC044"/>
<path d="M50.5 40.5V49C50.5 50.4001 50.5 51.1002 50.7725 51.635C51.0122 52.1054 51.3946 52.4878 51.865 52.7275C52.3998 53 53.0999 53 54.5 53H71.5C72.9001 53 73.6002 53 74.135 52.7275C74.6054 52.4878 74.9878 52.1054 75.2275 51.635C75.5 51.1002 75.5 50.4001 75.5 49V43M75.5 85.5V69.5C75.5 68.0999 75.5 67.3998 75.2275 66.865C74.9878 66.3946 74.6054 66.0122 74.135 65.7725C73.6002 65.5 72.9001 65.5 71.5 65.5H54.5C53.0999 65.5 52.3998 65.5 51.865 65.7725C51.3946 66.0122 51.0122 66.3946 50.7725 66.865C50.5 67.3998 50.5 68.0999 50.5 69.5V85.5M85.5 56.3137V73.5C85.5 77.7004 85.5 79.8006 84.6825 81.4049C83.9635 82.8161 82.8161 83.9635 81.4049 84.6825C79.8006 85.5 77.7004 85.5 73.5 85.5H52.5C48.2996 85.5 46.1994 85.5 44.5951 84.6825C43.1839 83.9635 42.0365 82.8161 41.3175 81.4049C40.5 79.8006 40.5 77.7004 40.5 73.5V52.5C40.5 48.2996 40.5 46.1994 41.3175 44.5951C42.0365 43.1839 43.1839 42.0365 44.5951 41.3175C46.1994 40.5 48.2996 40.5 52.5 40.5H69.6863C70.9092 40.5 71.5207 40.5 72.0962 40.6382C72.6063 40.7606 73.0941 40.9627 73.5414 41.2368C74.046 41.546 74.4784 41.9784 75.3431 42.8431L83.1569 50.6569C84.0216 51.5216 84.454 51.954 84.7632 52.4586C85.0373 52.9059 85.2394 53.3937 85.3619 53.9038C85.5 54.4793 85.5 55.0908 85.5 56.3137Z" stroke="#FFEDCA" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


);

const Icon_Confirm_Submit = (props) => <Icon component={icon} {...props} />;

export default Icon_Confirm_Submit;

