/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { SyncOutlined, LockOutlined } from '@ant-design/icons';
import { ArrowRightOutlined } from '@ant-design/icons';
import packageJson from "../../package.json";
import {
  Layout,
  Menu,
  Button,
  theme,
  Breadcrumb,
  Image,
  Flex,
  Dropdown,
  Tag
} from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  HomeOutlined,
  ReadOutlined,
  ExportOutlined,
  LogoutOutlined,
  DownOutlined,
  ProjectOutlined,
  FileTextOutlined,
  PhoneOutlined,
  SnippetsOutlined,
  FileDoneOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import Logo from "../assets/img/logo-menu.png";
import AuthService from "../service/authService";

// import logoImage from "../assets/logo_Rajabhat.png";

import Icon from "@ant-design/icons";
import UserListService from "../service/userListService";
import PermissionService from "../service/permissionService";

// import { useKeycloak } from "@react-keycloak/web";

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;
const userListService = new UserListService();
const permissionService = new PermissionService();

const App = () => {
  // const { keycloak, initialized } = useKeycloak();
  let isFirstTime = true;
  const navigate = useNavigate();
  const location = useLocation();
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  if (permissions === null) {
    window.location.replace('/login');
  }
  // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [userInfo, setUserInfo] = useState();
  const UserMenu = permissions.find(
    (todo) => todo.pageName === "User Management"
  ).isActivated;
  const PermissionMenu = permissions.some(i => i.roleId === '1');



  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [textColor, setTextColor] = useState("#98A2B3");

  const [selectedKeys, setSelectedKeys] = useState(() => {
    return localStorage.getItem('selectedMenuKey') || ['0'];
  });

  const getTagColorByRole = (roleName) => {
    let colorStr = "";
    let roleNameStr = "";
    if(roleName == "Editer"){
      colorStr ="warning";
      roleNameStr = "Editor"
    } else if(roleName == "Observer"){
      colorStr ="default";
      roleNameStr = roleName
    } else if(roleName == "Moderator"){
      colorStr ="green";
      roleNameStr = roleName
    } else if(roleName == "Administrator"){
      colorStr ="blue";
      roleNameStr = roleName
    }
    return <Tag color={colorStr} ><b>{roleNameStr}</b></Tag>
  }

  const menu = (

      <Menu   style={{ marginTop: "-25px", backgroundColor: "#F8F8F8" }}>
        
          
        <div className="p-2">
          <div className="pb-2"><b>{userInfo !== undefined ? userInfo.email : ""}</b></div> 
           <div className="pb-5">{userInfo !== undefined ? getTagColorByRole(userInfo.roleName) : ""}</div>
            <div className=" pl-3 pr-5" >
              <Menu.Item style={{backgroundColor: "#F8F8F8" }}>
                <Link to="/user-profile">
                  {<UserOutlined />} My Profile
                </Link>
              </Menu.Item>

              <Menu.Item style={{backgroundColor: "#F8F8F8" }}>
                <Link to="/edit-password">
                  {<LockOutlined />} Change Password
                </Link>
              </Menu.Item>
              </div>
              <hr style={{ borderTop: "1px solid #C8C8C8" }} />
              <div className=" pl-3 pr-5" >
              <Menu.Item style={{backgroundColor: "#F8F8F8" }}
                // key="11"
                // eslint-disable-next-line no-restricted-globals
                onClick={
                  () => {
                    localStorage.clear();
                    navigate("/login");
                  }

                  // navigate("/login")
                }
              // disabled={listMember.length > 0 ? false : true}
              >
                {/* <Link to="#/" disabled={true}></Link> */}
                <LogoutOutlined style={{ marginRight: '8px' }} />Logout
                {/* <Button onClick={() => ExportPDFHandler()}>Tax Invoice</Button> */}
              </Menu.Item>
            </div>
      </div>
    </Menu>

    
  );

  
  //console.log("oooo", localStorage.getItem("user"));



  useEffect(() => {
    // console.log("userInfo:", userInfo);
    localStorage.setItem('selectedMenuKey', selectedKeys);
  }, [selectedKeys]);

  // useEffect(() => {
  //   if (!localStorage.getItem("userInfo")) {
  //     navigate("/login");
  //     //console.log("000111100");
  //   }
  // }, [navigate]);
  function getUserInfo() {
    let userId = localStorage.getItem("userId");
    userListService
      .getUserDetail(userId)
      .then((response) => {
        console.log("loginValue", response);
        setUserInfo(response.data);

      })
      .catch(async (err) => {
        console.log(err);
        // if(isFirstTime){
        //   isFirstTime = false;
        //   getUserInfo();
        // }
      });
  }

  useEffect(() => {
    getUserInfo();
    getPermissionNew();
  }, [navigate]);

  async function getPermissionNew() {
    try{
      let userId = localStorage.getItem("userId");
      const permissionNew = await permissionService.getPermissionForuserId(userId);
      localStorage.setItem("permissions", JSON.stringify(permissionNew.data.permissions));
      console.log("permissions", permissionNew)
    }catch(err){
      console.log(err);
    }
   
  }


  const onLinkClick = function (e) {
    setSelectedKeys([e.key]);
  }

  return (
    <>
      <Layout
        style={{
          minHeight: "100vh",
          backgroundColor: "#F8F8F8",
        }}
      >
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          width={250}
          onBreakpoint={(broken) => {
            // console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            // console.log(collapsed, type);
          }}
          style={{
            //backgroundColor: "#0E0F1D",
            //backgroundImage: 'linear-gradient("#0E0F1D", "#27293D","#27293D")'
            backgroundImage: `linear-gradient( "#FF0000", "#FF0000","#FF0000")`,
            //backgroundImage: linearGradient("#0E0F1D", "#27293D")
            // overflow: 'auto', position: 'fixed', left: 0, top: 0, bottom: 0 
          }}
        >
          <div
            className="demo-logo-vertical"
            //style={{ marginLeft: 10, marginTop: 10 }}
            style={{ margin: 30 }}
          >
            <img src={Logo} style={{ width: 180 }}></img>
          </div>
          <Menu
            onClick={onLinkClick}
            theme="light"
            mode="inline"
            height={100}
            selectedKeys={selectedKeys}
            defaultSelectedKeys={["1"]}
            style={{
              // background: "#0E0F1D",
              // color: "#fff",
              background: "transparent",
            }}
          >

            {permissions.find((todo) => todo.pageName === "Dashboard")
              .isActivated ? (
              <>
                <Menu.Item key="1">
                  <Link to="/dashboard">
                    <ProjectOutlined
                      style={{ transform: "rotate(180deg)" }}
                    />
                    <span style={{ fontSize: 15 }}>
                      Dashboard
                    </span>
                  </Link>
                </Menu.Item>
              </>
            ) : null}

            {permissions.find((todo) => todo.pageName === "Survey Form")
              .isActivated ? (
              <>
                <Menu.Item key="2">
                  <Link to="/survey-form-list">
                    <FileTextOutlined />
                    <span >Survey Form</span>
                  </Link>
                </Menu.Item>
              </>
            ) : null}

            {permissions.find(
              (todo) => todo.pageName === "Survey Form Transaction"
            ).isActivated ? (
              <>
                <Menu.Item key="3">
                  <Link to="/survey-transaction-list">
                    <FileTextOutlined />
                    <span >
                      Survey Form Transaction
                    </span>
                  </Link>
                </Menu.Item>
              </>
            ) : null}

            {permissions.find((todo) => todo.pageName === "IVR Transaction")
              .isActivated ? (
              <>
                <Menu.Item key="4">
                  <Link to="/ivr-transaction-list">
                    <PhoneOutlined />
                    <span >IVR Transaction</span>
                  </Link>
                </Menu.Item>
              </>
            ) : null}

            {permissions.find((todo) => todo.pageName === "Report")
              .isActivated ? (
              <>
                <Menu.Item key="5">
                  <Link to="/report-list">
                    <SnippetsOutlined />
                    <span >Report</span>
                  </Link>
                </Menu.Item>
              </>
            ) : null}


            <div style={{ marginLeft: 20, marginTop: 30, marginBottom: 20 }}>
              <label style={{ color: "#FFFFFF", fontWeight: 600 }}>
                {permissions[0].roleName}
              </label>
            </div>



            <Menu.Item key="7" style={{ display: UserMenu ? "flex" : "none" }}>
              <Link to={"/user-list"}>
                <FileDoneOutlined />
                <span >User Management</span>
              </Link>
            </Menu.Item>



            <Menu.Item key="8" style={{ display: PermissionMenu ? "flex" : "none" }}>
              <Link to={"/permission-list"}>
                <KeyOutlined />
                <span >Permission</span>
              </Link>
            </Menu.Item>

          </Menu>
          <div style={{ 
                      position: 'absolute', 
                      bottom: 0, 
                      left: 0, 
                      width: '100%', 
                      padding: '16px', 
                      color: '#fff', 
                      textAlign: "center",
                      //position: "fixed", 
                    }} 
            className="ant-menu-item">
            <hr style={{ border: "1px solid #474B61" }} className="ant-menu-item" />
            Version: {packageJson.version}
          </div>
        </Sider>
        <Layout style={{ backgroundColor: "#F8F8F8", height: "100%" }}>
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
              backgroundColor: "#F8F8F8",
            }}
          >
<div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 10, marginTop: 10 }}>
  <Dropdown overlay={menu} placement="bottomRight">
    <span style={{ display: 'inline-block', lineHeight: '1', textAlign: 'center' }}>
      <span style={{ display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: 'bold' }}>
        {userInfo !== undefined ? userInfo.firstName : ""}&nbsp;{userInfo !== undefined ? userInfo.lastName : ""}
        <span style={{ height: '25px', borderLeft: '1px solid black', margin: '0 10px' }}></span> {/* เส้นแบ่งยาว */}
        <Button className="menu-dropdown" type="text">
          <DownOutlined />
        </Button>
      </span>
      <span style={{ display: 'block', marginTop: '5px', paddingRight: '50px' }}>
        {userInfo !== undefined ? userInfo.roleName : ""}
      </span>
    </span>
  </Dropdown>
</div>

          </Header>
          <br />
          <Content
            // width="75%"
            style={{
              // margin: "0px 15px",
              padding: "0px 24px",
              //marginBottom: "70px",
              minHeight: 280,
              backgroundColor: "#F8F8F8",
              overflow: 'auto',
              // marginLeft: '25%'
            }}
          >
            <div
              style={{
                // padding: 24,
                minHeight: 620,
                backgroundColor: "#F8F8F8",
              }}
            >
              <Outlet style={{ backgroundColor: "#F8F8F8" }} />
            </div>
            <Footer
              className=""
              style={{
                width:"100%",
                textAlign: "center",
                backgroundColor: "#F8F8F8",
                //backgroundColor: "transparent",
                bottom:"0",
                //left : 0,
                //right: 0,
                //position: "fixed",
              }}
            >
              <div>Web Survey Copyright © 2024 G-Able Public Company Limited. All rights reserved.</div>
              
            </Footer>
          </Content>
          
        </Layout>
        
      </Layout>
      
    </>
  );
};
export default App;
