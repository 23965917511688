import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Table,
  Dropdown,
  Space,
  Button,
  Form,
  Input,
  Flex,
  Drawer,
  Row,
  Col,
  Select,
  message,
  InputNumber,
  Modal,
  Switch,
  Tooltip,
  ConfigProvider,
} from "antd";
import SurveyFormService from "../service/surveyFormService";
import {
  EditTwoTone,
  EyeTwoTone,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import IconEdit from "../assets/icon/icon_edit";
import IconUnlock from "../assets/icon/icon_unlock";
import IconView from "../assets/icon/view_icon";
import { useHistory } from "react-router";
import IconArrowLeft from "../assets/icon/arrow_left";
import Utills from "./Utills";
// import { LearningPlaceApi } from "../apis/LearningPlaceApi";
// import { RefCampusApi } from "../apis/RefCampusApi";
// import { RefUnivApi } from "../apis/RefUnivApi";
// import { RefPlaceStatusApi } from "../apis/RefPlaceStatusApi";
// import { RefProvinceApi } from "../apis/RefProvinceApi";

const surveyFormService = new SurveyFormService();
const utills = new Utills();

let currentPage = 1;
let pageSize = 10;
let sortBy = "";
let sortDirection = "";
let total = 0;

const SurveyFormHistoryList = () => {
  const passData = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useNavigate();
  const [pageValue, setPageValue] = useState({
    page: currentPage,
    pageSize: pageSize,
    total: 0,
    SurveyFormId: passData.state.id,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const testData = [
    {
      id: 1,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 2,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 3,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 4,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 5,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 6,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 6,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 7,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 8,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 9,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 10,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 11,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
  ];

  // let newData = testData[0];
  // newData.id = 4;
  // // testData.push(newData)
  // // console.log(testData);

  const columns = [
    {
      title: "No",
      // dataIndex: "id",
      width: '5%',
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
    },
    {
      title: "Channel",
      width: '10%',
      dataIndex: "channels",
      sorter: true,
      render: (text, record, index) => utills.getChannelName(record.channels),
    },
    {
      title: "Version",
      width: '10%',
      dataIndex: "version",
      sorter: true,
    },
    {
      title: "Create By",
      width: '15%',
      dataIndex: "createdBy",
      sorter: true,
    },
    {
      title: "Create Date",
      width: '15%',
      dataIndex: "createdDateDisplay",
        sorter: true,
    },
   
    {
      title: "Action",
      width: '5%',
      render: (text, record) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {/* {role[0].roleKey.includes("web-admin") && ( */}
          <Tooltip placement="bottom" title="Detail">
            <IconView
              style={{ marginRight: 10 }}
              onClick={() => {
                history(
                  {
                    pathname: `/survey-form-history-detail`,

                    // pagedata: pageValue,
                  },
                  { state: { id: record.id } }
                );
              }}
              twoToneColor="#98A2B3"
              onMouseEnter={()=>{
                // alert("I am an alert box!");
              }}
            />
          </Tooltip>
          {/* )} */}
        </span>
      ),
    },
  ];

  const { Option } = Select;

  const query = useQuery();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [Keywords, setKeywords] = useState("");

  const { Search, TextArea } = Input;

  const onSearch = (value, _e, info) => {
    // console.log(info?.source, value);
    const pageValues = {
      page: currentPage,
      pageSize: pageSize,
      sortBy: sortBy,
      sortDirection: sortDirection,
      total: total,
      keywords: Keywords,
    };
    setPageValue(pageValues);
    fetchData(pageValues);
  };

  const handleSearchChange = (event) => {
    setKeywords(event.target.value);
  };

  // const [filterCampus, setFilterCampus] = useState([]);
  // const [disabledCampus, setDisableCampus] = useState(true);

  const [data, setData] = useState();
  const [dataList, setDataList] = useState();

  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: pageSize,
      position: ['bottomCenter'],
      total: 100,
      showSizeChanger: true,
    },
  });

  function fetchData(pageValue){
    setLoading(true);
    surveyFormService.getSurveyFormHistoryList(pageValue).then((response) => {
      setLoading(false);
      if(response.data != undefined){
        total = response.data.pagination.totalCount;
        setTableParams({
          pagination: {
            current: currentPage,
            pageSize: pageSize,
            position: ['bottomCenter'],
            total: total,
            showSizeChanger: true,
          },
        });
        setData(response.data.items);
      }
      // if (response.data.objectValue) setDataList(response.data.objectValue);
      // console.log("ddddd", response.data.objectValue);
    }).catch(async (err) => {
      console.log("err", err);
    });
  }

  const getDropdownConnector = (value) => {
    // userListService.getUserList().then((response) => {
    //   if (response.data.objectValue) setDataList(response.data.objectValue);
    //   console.log("ddddd",response.data.objectValue);
    // });
  };

  function useQuery() {
    const { search } = useLocation();

    // return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  useEffect(() => {
    fetchData(pageValue);
    //   }, [JSON.stringify(tableParams)]);
  }, []);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    currentPage = pagination.current;
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  function capitalize(str){
    if(str == undefined){
      return;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const onPageChange = async (pagination, filters, sorter) => {
    let sortBy = capitalize(sorter.field);
    if("createdDateDisplay" === sorter.field){
      sortBy = "CreateDate";
    }
    currentPage = pagination.current;
    pageSize = pagination.pageSize;
    const pageValues = {
      keywords: Keywords,
      page: pagination.current,
      pageSize: pagination.pageSize,
      sortBy: sortBy,
      sortDirection:
        sorter && sorter.order
          ? sorter.order.substring(0, 3).toUpperCase() === "ASC"
            ? "asc"
            : "desc"
          : "asc",
      total: pageValue.total,
      SurveyFormId: passData.state.id,
    };
    setPageValue(pageValues);

    fetchData(pageValues);
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#ffffff",
          },
          components: {
            Pagination: {
              itemActiveBg: "#FF7F07",
            },
            Input: {
              activeBorderColor:'#1677ff',
            },
          },
        }}
      >
        {contextHolder}
        <div className="flex flex-row mb-[20px]">
              <IconArrowLeft
                style={{ marginRight: 20 }}
                onClick={() => {
                  history(-1);
                }}
                twoToneColor="#98A2B3"
                onMouseEnter={() => {
                  // alert("I am an alert box!");
                }}
              />
              <label style={{ fontSize: 24, fontWeight: 600 }}>
                History
              </label>
            </div>
     

        <div
          style={{
            backgroundColor: "#fff",
            padding: 15,
            marginBottom: 15,
            borderRadius: 10,
          }}
        >
          <div>
            <Row>
              <Col span={20} style={{ marginRight: 0 }}>
                <label style={{ fontSize: 20, fontWeight: 600 }}>
                  Survey Form History list
                </label>
              </Col>
              <Col span={4} style={{ marginRight: 0 }}>
                {/* <Flex justify="flex-end" style={{ marginRight: 0 }}>
                <Button
                  className="w-28"
                  style={{
                    marginRight: 10,
                    backgroundColor: "#FF7F07",
                    color: "#ffffff",
                    border: "2px solid #FF7F07",
                  }}
                  onClick={onSearch}
                  // type="primary"
                  shape="default"
                  htmlType="submit"
                  icon={<PlusOutlined />}
                >
                  Add User
                </Button>
              </Flex> */}
              </Col>
            </Row>
          </div>

          <Table
          className="mt-8"
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={tableParams.pagination}
            loading={loading}
            onChange={onPageChange}
            scroll={{
              x: 1024,
            }}
          />
        </div>
      </ConfigProvider>
    </>
  );
};

export default SurveyFormHistoryList;
