import axios from "axios";
import environment from "../environment/environment";

const url = environment.SERVICE_URL_REPORT;
const url_host = environment.SERVICE_URL_EV;

const getIvrHit = async (start, end, page, pageSize) => {
  try {
    const response = await axios.get(`${url}/ivr-hit`, {
      params: {
        "StartDate": start,
        "EndDate": end,
        "Pagination.Page": page,
        "Pagination.PageSize": pageSize
      }
    });
    if (response.data.items.length === 0) {
      throw new Error('No data found');
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


const getVoiceTransaction = async (start, end , page, pagesize) => {
  try {
    const response = await axios.get(`${url}/voice-transaction`, {
      params: {
        "StartDate": start,
        "EndDate": end,
        "Pagination.Page": page,
        "Pagination.PageSize": pagesize
      }
    });
    console.log('Response from getVoiceTransaction:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


const getVoiceSummaryReporst= async (start, end , page, pagesize) => {
  try {
    const response = await axios.get(`${url}/voice-summary`, {
      params: {
        "StartDate": start,
        "EndDate": end,
        "Pagination.Page": page,
        "Pagination.PageSize": pagesize
      }
    });
    console.log('Response from getVoiceTransaction:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


const getSummary = async (start, end, selectedChannelId, selectedVersion , page , pagesize) => {
  try {
    const response = await axios.get(`${url}/non-voice-summary`, {
      params: {
        "StartDate": start,
        "EndDate": end,
        "ChannelId": selectedChannelId,
        "Version": selectedVersion,
        "Pagination.Page": page,
        "Pagination.PageSize": pagesize
      }
    });
    console.log("nonvoice",response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const getTransaction = async (start, end, selectedChannelId, selectedVersion , page , pagesize) => {
  try {
    const response = await axios.get(`${url}/non-voice-transaction`, {
      params: {
        "StartDate": start,
        "EndDate": end,
        "ChannelId": selectedChannelId,
        "Version": selectedVersion,
        "Pagination.Page": page,
        "Pagination.PageSize": pagesize
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const getChannel = async (SurveyFormId) => {
  try {
    // Assuming you're using axios and the API expects SurveyFormId as a query parameter
    const response = await axios.get(`${url_host}/survey-form-histories/version`, {
      params: { SurveyFormId }
    });
    console.log('Channels:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


const getVersion = async () => {
  try {
    const response = await axios.get(`${url_host}/survey-form-histories/version`);
     console.log('Version:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const getName = async () => {
  try {
    const response = await axios.get(`${url_host}/survey-forms/name`);
     console.log('Version:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


const getTitle = async () => {
  try {
    const response = await axios.get(`${url_host}/survey-form-histories/Title`);
    // console.log('Title:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};




const IVRHITExport = async (start, end) => {
  try {
    const response = await fetch(`${url}/export-ivr-hit?StartDate=${start}&EndDate=${end}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem("accessToken")),
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `ivr_report_${start}_to_${end}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      console.error('การส่งคำขอล้มเหลว');
    }
  } catch (error) {
    console.error('เกิดข้อผิดพลาดในการส่งคำขอ:', error);
  }
};


const NonVoiceSummaryExport = async (start, end, selectedName , selectedChannelId, selectedVersion) => {
  try {
    const response = await fetch(`${url}/export-non-voice-summary?StartDate=${start}&EndDate=${end}&SurveyFormId=${selectedName}&ChannelId=${selectedChannelId}&Version=${selectedVersion}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem("accessToken")),
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Nonvoicesummary_${start}_to_${end}_report.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      console.error('การส่งคำขอล้มเหลว');
    }
  } catch (error) {
    console.error('เกิดข้อผิดพลาดในการส่งคำขอ:', error);
  }
};

const NonVoiceTransactionExport = async (start, end, selectedName , selectedChannelId, selectedVersion) => {
  try {
    const response = await fetch(`${url}/export-non-voice-transaction?StartDate=${start}&EndDate=${end}&SurveyFormId=${selectedName}&ChannelId=${selectedChannelId}&Version=${selectedVersion}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem("accessToken")),
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Nonvoicetransaction_${start}_to_${end}_report.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      console.error('การส่งคำขอล้มเหลว');
    }
  } catch (error) {
    console.error('เกิดข้อผิดพลาดในการส่งคำขอ:', error);
  }
};


const VoiceSummaryExport = async (start, end) => {
  try {
    const response = await fetch(`${url}/export-voice-summary?StartDate=${start}&EndDate=${end}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem("accessToken")),
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `voiceSummary_${start}_to_${end}_report.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      console.error('การส่งคำขอล้มเหลว');
    }
  } catch (error) {
    console.error('เกิดข้อผิดพลาดในการส่งคำขอ:', error);
  }
};


const VoiceTransactionExport = async (start, end) => {
  try {
    const response = await fetch(`${url}/export-voice-transaction?StartDate=${start}&EndDate=${end}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem("accessToken")),
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `voiceTransaction_${start}_to_${end}_report.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      console.error('การส่งคำขอล้มเหลว');
    }
  } catch (error) {
    console.error('เกิดข้อผิดพลาดในการส่งคำขอ:', error);
  }
};


export { getIvrHit , getChannel , getTitle , getVersion , getSummary , getTransaction , getVoiceTransaction , getVoiceSummaryReporst , IVRHITExport , NonVoiceSummaryExport , NonVoiceTransactionExport , VoiceSummaryExport , VoiceTransactionExport , getName};
