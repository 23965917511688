import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  message,
  Checkbox,
  Layout,
  Flex
} from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import Logo from "../assets/img/logo.png";
import Background from "../assets/img/background.jpg";
import AuthService from "../service/authService";
import packageJson from "../../package.json";
import { Alert } from 'antd';
import { CloseCircleOutlined } from "@ant-design/icons";
import Swal from 'sweetalert2';
import "../Login.css";
import error404SVG from '../assets/img/error404.svg';

const { Footer } = Layout;

const Login = () => {
  const history = useNavigate();

  return (
    <>

      <Flex gap="middle" vertical justify={"center"} align={"center"}
        style={{
          width: "100%",
          height: "100vh",
          backgroundColor: "#FFFCF9",
        }}>

        <div>
          <div
            style={{
              textAlign: "center",
              //marginBottom: "1rem"
            }}
          >
            
          </div>

          

          <div
            style={{
              width:600,
              boxShadow: "0px 0px 45px 15px #FCF2E8",
              marginBottom: "2rem",
              borderRadius: 30,
              backgroundColor: "#fff",
              padding: "50px 50px",
              boxSizing: "border-box",
              textAlign:"center"
            }}
          >
            <img src={error404SVG} alt="Logo" />
            <h1 style={{ textAlign: "center", marginTop: 0 }}>
              Not Found
            </h1>

           
            <p style={{color:"#667085"}}>The resource requested not be found on this server !</p>
          </div>

          <Footer
            style={{
              backgroundColor: "transparent",
              textAlign: "center",
              zIndex: "100",
              bottom:"0",
              position: "fixed",
              paddingBottom :"40px"
            }}
          >
            <Flex gap="small" justify={"center"} align={"center"}>
              <span>
                Web Survey Copyright © 
              </span>
              <span style={{ color: "#FF7F07" }}>2024 G-Able Public Company Limited. All rights reserved.</span>
              
              {/* <span>|</span>
            <span style={{ color: "#FF7F07" }}>Terms And Conditions </span> */}
            </Flex>
          </Footer>
        </div>

      </Flex>
    </>
  );
};

export default Login;
