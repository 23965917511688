
import Icon from "@ant-design/icons";

const icon = () => (
    <svg width="32" height="35" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_6918_17457)">
<path d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5H24C28.4183 0.5 32 4.08172 32 8.5V24.5C32 28.9183 28.4183 32.5 24 32.5H8C3.58172 32.5 0 28.9183 0 24.5V8.5Z" fill="white"/>
<path d="M0.5 8.5C0.5 4.35786 3.85786 1 8 1H24C28.1421 1 31.5 4.35786 31.5 8.5V24.5C31.5 28.6421 28.1421 32 24 32H8C3.85786 32 0.5 28.6421 0.5 24.5V8.5Z" stroke="#98A2B3"/>
<path d="M7.69629 16.7687C7.63872 16.5959 7.63867 16.4089 7.69614 16.2362C8.85311 12.7581 12.134 10.25 16.0007 10.25C19.8656 10.25 23.1452 12.7558 24.3036 16.2313C24.3612 16.4041 24.3612 16.5911 24.3038 16.7638C23.1468 20.2419 19.8659 22.75 15.9992 22.75C12.1343 22.75 8.8547 20.2442 7.69629 16.7687Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.5 16.5C18.5 17.8807 17.3807 19 16 19C14.6193 19 13.5 17.8807 13.5 16.5C13.5 15.1193 14.6193 14 16 14C17.3807 14 18.5 15.1193 18.5 16.5Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<filter id="filter0_d_6918_17457" x="0" y="0.5" width="32" height="34" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.043 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6918_17457"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6918_17457" result="shape"/>
</filter>
</defs>
</svg>
    
    
);

const Icon_View = (props) => <Icon component={icon} {...props} />;

export default Icon_View;
