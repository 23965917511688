import Icon from "@ant-design/icons";

const icon = () => (
  

<svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 12.2627H6V10.2627H0V12.2627ZM0 5.2627V7.2627H12V5.2627H0ZM0 0.262695V2.2627H18V0.262695H0Z" fill="#1677FF"/>
</svg>



  


);

const Icon_Textarea_Selected = (props) => <Icon component={icon} {...props} />;

export default Icon_Textarea_Selected;

