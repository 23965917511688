import Icon from "@ant-design/icons";

const icon = () => (
  
<svg width="126" height="125" viewBox="0 0 126 125" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="8" y="7.5" width="110" height="110" rx="42" fill="#E3FFF0"/>
<rect x="8" y="7.5" width="110" height="110" rx="42" stroke="#F5FFFA" stroke-width="15"/>
<g clip-path="url(#clip0_1032_28031)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M38.625 62.5C38.625 49.0381 49.5381 38.125 63 38.125C76.4619 38.125 87.375 49.0381 87.375 62.5C87.375 75.9619 76.4619 86.875 63 86.875C49.5381 86.875 38.625 75.9619 38.625 62.5ZM72.0257 57.9648C72.6276 57.1222 72.4325 55.9511 71.5898 55.3493C70.7472 54.7474 69.5761 54.9425 68.9742 55.7852L60.8857 67.1091L56.8258 63.0492C56.0936 62.3169 54.9064 62.3169 54.1742 63.0492C53.4419 63.7814 53.4419 64.9686 54.1742 65.7008L59.7992 71.3258C60.1889 71.7156 60.7304 71.9141 61.2797 71.8686C61.8289 71.8232 62.3304 71.5383 62.6508 71.0898L72.0257 57.9648Z" fill="#1AC487"/>
</g>
<defs>
<clipPath id="clip0_1032_28031">
<rect width="60" height="60" fill="white" transform="translate(33 32.5)"/>
</clipPath>
</defs>
</svg>


);

const Icon_Success_Dialog = (props) => <Icon component={icon} {...props} />;

export default Icon_Success_Dialog;

