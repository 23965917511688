import Icon from "@ant-design/icons";

const icon = () => (
  

<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 4.4209C0 2.21176 1.79086 0.420898 4 0.420898H12C14.2091 0.420898 16 2.21176 16 4.4209V12.4209C16 14.63 14.2091 16.4209 12 16.4209H4C1.79086 16.4209 0 14.63 0 12.4209V4.4209Z" fill="#667085"/>
<path d="M7.10497 13.025L7.08735 13.0426L2.6875 8.64274L4.12018 7.21007L7.10502 10.1949L11.879 5.4209L13.3117 6.85358L7.12264 13.0426L7.10497 13.025Z" fill="white"/>
</svg>


  


);

const Icon_Checkbox_Unselect = (props) => <Icon component={icon} {...props} />;

export default Icon_Checkbox_Unselect;

