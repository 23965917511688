import Icon from "@ant-design/icons";

const icon = () => (
  
<svg width="126" height="126" viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="8" y="8" width="110" height="110" rx="42" fill="#FFE2E2"/>
<rect x="8" y="8" width="110" height="110" rx="42" stroke="#FFF7F7" stroke-width="15"/>
<g clip-path="url(#clip0_1126_14039)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M74.2503 44.1955V44.7624C76.6371 44.9809 79.0042 45.2683 81.3494 45.6224C82.2178 45.7536 83.0831 45.8939 83.9453 46.0432C84.9657 46.22 85.6495 47.1904 85.4728 48.2108C85.296 49.2311 84.3256 49.915 83.3053 49.7382C83.1313 49.7081 82.9571 49.6783 82.7829 49.6489L80.2693 82.3252C79.9687 86.2327 76.7104 89.25 72.7914 89.25H53.2092C49.2902 89.25 46.0319 86.2327 45.7313 82.3252L43.2178 49.6489C43.0435 49.6783 42.8693 49.7081 42.6953 49.7382C41.675 49.915 40.7046 49.2311 40.5278 48.2108C40.3511 47.1904 41.0349 46.22 42.0553 46.0432C42.9175 45.8939 43.7829 45.7536 44.6512 45.6224C46.9964 45.2683 49.3635 44.9809 51.7503 44.7624V44.1955C51.7503 40.2843 54.7816 36.9455 58.7889 36.8173C60.1873 36.7725 61.5912 36.75 63.0003 36.75C64.4094 36.75 65.8133 36.7725 67.2117 36.8173C71.219 36.9455 74.2503 40.2843 74.2503 44.1955ZM58.9088 40.5654C60.2672 40.5219 61.6312 40.5 63.0003 40.5C64.3694 40.5 65.7334 40.5219 67.0918 40.5654C68.9784 40.6257 70.5003 42.2098 70.5003 44.1955V44.4771C68.0194 44.3264 65.5187 44.25 63.0003 44.25C60.482 44.25 57.9812 44.3264 55.5003 44.4771V44.1955C55.5003 42.2098 57.0223 40.6257 58.9088 40.5654ZM58.0218 55.4279C57.982 54.3932 57.1109 53.5866 56.0761 53.6264C55.0414 53.6662 54.2348 54.5373 54.2746 55.5721L55.14 78.0721C55.1798 79.1068 56.0509 79.9134 57.0856 79.8736C58.1204 79.8338 58.927 78.9627 58.8872 77.9279L58.0218 55.4279ZM71.7237 55.5721C71.7635 54.5373 70.9569 53.6662 69.9222 53.6264C68.8874 53.5866 68.0163 54.3932 67.9765 55.4279L67.1111 77.9279C67.0713 78.9627 67.8779 79.8338 68.9127 79.8736C69.9474 79.9134 70.8185 79.1068 70.8583 78.0721L71.7237 55.5721Z" fill="#FF4D4F"/>
</g>
<defs>
<clipPath id="clip0_1126_14039">
<rect width="60" height="60" fill="white" transform="translate(33 33)"/>
</clipPath>
</defs>
</svg>




);

const Icon_Delete_Survey_Dialog = (props) => <Icon component={icon} {...props} />;

export default Icon_Delete_Survey_Dialog;

