import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
import {
  Table,
  Dropdown,
  Space,
  Button,
  Form,
  Input,
  Flex,
  Drawer,
  Row,
  Col,
  Select,
  message,
  InputNumber,
  Modal,
  Switch,
  notification,
} from "antd";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";

import UserListService from "../service/userListService";
import AuthService from "../service/authService";

import Swal from "sweetalert2";

const userListService = new UserListService();
const authService = new AuthService();

const UserProfile = () => {
  const navigate = useNavigate();
  const { Option } = Select;
  const history = useLocation();
  let userDetailId = history.pathname.replace("/user-detail/", "");

  console.log("navigate", history.pathname);
  console.log("navigate", userDetailId);

  const [form] = Form.useForm();

  const [userDetail, setUserDetail] = useState([]);
  const [changeSwitch, setChangeSwitch] = useState();
  const [statusChange, setStatusChange] = useState(true);

  // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [userInfo, setUserInfo] = useState();
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  console.log("789789", userDetail);

  // const onSearch = (value, _e, info) => {};
  // const getUserList = (value) => {
  //   userListService.getUserDetail(value).then((response) => {
  //     setUserDetail(response.data);
  //     setChangeSwitch(response.data.isActivated);
  //     setStatusChange(response.data.isActivated);
  //     form.setFieldsValue({
  //       firstName: response.data.firstName,
  //       lastName: response.data.lastName,
  //       email: response.data.email,
  //       tel: response.data.tel,
  //       roleId: response.data.roleId,
  //       isActivated:response.data.isActivated,
  //     });
  //     console.log("ddddd", response);
  //   });
  // };

  useEffect(() => {
    // form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.pathname]);

  useEffect(() => {
    getUserInfo();
  }, []);

  function getUserInfo() {
    let userId = localStorage.getItem("userId");
    userListService
      .getUserDetail(userId)
      .then((response) => {
        // console.log("loginValue", response);
        setUserInfo(response.data);
      })
      .catch(async (err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <label
        style={{
          fontSize: 20,
          fontWeight: 600,
          marginBottom: '20px',
          display: 'block',
          color: '#000000', // ใช้สีเริ่มต้นของเนื้อหา
          cursor: 'pointer' // Add cursor pointer for better UX
        }}
        onClick={() => navigate(-1)} // Go back to the previous page
        onMouseEnter={(e) => e.target.style.color = '#000000'} // เมื่อเม้าส์เข้าไป
        onMouseLeave={(e) => e.target.style.color = '#000000'} // เมื่อเม้าส์ออก
      >
        <ArrowLeftOutlined style={{ marginRight: '8px' }} /> My Profile
      </label>

      <div
        style={{
          backgroundColor: "#fff",
          padding: 15,
          marginBottom: 15,
          borderRadius: 10,
          minHeight: 580,
        }}
      >
        <Form form={form} layout="vertical">
          <div className="contact">
            <Row>
              <Col sm={18} md={18} lg={18}>
                <div
                  style={{
                    marginBottom: 16,
                    fontSize: 12,
                    color: "#0C0C0C",
                  }}
                >
                  {/* <label style={{ fontSize: 20, fontWeight: 600 }}>
                  My Profile
                  </label> */}
                </div>
              </Col>
              <Col sm={6} md={6} lg={6}>
                <div
                  style={{
                    position: "flex",
                    right: 15,
                    display: "flex",
                    marginBottom: 16,
                  }}
                >
                </div>
              </Col>
            </Row>

            <Row align="middle" gutter={24} className="px-8">
              <Col sm={18} md={18} lg={18}>
                <div
                  style={{
                    marginBottom: 16,
                    fontSize: 12,
                    color: "#0C0C0C",
                  }}
                >
                  <label style={{ fontSize: 20, fontWeight: 600, transform: 'translateX(5px)' }}>
                    My Profile
                  </label>
                </div>
              </Col>
            </Row>

            <Row align="middle" gutter={24} className="px-8">
              <Col className="gutter-row" span={8}>
                <div style={{ marginBottom: 5 }}>
                  <label style={{ fontWeight: 500 }}>
                    Name
                  </label>
                </div>
                <Form.Item
                  //  label="Name"
                  name="firstName"
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: "This information is required.",
                    },
                  ]}
                >
                  <label>{userInfo !== undefined ? userInfo.firstName : ""}</label>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <div style={{ marginBottom: 5 }}>
                  <label style={{ fontWeight: 500 }}>
                    Surname
                  </label>
                </div>
                <Form.Item
                  // label={"Surname"}
                  name="lastName"
                  colon={true}
                // rules={[
                //   {
                //     required: true,
                //     message: "This information is required.",
                //   },
                // ]}
                >
                  <label>{userInfo !== undefined ? userInfo.lastName : ""}</label>
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle" gutter={24} className="px-8">
              <Col className="gutter-row" span={8}>
                <div style={{ marginBottom: 5 }}>
                  <label style={{ fontWeight: 500 }}>
                    Email
                  </label>
                </div>
                <Form.Item
                  // label="Email"
                  name="email"
                  colon={false}
                // rules={[
                //   {
                //     required: true,
                //     message: "This information is required.",
                //   },
                // ]}
                >
                  <label>{userInfo !== undefined ? userInfo.email : ""}</label>
                </Form.Item>
              </Col>
              
              <Col className="gutter-row" span={8}>
                <div style={{ marginBottom: 5 }}>
                  <label style={{ fontWeight: 500 }}>
                  Role
                  </label>
                </div>
                <Form.Item
                  // label="Role"
                  name="roleId"
                  colon={false}
                  >
                 <label>{permissions[0].roleName}</label>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UserProfile;
