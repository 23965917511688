import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const UserApi = {
    get: async function (id, cancel = false) {
        const response = await api.request({
            url: `/users/${id}`,
            method: "GET",
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the product returned by the API
        return response.data
    },
    search: async function (params, cancel = false) {
        const response = await api.request({
            url: "/users",
            method: "GET",
            params: params,
            signal: cancel ? cancelApiObject[this.search.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    create: async function (data, cancel = false) {
        await api.request({
            url: `/users`,
            method: "POST",
            data: data,
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
    },
    update: async function (id, data, cancel = false) {
        await api.request({
            url: `/users/${id}`,
            method: "PUT",
            data: data,
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
    },
    delete: async function (id, cancel = false) {
        await api.request({
            url: `/users/${id}`,
            method: "DELETE",
            signal: cancel ? cancelApiObject[this.delete.name].handleRequestCancellation().signal : undefined,
        })
    },
}

// defining the cancel API object for UserApi
const cancelApiObject = defineCancelApiObject(UserApi)