import Icon from "@ant-design/icons";

const icon = () => (
  

<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8.4209" r="8" fill="#E6F7FF"/>
<path d="M0.5 8.4209C0.5 4.27876 3.85786 0.920898 8 0.920898C12.1421 0.920898 15.5 4.27876 15.5 8.4209C15.5 12.563 12.1421 15.9209 8 15.9209C3.85786 15.9209 0.5 12.563 0.5 8.4209Z" fill="white" stroke="#1677FF"/>
<rect x="4" y="4.4209" width="8" height="8" rx="4" fill="#1677FF"/>
</svg>


  


);

const Icon_Radio_Selected = (props) => <Icon component={icon} {...props} />;

export default Icon_Radio_Selected;

