import React, { useEffect, useState, useContext, createContext, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Table,
    Dropdown,
    Space,
    Button,
    Form,
    Input,
    Flex,
    Drawer,
    Row,
    Col,
    Select,
    message,
    InputNumber,
    Modal,
    Tooltip,
    Tag,
    Radio,
  } from "antd";
  import Swal from "sweetalert2";
import { FormOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { RadioChangeEvent } from "antd";
import IconEdit from "../assets/icon/icon_edit";
import IconSurveyFormSuccess from "../assets/icon/survey_form_success";
import IconSurveyFormTimeUp from "../assets/icon/survey_form_time_up";
import IconLogo from "../assets/icon/icon_logo";
import IconCustomer404 from "../assets/icon/icon_customer_404";
import SurveyTractionService from "../service/surveyTransactionService";
import Question from "./Question";
import CustomerSurveyFormService from "../service/customerSurveyFormService";
import AuthService from "../service/authService";

const surveyTractionService = new SurveyTractionService();
const customerSurveyFormService = new CustomerSurveyFormService();
const authService = new AuthService();
export const AppContext = createContext(null);
let submitIndex = 0;

const CustomerSurveyForm = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState(1);
    const { Search, TextArea } = Input;
    const history = useLocation();
    const [response, setResponse] = useState();
    let userDetailId = history.pathname.replace("/survey-form-transaction/", "");
    console.log("ID : ", userDetailId);
    let questionOption = {options:[]};
    const [questionState, setQuestionState] = useStateCallback(questionOption);
    const [submit, setSubmit] = useState(submitIndex);
    const [comment, setComment] = useState("");
    const [isDisabled, setIsDisabled] = useState(false);
    const [resultMode, setResultMode] = useState("show");//show

    // useEffect(() => {
        
    //     if(isSubmitClick && !isDisabled){
    //         console.log("send data");
    //     }
    //     setIsSubmitClick(false);
    //   }, [isDisabled, isSubmitClick]);


    function useStateCallback(initialState) {
        const [state, setState] = useState(initialState);
        const cbRef = useRef(null); // init mutable ref container for callbacks
      
        const setStateCallback = useCallback((state, cb) => {
          cbRef.current = cb; // store current, passed callback in ref
          setState(state);
        }, []); // keep object reference stable, exactly like `useState`
      
        useEffect(() => {
          // cb.current is `null` on initial render, 
          // so we only invoke callback on state *updates*
          if (cbRef.current) {
            cbRef.current(state);
            cbRef.current = null; // reset callback after execution
          }
        }, [state]);
      
        return [state, setStateCallback];
      }

    const onCommentTextChange = function(e){
        setComment(e.target.value);
    }

    const onSubmitClick = function(){
        // console.log("Submit data : ", questionState);
        if(submitIndex == 0){
            setSubmit(submitIndex++);
        }
        setSubmit(submitIndex++);
        // setIsSubmitClick(true);
        // setQuestionState({ ...questionState, options: nextState });
        
    }

    const onSendData = function(){
        const {options} = questionState
        let answerList = [];
        options.map((data) => {
            let answer = {};
            if(data.questionType == '2'){
                data.selected.map((selected) => {
                    if(data.isVariantValue){
                        answer = {surveyFormQuestionId:data.questionId, surveyFormAnswerId: selected, value:data.value, surveyFormQuestionHistoryId: data.surveyFormQuestionHistoryId, surveyFormAnswerHistoryId: data.surveyFormAnswerHistoryId};
                    }else{
                        answer = {surveyFormQuestionId:data.questionId, surveyFormAnswerId: selected, surveyFormQuestionHistoryId: data.surveyFormQuestionHistoryId, surveyFormAnswerHistoryId: data.surveyFormAnswerHistoryId};
                    }
                    answerList.push(answer);
                })
            }else{
                if(data.isVariantValue){
                    answer = {surveyFormQuestionId:data.questionId, surveyFormAnswerId: data.selected, value:data.value, surveyFormQuestionHistoryId: data.surveyFormQuestionHistoryId, surveyFormAnswerHistoryId: data.surveyFormAnswerHistoryId};
                }else if(data.questionType == '3'){
                    answer = {surveyFormQuestionId:data.questionId, value: data.selected};
                }else{
                    answer = {surveyFormQuestionId:data.questionId, surveyFormAnswerId: data.selected, surveyFormQuestionHistoryId: data.surveyFormQuestionHistoryId, surveyFormAnswerHistoryId: data.surveyFormAnswerHistoryId};
                }
                answerList.push(answer);
            }
        });
        let request = {id: userDetailId, additionalComment:comment, answers:answerList}
        console.log("Send data : ", request);
        customerSurveyFormService.saveCustomerSurveyForm(request).then((response) => {
            console.log("response", response);
            
            
            if (response.status === 201) {
                setResultMode("success");
              // onOk();
            //   Swal.fire({
            //     icon: "success",
            //     title: "Success !",
            //     text: "Successfully saved data.",
            //     showConfirmButton: false,
            //     timer: 1500,
            //   });
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed !",
                text: "Failed to save data.",
              });
            }
            // getUserList(searchValue, pageValue);
          })
          .catch(async (err) => {
            console.log("err", err);
            if (err?.response)
              if (err?.response?.status === 401) {
                if (await authService.refreshToken()) {
                  // return getRolePermission(access_token);
                }
              }
            // props.history.push(ROUTE.LOGIN);
          });
    }


    function isExist(dataList, id){
        if(dataList.length == 0){
            return false;
        }
        const existData = dataList.find((element) => element.questionId === id);
        if(existData == undefined){
            return false;
        }
        return true;
    }

    function fetchData(){
        customerSurveyFormService.getSurveyDetail(userDetailId).then((response) => {
            if(response.data.httpCode != undefined){
                if(response.data.errorCode === 'err003'){
                    setResultMode("answer");
                }else{
                    setResultMode("expire");
                }
            }else if(response.data != undefined){
            // setData(response.data.items);
            console.log(response);
            let { options } = questionState;
            if(response.data.surveyForm.questions.length > 0){
                response.data.surveyForm.questions.map((data) => {
                    if(!isExist(options, data.surveyFormQuestionId)){
                        options.push({
                            questionId: data.surveyFormQuestionId,
                            selected: "",
                            questionType: data.questionTypeId,
                            responseValidationNo: data.responseValidationNo,
                            responseValidationId: data.responseValidationId,
                            isValid: false,
                            value: "",
                            isVariantValue: false,
                          });
                    }
                });
                console.log("Question total : ",options);
                setQuestionState({options });
              }
            setResponse(response);
            try{
              let titleElement = document.getElementById("title");
              titleElement.innerHTML = response.data.surveyForm.title;
              let desElement = document.getElementById("description");
              desElement.innerHTML = response.data.surveyForm.description;
              console.log(response.data.surveyForm.title);
            }catch(err){

            }
           
          }
          // if (response.data.objectValue) setDataList(response.data.objectValue);
          // console.log("ddddd", response.data.objectValue);
        }).catch(async (err) => {
          console.log("err", err);
          if (err?.status === 404) {
            setResultMode("404");
          }
          // props.history.push(ROUTE.LOGIN);
        });
      }

      function readCustomerSurveyForm(){
        customerSurveyFormService.readCustomerSurveyForm(userDetailId).then((response) => {
            console.log(response);
        }).catch(async (err) => {
          console.log("err", err);
          if (err?.status === 404) {
            setResultMode("404");
          }
          // props.history.push(ROUTE.LOGIN);
        });
      }

      useEffect(() => {
        fetchData();
        readCustomerSurveyForm();
        //   }, [JSON.stringify(tableParams)]);
      }, []);

      let displayContent = "";
      let mainClass = "mx-auto flex flex-col items-center justify-center min-[768px]:max-[1024px]:h-screen lg:pt-[100px] lg:pb-[100px] w-full min-[1024px]:max-[1366px]:pt-[0px]";
      let contentClass = "min-[320px]:max-[600px]:h-[95vh]";
     if(resultMode === 'show'){
        mainClass = "mx-auto flex flex-col items-center justify-center lg:pt-[100px] lg:pb-[100px] w-full  min-[1024px]:max-[1366px]:pt-[0px]";
        //min-[768px]:max-[1024px]:h-screen
        contentClass = "";
        displayContent =  <div className="">
        <Row>
          <Col span={22} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div
              id="description"
              style={{
                fontWeight: 400,
                fontSize: 14,
                color: "#667085",
                marginTop:0,
              }}
              className="mt-0 ms-6 me-6"
            >
              {/* <label
                style={{
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#667085",
                }}
              >
                Thank you for choosing. Your feedback will help us
                improve our work and our service standard, so we
                would greatly appreciate you filling out this
                form. Thank you
              </label> */}
            </div>
          </Col>
          <Col span={2}>
            <div style={{ marginTop: 0 }}></div>
          </Col>
        </Row>
        <Row className="mt-0 ms-10 me-10">
          <Col span={24}>
            <div
              style={{
                width: "100%",
                position: "relative",
                // marginLeft: "-12px",
                margin: "10px 0px",
                borderBottom: "2px solid #ECF1F6",
              }}
            />
          </Col>
        </Row>
        {/* ----------------------- */}
        <Question
          response={response}
          isDetailMode={false}
          onSendData={onSendData}
          submitIndex={submit}
          isTouchable={false}
        />
  
        {/* ----------------------- */}
  
        <Row className="ms-6 me-6 mt-[20px] ">
          <Col span={24} style={{ marginLeft: 0, marginRight:0 }}>
            <div style={{ marginBottom: 10 }}>
              <label
                style={{ fontWeight: 500, color: "#1C2538", mariginTop: 0 }}
              >
                Additional Comments:
              </label>
            </div>
            <Form.Item
              //  label="Name"
              name="comments"
              colon={false}
              // rules={[
              //   {
              //     required: true,
              //     message: "This information is required.",
              //   },
              // ]}
            >
              <TextArea onChange={onCommentTextChange} />
            </Form.Item>
          </Col>
        </Row>
  
        <div className="flex flex-row justify-center mb-8">
          {/* <Button
            className="w-28"
            style={{
              marginRight: 12,
              backgroundColor: "#FFFFFF",
              color: "#344054",
              border: "1px solid #D9D9D9",
            }}
            onClick={() => {
              navigate("/survey-form-detail");
            }}
            // type="primary"
            shape="default"
          >
            Cancel
          </Button> */}
          <Button
            className="w-28 ms-6 me-6 min-[320px]:max-[600px]:w-full"
            style={{
              backgroundColor: "#FF7F07",
              color: "#FFFFFF",
              border: "2px solid #FF7F07",
            }}
            onClick={onSubmitClick}
            // type="primary"
            shape="default"
            disabled={isDisabled}
          >
            Submit
          </Button>
        </div>
      </div>
     }else if(resultMode === 'success'){
        displayContent = <div className="flex flex-col items-center ">
        <IconSurveyFormSuccess className="me-0 mt-4"/>
        <h1 className="mb-5 font-semibold text-4xl min-[320px]:max-[600px]:text-2xl	" style={{
                              color: "#1C2538",
                            }}>Thank you</h1>
                            <p className="ms-16 me-16 mb-20 font-medium text-[22px] min-[320px]:max-[600px]:text-lg" style={{
                              color: "#65707A",
                            }}>We have received all of your information. Thank you for your cooperation.</p> </div>
     }else if(resultMode === 'answer'){
        displayContent = <div className="flex flex-col items-center ">
        <IconSurveyFormSuccess className="me-0 mt-4"/>
        <h1 className="mb-5 ms-10 me-10 font-semibold text-4xl min-[320px]:max-[600px]:text-2xl" style={{
                              color: "#1C2538",
                            }}>You have already completed this survey</h1>
                            <p className="ms-10 me-10 mb-20 font-medium text-[22px] min-[320px]:max-[600px]:text-lg" style={{
                              color: "#65707A",
                            }}>Thank you for your cooperation.</p> </div>
     }else if(resultMode === '404'){
      displayContent = <div className="flex flex-col items-center">
      <IconCustomer404 className="me-0 mt-4"/>
      <h1 className="mb-5 ms-20 me-20 font-semibold text-4xl min-[320px]:max-[600px]:text-2xl" style={{
                            color: "#1C2538",
                          }}>Not Found</h1>
                          <p className="ms-20 me-20 mb-20 font-medium text-[22px] min-[320px]:max-[600px]:text-lg" style={{
                            color: "#65707A",
                          }}>"The resource requested not be found on this server !"</p> </div>

     }else{
        displayContent = <div className="flex flex-col items-center">
        <IconSurveyFormTimeUp className="me-0 mt-4"/>
        <h1 className="mb-5 ms-20 me-20 font-semibold text-4xl min-[320px]:max-[600px]:text-2xl" style={{
                              color: "#1C2538",
                            }}>Time's up</h1>
                            <p className="ms-20 me-20 mb-20 font-medium text-[22px] min-[320px]:max-[600px]:text-lg" style={{
                              color: "#65707A",
                            }}>The survey time has expired.</p>
       </div>
     }
     


    return (
      <AppContext.Provider
        value={{ questionState, setQuestionState, isDisabled, setIsDisabled }}
      >
        <div id="main" className={mainClass} style={{
                  backgroundColor: "#F8F8F9",
                  fontFamily: 'Noto Sans Thai' 
                }}>
          {/* #F8F8F8 */}

           {/* w-[50vh] */}
           <div
                className="lg:w-[1000px] "
                style={{
                  backgroundColor: "#fff",
                  marginBottom: 10,
                  marginLeft: 10,
                  marginRight: 10,
                  marginTop:15,
                  borderRadius: 10,
                  minHeight: 580,
                  border: "2px solid #D0D5DD",
                }}
              >
                <div style={{ padding: 0 }}>
                  <div
                  className={contentClass}
                  >
                    <Row>
                      <Col span={12} style={{ padding: "5px 15px" }}>
                        <div className="mt-8 ms-6">
                          {/* <IconLogo /> */}
                          <img width="80%" height="25%" src="https://samsunglife.co.th/wp-content/uploads/2023/07/Logo-Samsung-Life-Insurance-Eng-no-clear-space.png"></img>
                        </div>
                      </Col>
                      <Col span={12}></Col>
                    </Row>
                    <Row>
                      <Col span={24} style={{ padding: "5px 15px" }}>
                        <div id="title" className=" ms-6 me-6"
                            style={{
                              fontWeight: 700,
                              fontSize: 24,
                              color: "#1C2538",
                              marginTop: 0,
                            }}>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <div
                          style={{
                            width: "100%",
                            position: "relative",
                            margin: "10px 0px",
                            borderBottom: "2px solid #D0D0D0",
                          }}
                        />
                      </Col>
                    </Row>

                    {displayContent}
                  </div>
                </div>
              </div>
              <div className="mt-[80px] ms-10 me-10 mb-10">
              <p className="font-medium text-[14px] min-[320px]:max-[600px]:text-lg" style={{
                              color: "#667085",
                            }}>Web Survey Copyright © 2024 G-Able Public Company Limited. All rights reserved.</p>
              </div>
        </div>
      </AppContext.Provider>
    );
};

export default CustomerSurveyForm;