import environment from "../environment/environment";
import httpRequest from "./httpService";
import axios from "axios";

export default class CustomerSurveyFormService {
  url = environment.SERVICE_URL_EV;
  urldev = environment.API_CUSTOMER_SURVEY_FORM;


  readCustomerSurveyForm(id) {
    let request = {id:id};
    return httpRequest.patch(this.url + this.urldev+"/"+id+"/read",request);
  }

  saveCustomerSurveyForm(request) {
    return httpRequest.post(this.url + this.urldev+"/save",request);
  }

  getSurveyDetail(id) {
    return httpRequest.get(this.url + this.urldev+"/" + id);
  }
}
