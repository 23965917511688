/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Input, Button, Row, Col, Layout, Modal } from "antd";
import { CheckCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import IconSuccessDialog from "../assets/icon/icon_success_dialog";
import IconFailedDialog from "../assets/icon/icon_failed_dialog";
import AuthService from "../service/authService";
import UserListService from "../service/userListService";

const { Footer } = Layout;
const authService = new AuthService();

const EditPassword = () => {
  const userListService = new UserListService();
  const navigate = useNavigate();
  const [oldPass, setOldPass] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const [uperLow, setUperLow] = useState(false);
  const [checkNum, setCheckNum] = useState(false);
  const [passThai, setPassThai] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);

  let userDetailId = localStorage.getItem("userId");
  const location = useLocation();

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    const oldPasswordchange = location.search.replace("?", "");
    console.log("userDetailId", userDetailId);
    console.log("userDetailId", oldPasswordchange);
  }, []);

  const getUserInfo = () => {
    const userId = localStorage.getItem("userId");
    userListService
      .getUserDetail(userId)
      .then((response) => {
        setUserInfo(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const containsThai = (str) => /[ก-ฮ]/.test(str);
  const containsUppercase = (str) => /[A-Z]/.test(str);
  const containsLowercase = (str) => /[a-z]/.test(str);
  const containsNumeric = (num) => /[0-9]/.test(num);

  const onFinish = (values) => {
    if (values.newPassword !== conPassword) {
      Swal.fire({
        icon: "error",
        title: "Password mismatch",
        text: "New Password and Confirm Password do not match.",
      });
    } else {
      authService
        .changePassword(values, userDetailId, oldPass)
        .then((response) => {
          if (response.status === 204) {
            setIsModalSuccessOpen(true);
            localStorage.setItem("accessToken", JSON.stringify(""));
            localStorage.setItem("refreshToken", JSON.stringify(""));
          } else {
            setIsModalFailedOpen(true);
          }
        })
        .catch((error) => {
          setIsModalFailedOpen(true);
        });
    }
  };

  const onChangePassword = (e) => {
    const value = e.target.value;
    setUperLow(containsUppercase(value) && containsLowercase(value));
    setPassThai(!(containsUppercase(value) || containsLowercase(value)) ? false : !containsThai(value));
    setCheckNum(containsNumeric(value));
    setRePassword(value);
  };

  const onChangeConfirm = (e) => setConPassword(e.target.value);
  const onChangeOld = (e) => setOldPass(e.target.value);

  const closeModal = () => {
    setIsModalSuccessOpen(false);
    setIsModalFailedOpen(false);
  };

  useEffect(() => {
    let timeoutId;
    if (isModalSuccessOpen || isModalFailedOpen) {
      timeoutId = setTimeout(closeModal, 800);
    }
    return () => clearTimeout(timeoutId);
  }, [isModalSuccessOpen, isModalFailedOpen]);

  const handleBackClick = () => navigate(-1);

  const isButtonEnabled =
    rePassword === conPassword && passThai && rePassword.length > 7 && uperLow && checkNum;

  return (
    <>
      <Modal
        title=""
        open={isModalSuccessOpen}
        footer={null}
        closable={false}
        destroyOnClose={true}
        afterClose={() => {
          navigate({ pathname: `/login` }, { state: { updateDate: new Date() } });
        }}
        centered
      >
        <div className="flex flex-col justify-center items-center">
          <IconSuccessDialog />
          <p style={{ fontWeight: 600, fontSize: 22, marginTop: 12 }}>Success</p>
          <p style={{ fontWeight: 500, fontSize: 16, marginTop: 8 }}>
            Successfully saved data.
          </p>
        </div>
      </Modal>

      <Modal
        title=""
        open={isModalFailedOpen}
        footer={null}
        closable={false}
        destroyOnClose={true}
        centered
      >
        <div className="flex flex-col justify-center items-center">
          <IconFailedDialog />
          <p style={{ fontWeight: 600, fontSize: 22, marginTop: 12 }}>Failed</p>
          <p style={{ fontWeight: 500, fontSize: 16, marginTop: 8 }}>
            Something went wrong. Please try again.
          </p>
          <Button
            className="w-[150px] h-[38px] mt-[20px]"
            style={{
              marginRight: 10,
              backgroundColor: "#FF7F07",
              color: "#ffffff",
              border: "2px solid #FF7F07",
            }}
            onClick={() => setIsModalFailedOpen(false)}
          >
            Try Again
          </Button>
        </div>
      </Modal>

      <label
        style={{
          fontSize: 20,
          fontWeight: 600,
          marginBottom: "20px",
          display: "block",
          color: "#000000",
          cursor: "pointer",
        }}
        onClick={handleBackClick}
        onMouseEnter={(e) => (e.target.style.color = "#000000")}
        onMouseLeave={(e) => (e.target.style.color = "#000000")}
      >
        <ArrowLeftOutlined style={{ marginRight: "8px" }} /> Change Password
      </label>
      <div className="bg-white rounded-lg shadow-md p-6 flex flex-col">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Row>
            <Col sm={24} md={24} lg={24}>
              <div
                className="ml-2"
                style={{
                  textAlign: "center",
                  fontSize: 12,
                  color: "#0C0C0C",
                }}
              />
            </Col>
            <Col sm={24} md={24} lg={24}>
              <div
                style={{
                  width: 400,
                  height: 530,
                  margin: "auto",
                  backgroundColor: "#fff",
                }}
              >
                <Form
                  name="login-form"
                  onFinish={onFinish}
                  onFinishFailed={(errorInfo) => console.log("Failed:", errorInfo)}
                  autoComplete="off"
                  className="mt-5 mx-5"
                >
                  <div style={{ textAlign: "left", marginBottom: 20 }}>
                    <label style={{ fontSize: 28, fontWeight: 600 }}>
                      Change password
                    </label>
                  </div>
                  <div style={{ textAlign: "left", marginBottom: 20 }}>
                    <label
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: "#667085",
                      }}
                    >
                      Please create new password to access account. Your Email:{" "}
                      {userInfo?.email || ""}
                    </label>
                  </div>
                  <Row align="middle" gutter={24}>
                    <Col sm={24} md={24} lg={24} span={8}>
                      <div style={{ marginBottom: 5 }}>
                        <label style={{ fontWeight: 500 }}>
                          Old Password<span style={{ color: "red" }}> *</span>
                        </label>
                      </div>
                      <Form.Item
                        name="oldpassword"
                        rules={[
                          {
                            required: true,
                            message: "Please input your old password!",
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder="Enter Old Password"
                          onChange={onChangeOld}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={24} md={24} lg={24} span={8}>
                      <div style={{ marginBottom: 5 }}>
                        <label style={{ fontWeight: 500 }}>
                          New Password<span style={{ color: "red" }}> *</span>
                        </label>
                      </div>
                      <Form.Item
                        name="newPassword"
                        rules={[
                          {
                            required: true,
                            message: "Please input your new password!",
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder="Enter New Password"
                          onChange={onChangePassword}
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={24} md={24} lg={24} span={8}>
                      <div style={{ marginBottom: 5 }}>
                        <label style={{ fontWeight: 500 }}>
                          Confirm Password<span style={{ color: "red" }}> *</span>
                        </label>
                      </div>
                      <Form.Item
                        name="confirmPassword"
                        dependencies={["newPassword"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your new password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue("newPassword") === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("The two passwords that you entered do not match!")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          placeholder="Enter Confirm password"
                          onChange={onChangeConfirm}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div className="mb-0">
                    <Row>
                      <Col sm={24} md={24} lg={24}>
                        <div style={{ textAlign: "left", marginBottom: 0 }}>
                          <label
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              color: passThai ? "#12B76A" : "#667085",
                            }}
                          >
                            <CheckCircleOutlined /> English characters only.
                          </label>
                        </div>
                      </Col>
                      <Col sm={24} md={24} lg={24}>
                        <div style={{ textAlign: "left", marginBottom: 0 }}>
                          <label
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              color: rePassword.length > 7 ? "#12B76A" : "#667085",
                            }}
                          >
                            <CheckCircleOutlined /> Your password must be at
                            least 8 - 20 characters.
                          </label>
                        </div>
                      </Col>
                      <Col sm={24} md={24} lg={24}>
                        <div style={{ textAlign: "left", marginBottom: 0 }}>
                          <label
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              color: uperLow ? "#12B76A" : "#667085",
                            }}
                          >
                            <CheckCircleOutlined /> It consists of uppercase and
                            lowercase
                          </label>
                        </div>
                      </Col>
                      <Col sm={24} md={24} lg={24}>
                        <div style={{ textAlign: "left", marginBottom: 0 }}>
                          <label
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              color: checkNum ? "#12B76A" : "#667085",
                            }}
                          >
                            <CheckCircleOutlined /> Contains at least 1 number
                            [0-9]
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Form.Item>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        disabled={!isButtonEnabled}
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "95%",
                          borderRadius: 10.5,
                          border: "1px solid",
                          marginTop: 20,
                          backgroundColor: isButtonEnabled ? "#FF7F07" : "#F5F5F5",
                        }}
                      >
                        Change Password
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
        <Footer
          style={{
            textAlign: "center",
            backgroundColor: "#ffffff",
          }}
        >
          <label style={{ color: "#000000", fontSize: 16 }}>
            If you need further assistance contact our support team
          </label>{" "}
          <br />
          <label style={{ color: "#000000", fontSize: 16 }}>
            Email Name เบอร์โทร
          </label>
        </Footer>
      </div>
    </>
  );
};

export default EditPassword;
