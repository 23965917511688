import Icon from "@ant-design/icons";

const icon = () => (
  
<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="45" height="45" rx="12.8571" fill="#DCDEE3"/>
<path d="M30.1423 25.7406C30.1423 25.4129 30.0273 25.1364 29.7971 24.9111L23.5942 18.7769C23.3118 18.4902 23.0084 18.357 22.6423 18.357C22.2762 18.357 21.9624 18.4902 21.6905 18.7667L15.4875 24.9111C15.2574 25.1364 15.1423 25.4128 15.1423 25.7406C15.1423 26.396 15.6758 26.9285 16.3453 26.9285C16.68 26.9285 16.9833 26.7953 17.2344 26.5598L22.6528 21.1732L28.0607 26.5598C28.3013 26.7953 28.6047 26.9285 28.9394 26.9285C29.6089 26.9285 30.1423 26.396 30.1423 25.7406Z" fill="#ACAEB9"/>
</svg>





);

const Icon_Top_Order_Disable = (props) => <Icon component={icon} {...props} />;

export default Icon_Top_Order_Disable;

