import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route, Redirect } from "react-router-dom";
import Login from "./page/Login";
import Layout from "./page/Layout";
import UserList from "./page/UserList";

import UserDetail from "./page/UserDetail";
import PermissionList from "./page/PermissionList";
import PermissionDetail from "./page/PermissionDetail";
import ChangePassword from "./page/ChangePassword";
import SurveyFormList from "./page/SurveyFormList";
import SurveyFormDetail from "./page/SurveyFormDetail";
import SurveyTransactionList from "./page/SurveyTransactionList";

import CustomerSurveyForm from "./page/CustomerSurveyForm";
import SurveyTransactionDetail from "./page/SurveyTransactionDetail";
import IVRTransactionList from "./page/IVRTransactionList";
import SurveyFormHistoryList from "./page/SurveyFormHistoryList";
import UserProfile from "./page/UserProfile";
import ReportList from './page/Report';
import Dashboard from "./page/Dashboard/Dashboard";
import EditPassword from './page/EditPassword';
import SurveyFormHistoryDetail from "./page/SurveyFormHistoryDetail";
import ChangePasswordEmail from "./page/ChangePasswordEmail";
import Landing from "./page/Landing";

import Login1 from "./page/Login1";
import User1 from "./page/User";

import Error404 from "./page/Error404"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/change-password/:id" element={<ChangePassword />} />
        <Route path="/change-password" element={<ChangePasswordEmail />} />
        <Route
          path="/customer-survey-form/:id"
          element={<CustomerSurveyForm />}
        />

        <Route
          path="/survey-form-transaction/:id"
          element={<CustomerSurveyForm />}
        />

        <Route path="/login1" element={<Login1 />} />
        <Route path="user1" element={<User1 />} />
        <Route path="*" element={<Error404 />} />

        <Route path="/" element={<Layout />}>
          {/* <Route index element={<List />} /> */}
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="user-list" element={<UserList />} />
          <Route path="user-detail" element={<UserDetail />} />
          <Route path="user-detail/:id" element={<UserDetail />} />
          <Route path="user-profile" element={<UserProfile />} />
          <Route path="permission-list" element={<PermissionList />} />
          <Route path="permission-detail/:id" element={<PermissionDetail />} />
          <Route path="survey-form-list" element={<SurveyFormList />} />
          <Route path="survey-form-detail" element={<SurveyFormDetail />} />
          <Route
            path="survey-transaction-list"
            element={<SurveyTransactionList />}
          />
          <Route
            path="survey-transaction-detail"
            element={<SurveyTransactionDetail />}
          />
          <Route
            path="survey-form-customer"
            element={<SurveyTransactionList />}
          />
          <Route path="ivr-transaction-list" element={<IVRTransactionList />} />
          <Route path="survey-form-history-list" element={<SurveyFormHistoryList />} />
          <Route path="report-list" element={<ReportList />} />
          <Route path="/edit-password" element={<EditPassword />} />
          <Route path="survey-form-history-detail" element={<SurveyFormHistoryDetail />} />

          <Route path="/" element={<Landing />} />
          
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
