import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  message,
  Checkbox,
  Layout,
  Flex,
  Space
} from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import Logo from "../assets/img/logo.png";
import Background from "../assets/img/background.jpg";
import AuthService from "../service/authService";
import packageJson from "../../package.json";
import { Alert } from 'antd';
import { CloseCircleOutlined } from "@ant-design/icons";
import Swal from 'sweetalert2';
import "../Login.css";

const authService = new AuthService();

const { Footer } = Layout;

const Login = () => {
  const history = useNavigate();
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState("");

  localStorage.clear();

  const onFinish = async (values) => {
    // console.log("Received values:", values);
    authService
      .authLogin(values.username, values.password)
      .then((response) => {
        console.log("loginValue", response);
        localStorage.setItem(
          "accessToken",
          JSON.stringify(response.data.accessToken)
        );
        localStorage.setItem(
          "refreshToken",
          JSON.stringify(response.data.refreshToken)
        );
        localStorage.setItem(
          "userId",
          response.data.userInfo.userId
        );
        localStorage.setItem(
          "permissions",
          JSON.stringify(response.data.permissions)
        );

        if (response.data.userInfo.isTemporaryPassword === true) {
          history({
            pathname: `/change-password/${response.data.userInfo.userId}`,
            search: values.password,
          });
        } else {
          history({
            pathname: "/",
          });
        }
      })
      .catch(async (err) => {
        console.log(err);
        const errorMessage = err.data.message;
        if (errorMessage) {
          console.log(errorMessage);
          setErrorMessage(errorMessage);
        } else {
          console.log("data", errorMessage)
        }
      });


  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <img
        className="fixed"
        style={{ zIndex: "-1", width: "100%", height: "100%", display: "block" }}
        src={Background}
      />

      <Flex gap="middle" vertical justify={"center"} align={"center"}
        style={{
          width: "100%",
          height: "100vh",
        }}>

        <div>
          <div
            style={{
              textAlign: "center",
              marginBottom: "2rem"
            }}
          >
            <img src={Logo} alt="Logo" />
          </div>

          {errorMessage && (
            <Alert
              message={errorMessage}
              type="error"
              style={{ color: "#ff4d4f", width: 560, marginBottom: "2rem" }}
              showIcon
              icon={<CloseCircleOutlined />}
            />
          )}

          <div
            style={{
              width: 560,
              boxShadow: "0px 0px 15px #F3C9A3",
              marginBottom: "2rem",
              borderRadius: 30,
              backgroundColor: "#fff",
              padding: "75px 100px",
              boxSizing: "border-box"
            }}
          >

            <h1 style={{ textAlign: "center", marginTop: 0 }}>
              Sign in to your account
            </h1>

            <Form
              name="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              style={{ marginBottom: "2rem" }}
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email",
                  },
                  {
                    type: "email",
                    message: "This email address is incorrect formatted.",
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined />}
                  placeholder="Email"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                >
                  Sign in
                </Button>
              </Form.Item>
            </Form>
            <div style={{ textAlign: "center" }}>
              If you forget your password,<br />
              please contact the administrator.
            </div>
          </div>
        </div>
        <Footer
          style={{
            backgroundColor: "transparent",
            textAlign: "center",
            zIndex: "100",
            position: "fixed",
            bottom: "0"
          }}
        >
          Web Survey Copyright © <span style={{ color: "#FF7F07" }}>2024 G-Able Public Company Limited. All rights reserved.</span>
          {/* <span>|</span>
            <span style={{ color: "#FF7F07" }}>Terms And Conditions </span> */}
        </Footer>
      </Flex>
    </>
  );
};

export default Login;
