


import Icon from "@ant-design/icons";

const icon = () => (
  <svg width="241" height="236" viewBox="0 0 241 236" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_309_37283)">
<path d="M35 110.897C35 104.344 38.6573 98.339 44.4795 95.3322L112.798 60.0497C117.841 57.4456 123.832 57.4456 128.875 60.0497L197.194 95.3322C203.016 98.339 206.673 104.344 206.673 110.897V182.902C206.673 192.576 198.83 200.419 189.156 200.419H52.5177C42.8429 200.419 35 192.576 35 182.902V110.897Z" fill="url(#paint0_linear_309_37283)"/>
<g filter="url(#filter1_bi_309_37283)">
<rect x="51.6417" y="34" width="138.39" height="141.017" rx="8.75884" fill="white" fill-opacity="0.5"/>
<rect x="52.4304" y="34.7887" width="136.812" height="139.44" rx="7.97013" stroke="url(#paint1_linear_309_37283)" stroke-opacity="0.5" stroke-width="1.57742"/>
</g>
<path d="M114.928 110.227C113.438 110.229 111.987 109.748 110.795 108.854L110.721 108.798L95.1528 96.8788C94.4319 96.3264 93.8268 95.6374 93.3721 94.8511C92.9174 94.0648 92.622 93.1967 92.5028 92.2963C92.3836 91.3958 92.4429 90.4808 92.6774 89.6033C92.9119 88.7258 93.3169 87.903 93.8693 87.1821C94.4217 86.4611 95.1107 85.856 95.897 85.4013C96.6832 84.9466 97.5514 84.6512 98.4518 84.532C99.3522 84.4128 100.267 84.4722 101.145 84.7066C102.022 84.9411 102.845 85.3461 103.566 85.8985L113.65 93.6305L137.479 62.5536C138.032 61.8328 138.721 61.228 139.507 60.7735C140.294 60.3191 141.162 60.0241 142.062 59.9052C142.963 59.7863 143.878 59.846 144.755 60.0808C145.633 60.3156 146.455 60.7209 147.176 61.2736L147.028 61.4747L147.18 61.2765C148.634 62.3939 149.585 64.0425 149.825 65.8604C150.065 67.6784 149.574 69.5173 148.46 70.9737L120.431 107.528C119.783 108.37 118.949 109.051 117.995 109.52C117.041 109.988 115.991 110.23 114.928 110.227V110.227Z" fill="#1AC487"/>
{/* <mask id="mask0_309_37283" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="34" y="58" width="173" height="144">
<path d="M34.8671 111.288C34.8671 104.756 38.5015 98.7661 44.2956 95.7497L112.747 60.1136C117.817 57.4742 123.856 57.4742 128.926 60.1136L197.378 95.7497C203.172 98.7661 206.806 104.756 206.806 111.288V184.296C206.806 193.971 198.963 201.814 189.288 201.814H52.3848C42.71 201.814 34.8671 193.971 34.8671 184.296V111.288Z" fill="url(#paint2_linear_309_37283)"/>
</mask> */}
<g mask="url(#mask0_309_37283)">
<path d="M120.837 179.613L206.806 100.859V183.508C206.806 193.183 198.963 201.026 189.288 201.026H52.3847C42.71 201.026 34.8671 193.183 34.8671 183.508V100.859L120.837 179.613Z" fill="url(#paint3_linear_309_37283)"/>
<path d="M106.912 136.826L29.3461 200.971H206.806L129.24 136.826C122.761 131.468 113.391 131.468 106.912 136.826Z" fill="url(#paint4_linear_309_37283)"/>
</g>
<g filter="url(#filter2_f_309_37283)">
<path d="M109.606 147.49L53.7961 194.108H187.877L132.067 147.49C125.565 142.059 116.108 142.059 109.606 147.49Z" fill="url(#paint5_linear_309_37283)" fill-opacity="0.35"/>
</g>
<g filter="url(#filter3_f_309_37283)">
<path d="M79.8236 154.34L41.9655 116.814V185.432L79.8236 154.34Z" fill="url(#paint6_linear_309_37283)" fill-opacity="0.35"/>
</g>
<g filter="url(#filter4_f_309_37283)">
<path d="M162.638 157.494L200.496 119.969V188.587L162.638 157.494Z" fill="url(#paint7_linear_309_37283)" fill-opacity="0.35"/>
</g>
</g>
<defs>
<filter id="filter0_d_309_37283" x="0.867065" y="0" width="239.939" height="235.025" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="17"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.904362 0 0 0 0 0.935319 0 0 0 0 0.903731 0 0 0 1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_309_37283"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_309_37283" result="shape"/>
</filter>
<filter id="filter1_bi_309_37283" x="16.6064" y="-1.03536" width="208.46" height="211.088" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="17.5177"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_309_37283"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_309_37283" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-8.75884" dy="8.75884"/>
<feGaussianBlur stdDeviation="8.75884"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_309_37283"/>
</filter>
<filter id="filter2_f_309_37283" x="38.0219" y="127.642" width="165.629" height="82.2398" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="7.88711" result="effect1_foregroundBlur_309_37283"/>
</filter>
<filter id="filter3_f_309_37283" x="30.1348" y="104.984" width="61.5195" height="92.2785" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="5.91533" result="effect1_foregroundBlur_309_37283"/>
</filter>
<filter id="filter4_f_309_37283" x="150.808" y="108.138" width="61.5195" height="92.2785" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="5.91533" result="effect1_foregroundBlur_309_37283"/>
</filter>
<linearGradient id="paint0_linear_309_37283" x1="179.428" y1="60.9399" x2="68.8091" y2="166.672" gradientUnits="userSpaceOnUse">
<stop stop-color="#F3F3F3"/>
<stop offset="1" stop-color="#D3D3D3"/>
</linearGradient>
<linearGradient id="paint1_linear_309_37283" x1="51.6417" y1="34" x2="159.813" y2="142.172" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0.5"/>
</linearGradient>
<linearGradient id="paint2_linear_309_37283" x1="207.001" y1="98.5684" x2="29.7975" y2="192.973" gradientUnits="userSpaceOnUse">
<stop stop-color="#F2F2F2"/>
<stop offset="1" stop-color="#D0D0D0"/>
</linearGradient>
<linearGradient id="paint3_linear_309_37283" x1="34.6721" y1="100.841" x2="238.351" y2="218.896" gradientUnits="userSpaceOnUse">
<stop stop-color="#ECECEC"/>
<stop offset="1" stop-color="#CACACA"/>
</linearGradient>
<linearGradient id="paint4_linear_309_37283" x1="118.076" y1="133.555" x2="118.076" y2="233.131" gradientUnits="userSpaceOnUse">
<stop stop-color="#FBFBFB"/>
<stop offset="1" stop-color="#CACACA"/>
</linearGradient>
<linearGradient id="paint5_linear_309_37283" x1="120.556" y1="143.63" x2="120.556" y2="194.108" gradientUnits="userSpaceOnUse">
<stop stop-color="#D5D5D5"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint6_linear_309_37283" x1="41.9655" y1="116.814" x2="80.6123" y2="155.461" gradientUnits="userSpaceOnUse">
<stop stop-color="#C7C7C7"/>
<stop offset="1" stop-color="#242424" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint7_linear_309_37283" x1="200.496" y1="119.969" x2="161.85" y2="158.616" gradientUnits="userSpaceOnUse">
<stop stop-color="#C7C7C7"/>
<stop offset="1" stop-color="#242424" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

);

const Icon_Survey_form_success = (props) => <Icon component={icon} {...props} />;

export default Icon_Survey_form_success;

