import axios from "axios"
import environment from "../../environment/environment";
import { notification } from "antd"

export const api = axios.create({
  baseURL: environment.SERVICE_URL,
});

// defining a custom error handler for all APIs
const errorHandler = async (error) => {
  const statusCode = error.response?.status

  if (error.code === "ERR_CANCELED") {
    notification.error({
      placement: "bottomRight",
      description: "API canceled!",
    })
    return Promise.resolve()
  }

  const originalRequest = error.config;

  // If the error status is 401 and there is no originalRequest._retry flag,
  // it means the token has expired and we need to refresh it
  if (statusCode === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    try {
      const response = await axios.post(`${environment.SERVICE_URL}/auth/refresh-token1`, { refreshToken: localStorage.getItem('refreshToken') });
      const { accessToken, refreshToken, userInfo, permissions } = response.data;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      localStorage.setItem('permissions', JSON.stringify(permissions));

      // Retry the original request with the new token
      originalRequest.headers.Authorization = `Bearer ${accessToken}`;
      return axios(originalRequest);
    } catch (error) {
      // Handle refresh token error or redirect to login
      localStorage.clear();
      window.location.href = "/login1";
    }
  }

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error)
  }

  return Promise.reject(error)
}

api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, async (error) => {
  return await errorHandler(error)
});