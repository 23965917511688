import Icon from "@ant-design/icons";

const icon = () => (
  

<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 4.8418C0 2.63266 1.79086 0.841797 4 0.841797H12C14.2091 0.841797 16 2.63266 16 4.8418V12.8418C16 15.0509 14.2091 16.8418 12 16.8418H4C1.79086 16.8418 0 15.0509 0 12.8418V4.8418Z" fill="#1677FF"/>
<path d="M7.10497 13.4459L7.08735 13.4635L2.6875 9.06364L4.12018 7.63097L7.10502 10.6158L11.879 5.8418L13.3117 7.27447L7.12264 13.4635L7.10497 13.4459Z" fill="white"/>
</svg>



  


);

const Icon_Checkbox_Selected = (props) => <Icon component={icon} {...props} />;

export default Icon_Checkbox_Selected;

