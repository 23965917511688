import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Table,
  Button,
  Form,
  Input,
  Row,
  Col,
  Select,
  message,
  Tooltip,
  Pagination,
} from "antd";
import SurveyTractionService from "../service/surveyTransactionService";
import {
  SearchOutlined,
  SyncOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import IconEdit from "../assets/icon/icon_edit";
import IconUnlock from "../assets/icon/icon_unlock";
import IconView from "../assets/icon/view_icon";
import { useHistory } from "react-router";
// import { LearningPlaceApi } from "../apis/LearningPlaceApi";
// import { RefCampusApi } from "../apis/RefCampusApi";
// import { RefUnivApi } from "../apis/RefUnivApi";
// import { RefPlaceStatusApi } from "../apis/RefPlaceStatusApi";
// import { RefProvinceApi } from "../apis/RefProvinceApi";

const surveyTractionService = new SurveyTractionService();

let currentPage = 1;
let pageSize = 10;
let sortBy = "";
let sortDirection = "";
let total = 0;

const SurveyTransactionList = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useNavigate();
  const [pageValue, setPageValue] = useState({
    page: currentPage,
    pageSize: pageSize,
    total: 0,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const testData = [
    {
      id: 1,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 2,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 3,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 4,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 5,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 6,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 6,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 7,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 8,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 9,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 10,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
    {
      id: 11,
      fullName: "Admin Admin",
      mobileNo: "09999999999",
      email: "Admin@gmail.com",
      role: "Editor",
      status: true,
    },
  ];

  // let newData = testData[0];
  // newData.id = 4;
  // // testData.push(newData)
  // // console.log(testData);

  const columns = [
    {
      title: "No",
      // dataIndex: "id",
      width: '5%',
      //render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
      render: (_, __, index) => (pageValue.page - 1) * pageValue.pageSize + index + 1,
    },
    {
      title: "Agent ID",
      dataIndex: "agentId",
      width: '10%',
      sorter: true,
    },
    {
      title: "Agent Name",
      width: '10%',
      dataIndex: "agentFullName",
      sorter: true,
    },
    {
      title: "Customer Name",
      width: '13%',
      dataIndex: "customerFullName",
      sorter: true,
    },
    {
      title: "Channel",
      width: '10%',
      dataIndex: "surveyChannelName",
      sorter: true,
    },
    {
      title: "Name",
      width: '10%',
      dataIndex: "surveyFormName",
      sorter: true,
    },
    {
      title: "Version",
      width: '5%',
      dataIndex: "version",
      sorter: true,
    },
    {
      title: "Send Date",
      width: '10%',
      dataIndex: "createdDateDisplay",
      sorter: true,
    },
    {
      title: "Read Date",
      width: '10%',
      dataIndex: "readDateDisplay",
      sorter: true,
      render: (text, record, index) => record.readDateDisplay ?? '-'
    },
    {
      title: "Answer Date",
      width: '10%',
      dataIndex: "answeredDateDisplay",
      sorter: true,
      render: (text, record, index) => record.answeredDateDisplay ?? '-'
    },


    {
      title: "Action",
      width: '10%',
      render: (text, record) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {/* {role[0].roleKey.includes("web-admin") && ( */}
          <Tooltip placement="bottom" title="Detail">
            <IconView
              style={{ marginRight: 10 }}
              onClick={() => {
                history(
                  {
                    pathname: `/survey-transaction-detail`,

                    // pagedata: pageValue,
                  },
                  { state: { id: record.id } }
                );
              }}
              twoToneColor="#98A2B3"
              onMouseEnter={() => {
                // alert("I am an alert box!");
              }}
            />
          </Tooltip>
          {/* )} */}
        </span>
      ),
    },
  ];

  const { Option } = Select;

  const query = useQuery();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [Keywords, setKeywords] = useState("");

  const { Search, TextArea } = Input;

  const onSearch = (value, _e, info) => {
    const pageValues = {
      page: currentPage,
      pageSize: pageSize,
      sortBy: sortBy,
      sortDirection: sortDirection,
      total: total,
      keywords: Keywords,
    };
    setPageValue(pageValues);
    fetchData(pageValues);
  };

  const handleSearchChange = (event) => {
    setKeywords(event.target.value);
  };

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState();
  const [LearningPlace, setLearningPlace] = useState({});

  const [disableForm, setDisableForm] = useState();

  //   const [LearningPlaceCreateRequest, setLearningPlaceCreateRequest] = useState({
  //     id: null,
  //     projectId: query.get("projectId"),
  //   });

  const [refCampus, setRefCampus] = useState();
  const [refUniv, setRefUniv] = useState();
  const [refProvince, setRefProvince] = useState();
  const [refPlaceStatus, setRefPlaceStatus] = useState();

  // const [filterCampus, setFilterCampus] = useState([]);
  // const [disabledCampus, setDisableCampus] = useState(true);

  const [data, setData] = useState();
  const [dataList, setDataList] = useState();

  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: pageSize,
      position: ['bottomCenter'],
      total: 100,
      showSizeChanger: true,
    },
  });

  function fetchData(pageValue) {
    setLoading(true);
    surveyTractionService.getSurveyList(pageValue).then((response) => {
      setLoading(false);
      if (response.data != undefined) {
        total = response.data.pagination.totalCount;
        setTableParams({
          pagination: {
            current: currentPage,
            pageSize: pageSize,
            position: ['bottomCenter'],
            total: total,
            showSizeChanger: true,
          },
        });
        setData(response.data.items);
      }
      // if (response.data.objectValue) setDataList(response.data.objectValue);
      // console.log("ddddd", response.data.objectValue);
    }).catch(async (err) => {
      console.log("err", err);
    });
  }

  const getDropdownConnector = (value) => {
    // userListService.getUserList().then((response) => {
    //   if (response.data.objectValue) setDataList(response.data.objectValue);
    //   console.log("ddddd",response.data.objectValue);
    // });
  };

  function useQuery() {
    const { search } = useLocation();

    // return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  useEffect(() => {
    fetchData(pageValue);
    //   }, [JSON.stringify(tableParams)]);
  }, []);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    currentPage = pagination.current;
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  function capitalize(str) {
    if (str == undefined) {
      return;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const onPageChange = async (pagination, filters, sorter) => {
    let sortBy = capitalize(sorter.field);
    if ("createdDateDisplay" === sorter.field) {
      sortBy = "CreatedDate";
    }
    if ("agentFullName" === sorter.field) {
      sortBy = "AgentName";
    }
    if ("customerFullName" === sorter.field) {
      sortBy = "CustomerName";
    }
    if ("surveyChannelName" === sorter.field) {
      sortBy = "ChannelName";
    }
    if ("readDateDisplay" === sorter.field) {
      sortBy = "ReadDate";
    }
    if ("answeredDateDisplay" === sorter.field) {
      sortBy = "AnsweredDate";
    }

    currentPage = pagination.current;
    pageSize = pagination.pageSize;
    const pageValues = {
      keywords: Keywords,
      page: pagination.current,
      pageSize: pagination.pageSize,
      sortBy: sortBy,
      sortDirection:
        sorter && sorter.order
          ? sorter.order.substring(0, 3).toUpperCase() === "ASC"
            ? "asc"
            : "desc"
          : "asc",
      total: pageValue.total,
    };
    setPageValue(pageValues);
    fetchData(pageValues);
  };


  const handleSortBy = async (pagination, filters, sorter) => {
    let sortBy = capitalize(sorter.field);
    if ("createdDateDisplay" === sorter.field) {
      sortBy = "CreatedDate";
    }
    if ("agentFullName" === sorter.field) {
      sortBy = "AgentName";
    }
    if ("customerFullName" === sorter.field) {
      sortBy = "CustomerName";
    }
    if ("surveyChannelName" === sorter.field) {
      sortBy = "ChannelName";
    }
    if ("readDateDisplay" === sorter.field) {
      sortBy = "ReadDate";
    }
    if ("answeredDateDisplay" === sorter.field) {
      sortBy = "AnsweredDate";
    }
    const pageValues = {
      keywords: Keywords,
      page: pageValue.page,
      pageSize: pageValue.pageSize,
      sortBy: sortBy,
      sortDirection:
        sorter && sorter.order
          ? sorter.order.substring(0, 3).toUpperCase() === "ASC"
            ? "asc"
            : "desc"
          : "asc",
      total: pageValue.total,
    };
    setPageValue(pageValues);
    fetchData(pageValues);
  }

  const handlePageChange = async (page, pageSize) => {
    const pageValues = {
      keywords: Keywords,
      page: page,
      pageSize: pageSize,
      sortBy: pageValue.sortBy,
      sortDirection:pageValue.sortDirection,
      total: pageValue.total,
    };
    setPageValue(pageValues);
    fetchData(pageValues);
  };

  const itemRender = (_, type, originalElement) => {
    const { page, total, pageSize } = pageValue;
    const totalPages = Math.ceil(tableParams.pagination?.total / pageSize);
    if (type === "prev") {
      if (page === 1) {
        return (
          <a style={{ pointerEvents: "none" }}>
            <LeftOutlined
              style={{
                color: "#d9d9d9",
              }}
            />
          </a>
        );
      }
      return (
        <a>
          <LeftOutlined
            style={{
              color: "#FF7F07",
            }}
          />
        </a>
      );
    }
    if (type === "next") {
      if (page === totalPages) {
        return (
          <a style={{ pointerEvents: "none" }}>
            <RightOutlined
              style={{
                // fontSize: "",
                color: "#d9d9d9",
              }}
            />
          </a>
        );
      }
      return (
        <a>
          <RightOutlined
            style={{
              // fontSize: "",
              color: "#FF7F07",
            }}
          />
        </a>
      );
    }
    return originalElement;
  };

  const handlePageSizeChange = (current, pageSize) => {
    const pageValues = {
      keywords: Keywords,
      page: 1,
      pageSize: pageSize,
      sortBy: pageValue.sortBy,
      sortDirection:pageValue.sortDirection,
      total: pageValue.total,
    };
    setPageValue(pageValues);
    fetchData(pageValues);
  };

  return (
    <>
      {contextHolder}
      <label style={{ fontSize: 24, fontWeight: 700 }}>
        Survey Form Transaction
      </label>
      <div className="bg-white rounded-lg pt-4 pl-4 pr-4 mb-3 mt-10">


        <Form form={form} layout="vertical">
          <div className="flex">
            <div className="flex-1 me-5">
              <Form.Item label="Search" name="chargerId" colon={false}>
                <Input size="large"
                  className=""
                  placeholder="Search Agent ID, Agent Name, Customer name, Channel, Title, Version..."
                  onChange={evt => handleSearchChange(evt)}
                  suffix={<SearchOutlined />}
                />
                {/* <Search placeholder="Search ID, Title,Customer name, Mobile No., Email..." onSearch={onSearch} /> */}
              </Form.Item>

            </div>
            <div className="mt-12 mb-0 ">
              <Button
                icon={<SyncOutlined />}
                onClick={() => {
                  form.resetFields();
                  const searchValues = {
                    chargerId: "",
                    landlordId: [],
                    merchantId: [],
                  };
                  currentPage = 1;
                  const pageValues = {
                    keywords: "",
                    page: currentPage,
                    pageSize: pageSize,
                    sortBy: "",
                    sortDirection: "",
                  };
                  fetchData(pageValues);

                  // setSearchValue(searchValues);
                  // getLogCharging(searchValues, pageValues);
                }}
              >
                Clear
              </Button>
            </div>
            <div className="mt-12 ml-4">
              <Button
                size="large"
                onClick={onSearch}
                type="primary"
                shape="default"
                htmlType="submit"
                icon={<SearchOutlined />}
              >
                Search
              </Button>
            </div>
          </div>
        </Form>

      </div>

      <div className="bg-white rounded-lg p-4 mb-3 mt-5 pt-5">
        <div>
          <Row>
            <Col span={20} style={{ marginRight: 0 }}>
              <label style={{ fontSize: 20, fontWeight: 700 }}>
                Survey Form Transaction
              </label>
            </Col>
            <Col span={4} style={{ marginRight: 0 }}>
              {/* <Flex justify="flex-end" style={{ marginRight: 0 }}>
                <Button
                  className="w-28"
                  style={{
                    marginRight: 10,
                    backgroundColor: "#FF7F07",
                    color: "#ffffff",
                    border: "2px solid #FF7F07",
                  }}
                  onClick={onSearch}
                  // type="primary"
                  shape="default"
                  htmlType="submit"
                  icon={<PlusOutlined />}
                >
                  Add User
                </Button>
              </Flex> */}
            </Col>
          </Row>
        </div>

        <Table
          className="mt-8"
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={data}
          //pagination={tableParams.pagination}
          pagination={false}
          loading={loading}
          onChange={(pagination, filters, sorter) => handleSortBy(pagination, filters, sorter, pagination.current)}
          scroll={{
            x: 1024,
          }}
        />
        {data &&data.length !== 0 ? (
          <Row justify="space-between" align="middle" style={{ marginTop: 16 }}>
            <Col>
              <span
                style={{
                  color: "#667085",
                }}
              >
                <b>Total : {tableParams.pagination?.total} items</b>
              </span>
            </Col>
            <Col>
              <Pagination
                style={{ pointerEvents: loading ? "none" : "auto" }}
                current={pageValue?.page}
                pageSize={pageValue?.pageSize}
                total={tableParams.pagination?.total}
                onChange={handlePageChange}
                itemRender={itemRender}
              />
            </Col>
            <Col>
              <Select
                size="middle"
                value={pageValue.pageSize}
                onChange={(pageSize) =>
                  handlePageSizeChange(
                    tableParams.pagination?.current,
                    pageSize
                  )
                }
                style={{
                  width: "130px",
                }}
              >
                <Option value={10}>10 / page</Option>
                <Option value={25}>25 / page</Option>
                <Option value={50}>50 / page</Option>
                <Option value={100}>100 / page</Option>
              </Select>
            </Col>
          </Row>
        ) : null}

      </div>

    </>
  );
};

export default SurveyTransactionList;
