




import Icon from "@ant-design/icons";

const icon = () => (
    <svg width="208" height="208" viewBox="0 0 208 208" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_309_37297)">
    <circle cx="104" cy="104" r="69.8871" fill="#F69A9D"/>
    </g>
    <circle cx="104" cy="104" r="77.5" fill="#FFF9F9" stroke="white" stroke-width="5"/>
    {/* <mask id="mask0_309_37297" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="28" y="28" width="152" height="152">
    <circle cx="104" cy="104" r="75.3047" fill="white"/>
    </mask> */}
    <g mask="url(#mask0_309_37297)">
    <path d="M66.0767 148.965L112.943 189.975C152.965 185.268 184 151.229 184 109.917C184 109.051 183.984 108.186 183.953 107.335L142.373 72.4311L141.255 71.4392L140.17 71.7226L137.966 69.9121L132.301 64.2915L127.736 70.778L88.6118 74.651L76.7925 64.2285L64.265 79.2167L69.254 105.667L86.5029 119.222L82.2536 136.462L66.0767 148.965Z" fill="url(#paint0_linear_309_37297)"/>
    </g>
    <path d="M159.53 103.865C159.53 134.609 134.608 159.531 103.865 159.531C73.1211 159.531 48.1986 134.609 48.1986 103.865C48.1986 73.1217 73.1211 48.1992 103.865 48.1992C134.608 48.1992 159.53 73.1217 159.53 103.865Z" fill="white"/>
    <path d="M102.42 68.9216V106.032H130.253M159.53 103.865C159.53 134.609 134.608 159.531 103.865 159.531C73.1211 159.531 48.1986 134.609 48.1986 103.865C48.1986 73.1217 73.1211 48.1992 103.865 48.1992C134.608 48.1992 159.53 73.1217 159.53 103.865Z" stroke="#FF7875" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
    <filter id="filter0_f_309_37297" x="0.112915" y="0.113281" width="207.774" height="207.773" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="17" result="effect1_foregroundBlur_309_37297"/>
    </filter>
    <linearGradient id="paint0_linear_309_37297" x1="124.132" y1="64.2285" x2="124.132" y2="189.975" gradientUnits="userSpaceOnUse">
    <stop stop-color="#F74E4A"/>
    <stop offset="1" stop-color="#FF7875" stop-opacity="0"/>
    </linearGradient>
    </defs>
    </svg>

);

const Icon_Survey_form_time_up = (props) => <Icon component={icon} {...props} />;

export default Icon_Survey_form_time_up;

