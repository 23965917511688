import Icon from "@ant-design/icons";

const icon = () => (
  
<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="44" height="44" rx="12.3571" fill="white"/>
<rect x="0.5" y="0.5" width="44" height="44" rx="12.3571" stroke="#FF7F07"/>
<path d="M30.1423 19.2594C30.1423 19.5871 30.0273 19.8636 29.7971 20.0889L23.5942 26.2231C23.3118 26.5098 23.0084 26.643 22.6423 26.643C22.2762 26.643 21.9624 26.5098 21.6905 26.2333L15.4875 20.0889C15.2574 19.8636 15.1423 19.5872 15.1423 19.2594C15.1423 18.604 15.6758 18.0715 16.3453 18.0715C16.68 18.0715 16.9833 18.2047 17.2344 18.4402L22.6528 23.8268L28.0607 18.4402C28.3013 18.2047 28.6047 18.0715 28.9394 18.0715C29.6089 18.0715 30.1423 18.604 30.1423 19.2594Z" fill="#FF7F07"/>
</svg>






);

const Icon_Bottom_Order = (props) => <Icon component={icon} {...props} />;

export default Icon_Bottom_Order;

