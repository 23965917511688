import React, { useEffect, useState, createContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Table,
  Dropdown,
  Space,
  Button,
  Form,
  Input,
  Flex,
  Drawer,
  Row,
  Col,
  Select,
  message,
  InputNumber,
  Modal,
  Tooltip,
  Tag,
  Radio,
  Menu,
} from "antd";
import { FormOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { RadioChangeEvent } from "antd";
import IconEdit from "../assets/icon/icon_edit";
import IconLogo from "../assets/icon/icon_logo";
import IconData from "../assets/icon/icon_no_data";
import IconMore from "../assets/icon/icon_more";
import IconEditSurvey from "../assets/icon/icon_edit_survey";
import IconDeleteSurvey from "../assets/icon/icon_delete_survey_dialog";
import IconSuccessDialog from "../assets/icon/icon_success_dialog";
import IconFailedDialog from "../assets/icon/icon_failed_dialog";
import IconHistory from "../assets/icon/icon_history";
import SurveyFormService from "../service/surveyFormService";
import AuthService from "../service/authService";
import Question from "./Question";
import Swal from "sweetalert2";
import Utills from "./Utills";

const surveyFormService = new SurveyFormService();
const authService = new AuthService();
const utills = new Utills();
export const SurveyFormListAppContext = createContext(null);

let currentPage = 1;
const pageSize = 4;
let sortBy = "";
let sortDirection = "";
let total = 0;

const SurveyFormList = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [pageValue, setPageValue] = useState({
    page: currentPage,
    pageSize: pageSize,
    total: 0,
  });
  const [responseList, setResponseList] = useState();
  const [responseDetail, setResponseDetail] = useState();
  const [currentItem, setCurrentItem] = useState();
  let questionOption = {options:[]};
  const [questionState, setQuestionState] = useState(questionOption);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [currentDelete , setCurrentDelete] = useState();
  const [isDisabledAddForm , setIsDisabledAddForm] = useState(false);
  const passData = useLocation();
  console.log(passData);

  const [messageApi, contextHolder] = message.useMessage();

  const [Keywords, setKeywords] = useState("");


  // const [filterCampus, setFilterCampus] = useState([]);
  // const [disabledCampus, setDisableCampus] = useState(true);

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 4,
    },
  });

  function fetchData(pageValue){
    setLoading(true);
    surveyFormService.getSurveyList(pageValue).then((response) => {
      setLoading(false);
      if(response.data != undefined){
        setResponseList(response);
        total = response.data.pagination.totalCount;
        console.log(response);
        // setTableParams({
        //   pagination: {
        //     current: currentPage,
        //     pageSize: pageSize,
        //     position: ['bottomCenter'],
        //     total: total,
        //     showSizeChanger: true,
        //   },
        // });
        // setData(response.data.items);
      }
    }).catch(async (err) => {
      console.log("err", err);
      if (err?.response)
        if (err?.response?.status === 401) {
          if (await authService.refreshToken()) {
            // return getRolePermission(access_token);
          }
        }
      // props.history.push(ROUTE.LOGIN);
    });
  }

  function getDetailData(id){
    surveyFormService.getSurveyDetail(id).then((response) => {
      if(response.data != undefined){
        // setData(response.data.items);
        console.log("Detail : ",response);
        setResponseDetail(response);

        // initData(response);
      }
      // if (response.data.objectValue) setDataList(response.data.objectValue);
      // console.log("ddddd", response.data.objectValue);
    }).catch(async (err) => {
      // Swal.close();
      console.log("err", err);
      // setIsModalFailedOpen(true);
    });
  }

  function deleteSurveyService(){
    surveyFormService.delteData(currentDelete).then((response) => {
      Swal.close();
      if(response.data != undefined){
        // setResponseList(response);
        console.log("Delete response ", response);
        setIsModalSuccessOpen(true);
      }
    }).catch(async (err) => {
      Swal.close();
      console.log("err", err);
      setIsModalFailedOpen(true);
    });
  }

  function getChannel(){
    surveyFormService.getChannel().then((response) => {
      if(response.data != undefined){
        // setResponseList(response);
        console.log(response);
        let option = [];
        response.data.map((item) => {
          if(!item.isExisted){
            option.push({label:item.name, value:item.id});
          }
        })
        if(option.length > 0){
          setIsDisabledAddForm(false);
        }else{
          setIsDisabledAddForm(true);
        }
        // setChannelOption(option);
      }
    }).catch(async (err) => {
      console.log("err", err);
      // if (err?.response)
      //   if (err?.response?.status === 401) {
      //     if (await authService.refreshToken()) {
      //     }
      //   }
    });
  }

  function getDisplayHtmlTag(html, id){
    let titleElement = document.getElementById(id);
    if(titleElement !== null){
        titleElement.innerHTML = html;
    }
  }

  function onDelete(){
    Swal.fire({
      title: 'Please Wait !',
      html: 'data uploading',// add html attribute if you want or remove
      allowOutsideClick: false,
      showConfirmButton: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading()
        deleteSurveyService();
      }
  });
  }

  const handleOnLeftMenuClick = function(data){
     setCurrentItem(data);
     getDetailData(data.id);
  }


  useEffect(() => {
    fetchData(pageValue);
    getChannel();
    //   }, [JSON.stringify(tableParams)]);
  }, [passData]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };
  const [value, setValue] = useState(1);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  let mainContent = "";
  let detailContent = "";
  if(responseList === undefined){
    return;
  }
  
  // if(false){
  if(responseList.data.items.length > 0){
    //Check active
    if(currentItem === undefined){
      detailContent = (
        <div span={18}>
          <div
            className="h-screen flex flex-col items-center justify-center "
            style={{
              backgroundColor: "#fff",
              padding: "15px 0px 8px 0px",
              marginLeft: 15,
              marginBottom: 0,
              borderRadius: 10,
              //   minHeight:580,
            }}
          >
            <IconData className="" />
            <p className="font-semibold text-[20px]">No data</p>
          </div>
        </div>
      ); 
      
    }else{
      if(responseDetail !== undefined){
        let channelName = "";
      channelName = utills.getChannelName(responseDetail.data.surveyForm.channels);
      detailContent = (
        <div
          className=""
          style={{
            backgroundColor: "#fff",
            padding: "0px 0px 0px 0px",
            marginBottom: 0,
            marginLeft: 15,
            borderRadius: 10,
            minHeight: 580,
          }}
        >
          <div className="" style={{ padding: 15 }}>
            <Row>
              <Col span={12}>
                <div style={{ marginBottom: 5 }}>
                  <label
                    style={{ fontWeight: 500, fontSize: 16, color: "#667085" }}
                  >
                    Survey Channel
                  </label>
                </div>
                <Form.Item
                  //  label="Name"
                  name="name"
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: "This information is required.",
                    },
                  ]}
                >
                  <label
                    style={{ fontWeight: 600, fontSize: 16, color: "#1C2538" }}
                  >
                    {channelName}
                  </label>
                </Form.Item>
              </Col>
              <Col span={12}>
                <div style={{ marginBottom: 5 }}>
                  <label
                    style={{ fontWeight: 500, fontSize: 16, color: "#667085" }}
                  >
                    By, Updated Date
                  </label>
                </div>
                <Form.Item
                  //  label="Name"
                  name="name"
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: "This information is required.",
                    },
                  ]}
                >
                  <label
                    style={{ fontWeight: 600, fontSize: 16, color: "#1C2538" }}
                  >
                    {responseDetail.data.surveyForm.modifiedBy + ", " + responseDetail.data.surveyForm.modifiedDateDisplay}
                  </label>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <div
                style={{
                  width: "100%",
                  margin: 0,
                  // paddingTop: 23,
                  borderBottom: "1px solid #D0D0D0",
                }}
              />
            </Row>

            <Row style={{ marginTop: 20 }}>
              <div className="w-full flex flex-row items-end justify-end">
                <label className="me-[10px] mb-[8px]" style={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#65707A",
                    }}>Version : {responseDetail.data.surveyForm.version}</label>
                <IconHistory onClick={() => {
                  navigate({
                    pathname: `/survey-form-history-list`,

                    // pagedata: pageValue,
                  },
                  { state: { id: responseDetail.data.surveyForm.id } })
                }}/>
              </div>
              {/* <Col span={6}>
                <Flex justify="flex-end" style={{ marginRight: 0 }}>
                  <label>Version : {responseDetail.data.version}</label>
                </Flex>
              </Col> */}
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col span={12}>
                <div style={{ marginTop: 12 }}>
                  <label
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#1C2538",
                    }}
                  >
                    ดู Survey form
                  </label>
                </div>
              </Col>
              <Col span={12}>
                <Flex justify="flex-end" style={{ marginRight: 0 }}>
                  <Button
                    icon={<IconEditSurvey  />}
                    size="large"
                    className="primary-outline"
                    onClick={() => {
                      // navigate("/survey-form-detail");
                      navigate({
                        pathname: `/survey-form-detail`,
                        // pagedata: pageValue,
                      },
                      { state: { id: responseDetail.data.surveyForm.id} })
                    }}
                  > Edit</Button>
                </Flex>
              </Col>
            </Row>
            <Row>

            <div
              className="flex w-full justify-start pointer-events-none"
              style={{
                backgroundColor: "#fff",
                padding: "15px 0px 8px 0px",
                border: "1px solid #D0D5DD",
                marginBottom: 0,
                marginLeft: 5,
                marginRight: 5,
                marginTop: 15,
                borderRadius: 10,
                minHeight: 580,
              }}
            >
              {/* #F8F8F8 */}

              <div className="w-full">
                <Row>
                  <Col span={12} style={{ padding: "5px 15px" }}>
                    <div className="mt-3 ms-3">
                      {/* <IconLogo /> */}
                      <img width="50%" height="25%" src="https://samsunglife.co.th/wp-content/uploads/2023/07/Logo-Samsung-Life-Insurance-Eng-no-clear-space.png"></img>
                    </div>
                  </Col>
                  <Col span={12}></Col>
                </Row>
                <Row>
                  <Col span={24} style={{ paddingLeft: "15px" }}>
                    <div id={"title"+responseDetail.data.surveyForm.id} className="mt-0 ms-3 me-3" style={{ marginTop: 0,  fontWeight: 700,
                          fontSize: 24,
                          color: "#1C2538", } 
                        } dangerouslySetInnerHTML={{__html: responseDetail.data.surveyForm.title}} >
                            {/* // {getDisplayHtmlTag(responseDetail.data.surveyForm.title, "title"+responseDetail.data.surveyForm.id)} */}
                      {/* <label
                        className="mt-8 ms-3"
                        style={{
                          fontWeight: 700,
                          fontSize: 24,
                          color: "#1C2538",
                        }}
                      >
                        Call Center Feedback Survey 
                      </label> */}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                        // marginLeft: "-12px",
                        // margin: "10px 0px",
                        borderBottom: "2px solid #D0D0D0",
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={22} style={{ paddingLeft: "15px" }}>
                    <div id={"des"+responseDetail.data.surveyForm.id} style={{ marginTop: 0,  fontWeight: 400,
                          fontSize: 14,
                          color: "#667085", }} className="mt-4 ms-4 me-3" dangerouslySetInnerHTML={{__html: responseDetail.data.surveyForm.description}}>
                      {/* <label
                        style={{
                          fontWeight: 400,
                          fontSize: 14,
                          color: "#667085",
                        }}
                      >
                        Thank you for choosing. Your feedback will help us
                        improve our work and our service standard, so we would
                        greatly appreciate you filling out this form. Thank you
                      </label> */}
                       {/* {getDisplayHtmlTag(responseDetail.data.surveyForm.description, "des"+responseDetail.data.surveyForm.id)} */}
                    </div>
                  </Col>
                  <Col span={2}>
                    <div style={{ marginTop: 0 }}></div>
                  </Col>
                </Row>
                <Row className="mt-0 ms-7 me-7">
                  <Col span={24}>
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                        // marginLeft: "-12px",
                        // margin: "10px 0px",
                        borderBottom: "2px solid #ECF1F6",
                      }}
                    />
                  </Col>
                </Row>
                {/* ----------------------- */}
                <Question response={responseDetail} isDetailMode={true} isTouchable={false} page="surveyFormList" />
                {/* ----------------------- */}

                {/* <Row className="ms-3 me-3">
                  <Col span={24} style={{ padding: 15 }}>
                    <div style={{ marginBottom: 0 }}>
                      <label style={{ fontWeight: 500, color: "#1C2538" }}>
                        Additional Comments:
                      </label>
                    </div>
                    <TextArea
                      className="mt-2"
                      defaultValue={comment}
                      value={comment}
                    />
                  </Col>
                </Row> */}

             
              </div>
            </div>
            </Row>
            

          </div>
        </div>
      );
      }
      
    }
    
    mainContent = (
      <Row className="">
        <Col span={6} className="h-screen hover:overflow-y-auto">
          <div
            className="h-screen"
            style={{
              backgroundColor: "#fff",
              padding: "15px 0px 8px 0px",
              marginBottom: 0,
              borderRadius: 10,
              //   minHeight:580,
            }}
          >
            <div style={{ marginLeft: 25 }}>
              <label
                style={{ fontSize: 12, fontWeight: 600, color: "#65707A" }}
              >
                Total {responseList.data.items.length} items
              </label>
            </div>

            {/* item left */}
            {responseList.data.items.map((dataListItem) => {
              let borderStyle = null;
              let backgroundColor = null;
              let colorStatus = null;
              if(currentItem === dataListItem){
                borderStyle = "5px solid #FF7F07";
                backgroundColor = "#FFFAF5";
              }else{
                borderStyle = null;
                backgroundColor = null;
              }
              if(dataListItem.surveyFormStatusName === "Publish"){
                colorStatus = "#4ACE8F";
              }else{
                colorStatus = "#9A9A9A";
              }
              return (
                <div onClick={() => handleOnLeftMenuClick(dataListItem)}>
                  <div
                    style={{
                      width: "100%",
                      position: "absolute",
                      // paddingTop: 23,
                      borderTop: "1px solid #ECEDED",
                    }}
                  />
                  <div
                    className=""
                    style={{
                      borderLeft: borderStyle,
                      borderRadius: 5,
                      backgroundColor: backgroundColor,
                      margin: 8,
                    }}
                  >
                    <div className="flex flex-col">
                      <div className="flex flex-row">
                        <span
                          className="w-[70%]"
                          style={{
                            fontWeight: 600,
                            fontSize: 14,
                            marginBottom: 8,
                            marginTop: 8,
                            marginLeft: 8,
                            marginRight: 8,
                            color: "#FF7F07",
                          }}
                        >
                          {dataListItem.name}
                        </span>
                        {/* overlay={menu}  */}
                        <Dropdown id={dataListItem.id} placement="bottomRight" overlay={
                          <Menu style={{ marginTop: "-25px",backgroundColor: "#F8F8F8", }}>

                          <Menu.Item
                            // key="11"
                            // eslint-disable-next-line no-restricted-globals
                            onClick={()=>{
                              //TODO delete
                              setIsModalOpen(true);
                              setCurrentDelete(dataListItem.id);
                            }
                              
                              
                              // navigate("/login")
                            }
                            // disabled={listMember.length > 0 ? false : true}
                          >
                            {/* <Link to="#/" disabled={true}></Link> */}
                            Delete
                            {/* <Button onClick={() => ExportPDFHandler()}>Tax Invoice</Button> */}
                          </Menu.Item>
                        </Menu>
                        }>
                          <IconMore className="flex-1 justify-end me-[8px]" />
                        </Dropdown>

                        {/* <span>Test</span> */}
                      </div>

                      <div className="flex flex-row">
                        <div className="w-[70%]">
                          <p
                            className="w-fit"
                            style={{
                              borderLeft: "1px solid "+colorStatus,
                              borderTop: "1px solid "+colorStatus,
                              borderBottom: "1px solid "+colorStatus,
                              borderRight: "1px solid "+colorStatus,
                              borderRadius: 20,
                              padding: "4px 10px 4px 10px",
                              gap: "10px",
                              fontWeight: 500,
                              fontSize: 10,
                              marginTop: 8,
                              marginLeft: 8,
                              marginRight: 8,
                              marginBottom: 12,
                              color: colorStatus,
                            }}
                          >
                            {dataListItem.surveyFormStatusName}
                          </p>
                        </div>

                        <p className="flex-1 text-end me-[10px] mt-[8px]">
                          {"Ver. "+dataListItem.version}
                        </p>
                        {/* <span>Test</span> */}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      position: "absolute",
                      // paddingTop: 23,
                      borderBottom: "1px solid #ECEDED",
                    }}
                  />
                </div>
              );
            })}

            {/* item left */}
          </div>
        </Col>

        <Col span={18} className="h-screen overflow-y-hidden hover:overflow-y-auto">
          {detailContent}
        </Col>
      </Row>
    );
  }else{
    mainContent = <div
        className="h-screen flex flex-col items-center justify-center"
        style={{
          backgroundColor: "#fff",
          padding: "15px 0px 8px 0px",
          marginBottom: 0,
          borderRadius: 10,
          //   minHeight:580,
        }}
      >
        <IconData className="" />
        <p className="font-semibold text-[20px]">No data</p>
      </div> 
  }

  return (
    <SurveyFormListAppContext.Provider value={{ questionState, setQuestionState }}>
      <div style={{ backgroundColor: "#F8F8F8" }}>
        {contextHolder}

        <Modal title="" open={isModalOpen} footer={null} closable={false} centered>
        <div className="flex flex-col justify-center items-center">
          <IconDeleteSurvey/>
          <p style={{
                    fontWeight: 600,
                    fontSize: 22,
                    marginTop: 12,
                  }}>Confirm Delete</p>
                  <p style={{
                    fontWeight: 500,
                    fontSize: 16,
                    marginTop: 8,
                    color:"#475467"
                  }}>Are you sure to delete ?</p>
                  <div className="flex flex-row items-center justify-center mt-[20px]">
                  <Button
                  className="w-[150px] h-[38px]"
                  //loading={loadingExcel}
                  // icon={<FileExcelOutlined />}
                  type="primary"
                  shape="round"
                  style={{
                    marginRight: 15,
                    borderRadius: 10.5,
                    border: "1px solid #D9D9D9",
                    //marginTop: 20,
                    color: "#000",
                    backgroundColor: "#FFF",
                  }}
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="w-[150px] h-[38px]"
                  style={{
                    marginRight: 10,
                    backgroundColor: "#FF4D4F",
                    color: "#ffffff",
                    border: "2px solid #FF4D4F",
                  }}
                  onClick={() => {
                    setIsModalOpen(false);
                    onDelete();
                    // navigate("/survey-form-list");
                  }}
                  // type="primary"
                >
                  Delete
                </Button>
                  </div>
        </div>
      </Modal>

      <Modal centered title="" open={isModalSuccessOpen} footer={null} closable={false} destroyOnClose={true} onCancel={()=>{setIsModalSuccessOpen(false);}} afterClose={e => {
      
      navigate({
        pathname: `/survey-form-list`,

        // pagedata: pageValue,
      },
      { state: { updateDate: new Date() } })
   }}
>
      <div className="flex flex-col justify-center items-center">
        <IconSuccessDialog/>
        <p style={{
                  fontWeight: 600,
                  fontSize: 22,
                  marginTop: 12,
                }}>Success</p>
                <p style={{
                  fontWeight: 500,
                  fontSize: 16,
                  marginTop: 8,
                }}>Successfully saved data.</p>
      </div>
    </Modal>

    <Modal centered title="" open={isModalFailedOpen} footer={null} closable={false} destroyOnClose={true} onCancel={()=>{setIsModalFailedOpen(false);}} onClose={e => {
      e.stopPropagation();
      setIsModalFailedOpen(false);
   }}
>
      <div className="flex flex-col justify-center items-center">
        <IconFailedDialog/>
        <p style={{
                  fontWeight: 600,
                  fontSize: 22,
                  marginTop: 12,
                }}>Failed</p>
                <p style={{
                  fontWeight: 500,
                  fontSize: 16,
                  marginTop: 8,
                }}>Something went wrong. Please try again.</p>
                 <Button
                className="w-[150px] h-[38px] mt-[20px]"
                style={{
                  marginRight: 10,
                  backgroundColor: "#FF7F07",
                  color: "#ffffff",
                  border: "2px solid #FF7F07",
                }}
                onClick={() => {
                  setIsModalFailedOpen(false);
                  onDelete();
                  // navigate("/survey-form-list");
                }}
                // type="primary"
              >
                Try Again
              </Button>
      </div>
    </Modal>

        <div className="bg-white rounded-lg p-5 mb-3">
          <Row>
            <Col span={20}>
                <label style={{ fontSize: 24, fontWeight: 700 }}>
                  Survey Form
                </label>
            </Col>
            <Col span={4}>
              <Flex justify="flex-end" >
                <Button disabled={isDisabledAddForm}
                  onClick={() => {
                    navigate("/survey-form-detail");
                  }}
                  type="primary"
                  shape="default"
                  htmlType="submit"
                  size="large"
                  icon={<PlusOutlined />}
                > Add Form </Button>
              </Flex>
            </Col>
          </Row>
        </div>

        {mainContent}
      </div>
    </SurveyFormListAppContext.Provider>
  );
};

export default SurveyFormList;
