import axios from "axios";
import environment from "../environment/environment";
//import ROUTE from "../constants/routes";
//import moment from "moment";

export default class AuthService {
  url = environment.SERVICE_URL_EV;
  urlEv = environment.SERVICE_URL_EV;

  authLogin(username, password) {
    var formData = { username: username, password: password };
    // console.log("00000", formData);
    // console.log("11111", username);
    // console.log("22222", password);
    return axios.post(this.url + environment.API_AUTH_LOGIN, formData);
  }

  loginWeb(token) {
    var formData = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    return axios.get(this.urlEv + environment.API_LOGIN_WEB, formData);
  }

  async refreshToken() {
    let token = JSON.parse(localStorage.getItem("refreshToken"));
    let headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      try {
        const res = await axios.post(
          this.url + environment.API_AUTH_REFRESH,
          { refreshToken: token },
          { headers }
        );
        localStorage.setItem(
          "accessToken",
          JSON.stringify(res.data.accessToken)
        );
        localStorage.setItem(
          "refreshToken",
          JSON.stringify(res.data.refreshToken)
        );
        localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
        localStorage.setItem(
          "permissions",
          JSON.stringify(res.data.permissions)
        );
        //localStorage.setItem("oldExpireTime", JSON.stringify(date));
        return true;
      } catch (err) {
        return false;
      }
    }
  }

  logout(history) {
    let temp1 = localStorage.getItem("username");
    let temp2 = localStorage.getItem("password");
    let temp3 = localStorage.getItem("Remember");
    localStorage.clear();
    localStorage.setItem("username", temp1);
    localStorage.setItem("password", temp2);
    localStorage.setItem("Remember", temp3);
    // history.push(ROUTE.LOGIN);
  }

  isLogin = () => {
    const accessToken = localStorage.getItem("currentUser");
    const userLogin = JSON.parse(localStorage.getItem("userLogin"));
    // console.log("userLogin::::: :: ", userLogin[0]);
    return accessToken && userLogin !== null ? true : false;
  };

  isTermPolicy = () => {
    let user = JSON.parse(localStorage.getItem("userLogin"));
    let accepted = false;

    // console.log("user : ", user);
    if (
      user !== null &&
      (!user[0]?.flagTermsConditions || !user[0]?.flagPolicy)
    ) {
      accepted = false;
    } else {
      accepted = true;
    }

    return accepted;
  };

  getRolePermission(token) {
    var formData = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    return axios.get(this.urlEv + environment.API_ROLE_PERMISSION, formData);
  }

  forgotPassword(body) {
    let formData = {
      requestObject: {
        ...body,
      },
    };
    return axios.post(this.url + environment.API_FORGOT_PASSWORD, formData);
  }

  resetPassword(body) {
    let formData = {
      requestObject: {
        token: body.token,
        newPassword: body.newPassword,
        confirmNewPassword: body.confirmPassword,
      },
    };
    return axios.post(this.url + environment.API_RESET_PASSWORD, formData);
  }

  getEmail(body) {
    return axios.get(this.url + environment.API_EMAIL + body);
  }

  changePassword(body, id, oldPassword) {
    console.log("body", body);
    let formData = {
      id: id,
      password: oldPassword,
      newPassword: body.newPassword,
    };

    console.log("change pass", formData);
    return axios.patch(
      this.url + environment.API_AUTH_CHANGE_PASSWORD + "/" + id + "/profile/change-password",formData
    );
  }

  changePasswordEmail(body, token) {
    let formData = {
      newPassword: body.newPassword,
      userResetPasswordToken: token
    };
    console.log("change pass", formData);
    return axios.patch(
      this.url + environment.API_AUTH_CHANGE_PASSWORD + "/change-password",formData
    );
  }
  
  userResetPasswordToken(token) {
    let formData = {
      token: token,
    };
    console.log("validate", formData);
    return axios.post(this.url + "/user-reset-password-tokens/validate",formData);
    //return axios.post("https://localhost:7285/user-reset-password-tokens/validate",formData);
  }

  changeNameSurname(body) {
    let formData = {
      requestObject: {
        userId: body.userId,
        firstName: body.userName,
        surName: body.surName,
      },
      headers: {
        Authorization: "Bearer " + body.token,
        "Content-Type": "application/json",
      },
    };
    return axios.post(this.url + environment.API_NAME_SURNAME, formData);
  }

  getTermConditionPolicy() {
    return axios.get(this.url + environment.API_TERM_CONDITION_PRIVACY_POLICY);
  }

  acceptTermConditionPolicy(body) {
    let formData = {
      requestObject: {
        ...body,
      },
    };

    // console.log("acceptTermConditionPolicy : ", formData);
    return axios.put(
      this.url + environment.API_TERM_CONDITION_PRIVACY_POLICY,
      formData
    );
  }
}
