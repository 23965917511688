/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Save from "../assets/img/save.png";
import {
  Table,
  Dropdown,
  Space,
  Button,
  Form,
  Input,
  Flex,
  Drawer,
  Row,
  Col,
  Select,
  message,
  InputNumber,
  Modal,
  Switch,
  notification,
  Tooltip,
} from "antd";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
import PermissionService from "../service/permissionService";
import AuthService from "../service/authService";
import Swal from "sweetalert2";
import IconConfirmSubmit from "../assets/icon/icon_confirm_submit";
import IconSuccessDialog from "../assets/icon/icon_success_dialog";
import IconFailedDialog from "../assets/icon/icon_failed_dialog";

const permissionService = new PermissionService();
const authService = new AuthService();

const PermissionDetail = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const { Option } = Select;
  const history = useLocation();
  let permissionId = history.pathname.replace("/permission-detail/", "");

  // const query = useQuery();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);

  const [Keywords, setKeywords] = useState("");

  const { Search, TextArea } = Input;

  const onSearch = (value, _e, info) => {
    // console.log(info?.source, value);
    // fetchData();
  };

  // const [filterCampus, setFilterCampus] = useState([]);
  // const [disabledCampus, setDisableCampus] = useState(true);

  const [data, setData] = useState();
  const [permissionDetail, setPermissionDetail] = useState();
  console.log("permissionDetail", permissionDetail);

  const columns = [
    {
      title: "Menu",
      dataIndex: "pageName",
    },
    {
      title: "Visibilities",
      dataIndex: "isActivated",
      render: (e, record) => (
        <>
         <Tooltip placement="bottom" title={e ? "Active" : "Inactive"}>
          <Switch
            style={{ backgroundColor: e === true ? "#1890FF" : "#d9d9d9" }}
            // checkedChildren="Open"
            // unCheckedChildren="Close"
            // checked={e === "0" ? true : false}
            checked={record.isActivated}
            onChange={(value) => {
              handleMarkComplete(record.id);
            }}
          />
          </Tooltip>
        </>
      ),
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const getPermissionDetail = () => {
    permissionService.getPermissionDetail(permissionId).then((response) => {
      var permissions = response.data.permissions.filter(i=> i.pageId !== '7')
      setData(permissions);
      setPermissionDetail(response.data);
      // setChangeSwitch(response.data.isActivated);
      // setStatusChange(response.data.isActivated);
      // form.setFieldsValue({
      //   firstName: response.data.firstName,
      //   lastName: response.data.lastName,
      //   email: response.data.email,
      //   tel: response.data.tel,
      //   roleId: response.data.roleId,
      //   isActivated:response.data.isActivated,
      // });
      console.log("ddddd", response);
    });
  };

  const onSubmit = async () => {
        await permissionService.updatePermission(permissionDetail, data)
          .then(async (res) => {
            if (res.status === 204) {
              let userId = localStorage.getItem("userId");
              const permissionNew = await permissionService.getPermissionForuserId(userId);
              localStorage.setItem("permissions", JSON.stringify(permissionNew.data.permissions));
              setIsModalOpen(false)
              setIsModalSuccessOpen(true)
            } else {
              setIsModalFailedOpen(true)
              form.resetFields();
              // navigate("/permission-list");
            }
          })
          .catch(async (err) => {
            if (err.response && err.response.status === 401) {
              if (await authService.refreshToken()) {
                return save();
              }
            }
            notification["error"]({
              message: "Something went wrong !",
              description: "Please re-login or contact web admin.",
            });
            navigate("/permission-list");
          });
    }
  

    const closeModal = () => {
      setIsModalSuccessOpen(false);
    };
       useEffect(() => {
        let timeoutId;
        if (isModalSuccessOpen) {
            timeoutId = setTimeout(closeModal, 800);
        }
        return () => clearTimeout(timeoutId);
    }, [isModalSuccessOpen]);


  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const save = async () => {
    setIsModalOpen(true)
  };
  
  
  console.log("todos", data);
  useEffect(() => {
    console.log("todos11111");
    getPermissionDetail();
  }, []);

  const handleMarkComplete = (id) => {
    const newTodos = data.map((todo) => {
      if (todo.id === id) {
        return { ...todo, isActivated: !todo.isActivated };
      }
      return todo;
    });

    setData(newTodos);
  };

  return (
    <>

    {/* Modal Confirm Save */}
<Modal title="" open={isModalOpen} footer={null} closable={false} centered>
        <div className="flex flex-col justify-center items-center">
        <IconConfirmSubmit />
          <p style={{ fontWeight: 600, fontSize: 22, marginTop: 12 }}>
            Confirm Save
          </p>
          <p style={{ fontSize: 15, marginTop: -10 }}>Do you want to Save data ?</p>
         
          <div className="flex flex-row items-center justify-center mt-[20px]">
            <Button
              className="w-[150px] h-[38px]"
              type="primary"
              shape="round"
              style={{
                marginRight: 15,
                borderRadius: 10.5,
                border: "1px solid #D9D9D9",
                color: "#000",
                backgroundColor: "#FFF",
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="w-[150px] h-[38px]"
              style={{
                marginRight: 10,
                backgroundColor: "#FF7F07",
                color: "#ffffff",
                border: "2px solid #FF7F07",
              }}
              onClick={onSubmit}
            >
              Save
            </Button>
          </div>
        </div>
</Modal>

{/* Modal After Save - Success */}
<Modal
    title=""
    open={isModalSuccessOpen} 
    footer={null}
    closable={false}
    destroyOnClose={true}
    afterClose={() => {
        navigate(
            {
                pathname: `/permission-list`,
            },
            { state: { updateDate: new Date() } }
        );
    }}
    centered
>
    <div className="flex flex-col justify-center items-center">
        <IconSuccessDialog />
        <p
            style={{
                fontWeight: 600,
                fontSize: 22,
                marginTop: 12,
            }}
        >
            Success
        </p>
        <p
            style={{
                fontWeight: 500,
                fontSize: 16,
                marginTop: 8,
            }}
        >
            Successfully saved data.
        </p>
    </div>
</Modal>

{/* Modal After Save - Failed */}
<Modal
    title=""
    open={isModalFailedOpen} 
    footer={null}
    closable={false}
    destroyOnClose={true}
    afterClose={() => {
        navigate(
            {
                pathname: `/user-list`,
            },
            { state: { updateDate: new Date() } }
        );
    }}
    centered
      >
        <div className="flex flex-col justify-center items-center">
          <IconFailedDialog />
          <p
            style={{
              fontWeight: 600,
              fontSize: 22,
              marginTop: 12,
            }}
          >
            Failed
          </p>
          <p
            style={{
              fontWeight: 500,
              fontSize: 16,
              marginTop: 8,
            }}
          >
            Something went wrong. Please try again.
          </p>
          <Button
            className="w-[150px] h-[38px] mt-[20px]"
            style={{
              marginRight: 10,
              backgroundColor: "#FF7F07",
              color: "#ffffff",
              border: "2px solid #FF7F07",
            }}
            onClick={() => {
              setIsModalFailedOpen(false);
              onSubmit();
              // navigate("/survey-form-list");
            }}
            // type="primary"
          >
            Try Again
          </Button>
        </div>
</Modal>
           
          <div className="mt-5 mb-3" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Link to="/permission-list" style={{ textDecoration: "none" }}>
              <label
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  marginBottom: "20px",
                  display: "block",
                  color: "#000000",
                }}
                onMouseEnter={(e) => (e.target.style.color = "#000000")}
                onMouseLeave={(e) => (e.target.style.color = "#000000")}
              >
                <ArrowLeftOutlined style={{ marginRight: "8px" }} /> Permission
              </label>
          
          </Link>
              <Flex justify="flex-end" style={{ marginTop: 0 }}>
                <Button
                  // className="mt-2 mr-3 flex items-center px-12 border-0"
                  //loading={loadingExcel}
                  // icon={<FileExcelOutlined />}
                  size="large"
                  className="mr-4"
                  type="default"
                  shape="default"
                  onClick={() => {
                    navigate("/permission-list");
                  }}
                >
                  Cancel
                </Button>

                <Button
                  size="large"
                  type="primary"
                  shape="default"
                  onClick={save}
                >
                  Save
                </Button>

              </Flex>
            </div>
      <div className="bg-white rounded-lg p-5 mb-3 mt-5 pt-5">
      {contextHolder}

      <div className="w-full justify-between items-center">
        <Row>
          <Col sm={14} md={14} lg={14}>
            <div
              style={{
                marginBottom: 16,
                fontSize: 12,
                color: "#0C0C0C",
              }}
            >
              <label style={{ fontSize: 20, fontWeight: 600 }}>
                Permission - {permissionDetail && permissionDetail.name}
              </label>
            </div>
            <div>
              {/* {testV1.map((d) => (
                <div >
                  {d.task}000000{d.id}
                 
                </div>
              ))} */}
            </div>
            
          </Col>
         
        </Row>
      </div>

      <Form form={form} layout="vertical">
        <hr/>
        <div className="contact ml-3">
          <Row align="middle" gutter={24}>
            <Table
              style={{
                margin: "8px 0",
              }}
              columns={columns}
              //rowKey={(record) => record.id}
              dataSource={data}
              pagination={false}
              //loading={loading}
              // onChange={handleTableChange}
              scroll={{
                x: 500,
              }}
            />
          </Row>
        </div>
      </Form>
      </div>
    </>
  );
};

export default PermissionDetail;
