import Icon from "@ant-design/icons";

const icon = () => (
  <svg width="229" height="171" viewBox="0 0 229 171" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="117" cy="80.5" r="80" fill="#EAECF0"/>
<circle cx="33" cy="20.5" r="8" fill="#F2F4F7"/>
<circle cx="205" cy="126.5" r="6" fill="#F2F4F7"/>
<circle cx="32" cy="138.5" r="10" fill="#F2F4F7"/>
<circle cx="217" cy="38.5" r="10" fill="#F2F4F7"/>
<circle cx="198" cy="11.5" r="7" fill="#F2F4F7"/>
<g filter="url(#filter0_dd_461_23765)">
<path d="M63.078 129.714L115.529 105.255C118.163 104.027 119.302 100.897 118.074 98.2633L87.0985 31.836L66.1232 24.2016L23.2085 44.213C20.575 45.441 19.4357 48.5714 20.6637 51.2048L56.0862 127.169C57.3142 129.802 60.4446 130.942 63.078 129.714Z" fill="url(#paint0_linear_461_23765)"/>
<path d="M66.123 24.2017L87.0984 31.8361L72.7935 38.5066L66.123 24.2017Z" fill="#D0D5DD"/>
</g>
<g filter="url(#filter1_dd_461_23765)">
<path d="M88.1516 111.839H146.025C148.931 111.839 151.286 109.484 151.286 106.578V33.2837L135.503 17.5H88.1516C85.2459 17.5 82.8904 19.8555 82.8904 22.7612V106.578C82.8904 109.484 85.2459 111.839 88.1516 111.839Z" fill="url(#paint1_linear_461_23765)"/>
<path d="M135.503 17.5L151.286 33.2837H135.503V17.5Z" fill="#D0D5DD"/>
</g>
<g filter="url(#filter2_dd_461_23765)">
<path d="M117.911 105.224L170.362 129.682C172.996 130.91 176.126 129.771 177.354 127.138L208.33 60.7103L200.695 39.7349L157.781 19.7235C155.147 18.4955 152.017 19.6348 150.789 22.2683L115.366 98.2322C114.138 100.866 115.278 103.996 117.911 105.224Z" fill="url(#paint2_linear_461_23765)"/>
<path d="M200.695 39.7349L208.329 60.7102L194.025 54.0398L200.695 39.7349Z" fill="#D0D5DD"/>
</g>
<defs>
<filter id="filter0_dd_461_23765" x="-1.55981" y="17.5312" width="141.857" height="154.406" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_dropShadow_461_23765"/>
<feOffset dy="8"/>
<feGaussianBlur stdDeviation="4"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_461_23765"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect2_dropShadow_461_23765"/>
<feOffset dy="20"/>
<feGaussianBlur stdDeviation="12"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"/>
<feBlend mode="normal" in2="effect1_dropShadow_461_23765" result="effect2_dropShadow_461_23765"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_461_23765" result="shape"/>
</filter>
<filter id="filter1_dd_461_23765" x="62.8904" y="17.5" width="108.396" height="134.339" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_dropShadow_461_23765"/>
<feOffset dy="8"/>
<feGaussianBlur stdDeviation="4"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_461_23765"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect2_dropShadow_461_23765"/>
<feOffset dy="20"/>
<feGaussianBlur stdDeviation="12"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"/>
<feBlend mode="normal" in2="effect1_dropShadow_461_23765" result="effect2_dropShadow_461_23765"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_461_23765" result="shape"/>
</filter>
<filter id="filter2_dd_461_23765" x="93.1426" y="17.5" width="141.857" height="154.406" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_dropShadow_461_23765"/>
<feOffset dy="8"/>
<feGaussianBlur stdDeviation="4"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_461_23765"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect2_dropShadow_461_23765"/>
<feOffset dy="20"/>
<feGaussianBlur stdDeviation="12"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"/>
<feBlend mode="normal" in2="effect1_dropShadow_461_23765" result="effect2_dropShadow_461_23765"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_461_23765" result="shape"/>
</filter>
<linearGradient id="paint0_linear_461_23765" x1="59.9965" y1="129.918" x2="18.197" y2="54.5087" gradientUnits="userSpaceOnUse">
<stop stop-color="#E4E7EC"/>
<stop offset="1" stop-color="#F9FAFB"/>
</linearGradient>
<linearGradient id="paint1_linear_461_23765" x1="85.2723" y1="110.723" x2="79.2585" y2="24.7131" gradientUnits="userSpaceOnUse">
<stop stop-color="#E4E7EC"/>
<stop offset="1" stop-color="#F9FAFB"/>
</linearGradient>
<linearGradient id="paint2_linear_461_23765" x1="115.773" y1="102.995" x2="146.672" y2="22.5024" gradientUnits="userSpaceOnUse">
<stop stop-color="#E4E7EC"/>
<stop offset="1" stop-color="#F9FAFB"/>
</linearGradient>
</defs>
</svg>

);

const Icon_NoData = (props) => <Icon component={icon} {...props} />;

export default Icon_NoData;
