import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NoData from "../assets/img/NoData.png";
import {
  Table,
  Dropdown,
  Space,
  Button,
  Form,
  Input,
  Flex,
  Drawer,
  Row,
  Col,
  Select,
  message,
  InputNumber,
  Modal,
  Tooltip,
  ConfigProvider
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import IconEdit from "../assets/icon/icon_edit";

// import { LearningPlaceApi } from "../apis/LearningPlaceApi";
// import { RefCampusApi } from "../apis/RefCampusApi";
// import { RefUnivApi } from "../apis/RefUnivApi";
// import { RefPlaceStatusApi } from "../apis/RefPlaceStatusApi";
// import { RefProvinceApi } from "../apis/RefProvinceApi";

import PermissionService from "../service/permissionService";
import AuthService from "../service/authService";

const ermissionService = new PermissionService();
const authService = new AuthService();

const PermissionList = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useNavigate();

  const pageSize = 10;
  let currentPage = 1;

  const columns = [
    {
      title: "No.",
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: "Role",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Permission Menu",
      dataIndex: "permissions",
      render: (text, record) =>
        record.permissions.filter(i => i.isActivated).map((name) => (
          <Row style={{ margin: 0 }}>
            <label>• {name.pageName}</label>
          </Row>
        )),
    },

    {
      title: "Action",
      dataIndex: "",
      render: (text, record) => (
        <span style={{ whiteSpace: "nowrap" }}>
          <Tooltip placement="bottom" title="View / Edit">
            <IconEdit
              onClick={() => {
                history({
                  pathname: `/permission-detail/${record.id}`,

                  // searchdata: searchValue,
                  // pagedata: pageValue,
                });
              }}
            />
          </Tooltip>
        </span>
      ),
    },
  ];

  const { Option } = Select;
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [Keywords, setKeywords] = useState("");

  const { Search, TextArea } = Input;

  const onSearch = (value, _e, info) => {
  };

  const handleSearchChange = (event) => {
    setKeywords(event.target.value);
  };

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState();
  const [LearningPlace, setLearningPlace] = useState({});
  const [disableForm, setDisableForm] = useState();
  const [refCampus, setRefCampus] = useState();
  const [refUniv, setRefUniv] = useState();
  const [refProvince, setRefProvince] = useState();
  const [refPlaceStatus, setRefPlaceStatus] = useState();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [pageValue, setPageValue] = useState({
    page: 1,
    pageSize: 10,
    // total: 40,
  });
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 4,
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const testData = [
    {
      id: 1,
      role: "Editer",
      permissionMenu: [
        { menuName: "Survey Form" },
        { menuName: "Survey Form Transaction" },
        { menuName: "IVR Transaction" },
      ],
    },

    {
      id: 2,
      role: "Moderator",
      permissionMenu: [
        { menuName: "Survey Form Transaction" },
        { menuName: "IVR Transaction" },
        { menuName: "All Report" },
      ],
    },
    {
      id: 3,
      role: "Observer",
      permissionMenu: [
        { menuName: "Survey Form" },
        { menuName: "Survey Form Transaction" },
        { menuName: "IVR Transaction" },
        { menuName: "All Report" },
      ],
    },
    {
      id: 4,
      role: "Administrator",
      permissionMenu: [
        { menuName: "User Management " },
        { menuName: "Permission" },
        { menuName: "Survey Form" },
        { menuName: "Survey Form Transaction" },
        { menuName: "IVR Transaction" },
        { menuName: "All Report" },
      ],
    },
  ];

  const getPermissionList = () => {
    ermissionService
      .getPermissionList()
      .then((response) => {
        setData(response.data.items);
        setPageValue({
          page: response.data.pagination.page,
          pageSize: response.data.pagination.pageSize,
          total: response.data.pagination.totalCount,
        });
        console.log("ddddd", response);
        console.log("ddddd", response.data.pagination);

      })
      .catch(async (err) => {
        console.log("err", err);
        if (err.status === 401) {
          console.log("token time");
        }
        history({
          pathname: `/login`,
        });
      });
  };




  useEffect(() => {
    getPermissionList();
  }, []);



  const handleTableChange = (pagination, filters, sorter, extra) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return (
    <>
       <div
        style={{
          marginBottom: 16,
          fontSize: 12,
          color: "#0C0C0C",
        }}
      >
        <label style={{ fontSize: 20, fontWeight: 600 }}>Permission</label>
      </div>
      <div
          style={{
            backgroundColor: "#fff",
            padding: 15,
            marginBottom: 15,
            borderRadius: 10,
          }}
        >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#ffffff",
          },
          components: {
            Pagination: {
              itemActiveBg: "#FF7F07",
            },
            Input: {
              activeBorderColor: "#1677ff",
            },
          },
        }}
      >
      {contextHolder}
      <div>
        <label style={{ fontSize: 16, fontWeight: 600 }}>Permission list</label>
      </div>
      {!data || data.length === 0 ? (
        <div style={{ textAlign: 'center', padding: '20px', color: '#666' }}>
          <img style={{ margin: 'auto'}} src={NoData} alt="No data" />
        </div>
      ) : (
        <Table
          style={{
            margin: '8px 0',
          }}
          columns={columns}
          dataSource={data}
          pagination={{
            position: ['bottomCenter'],
          }}
        />
      )}

      </ConfigProvider>
      </div>
    </>
  );
};

export default PermissionList;
