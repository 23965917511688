


export default class Utills {

    getChannelName (findItem){
        let channelName = "";
        findItem.map((item, i) => {
          if(i === 0){
            channelName = item.surveyChannelName;
          }else{
            channelName += ", " + item.surveyChannelName;
          }
        })
        return channelName;
      }

}