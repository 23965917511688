import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Table,
  Button,
  Form,
  Input,
  Row,
  Col,
  Select,
  message,
  DatePicker,
  Pagination,
} from "antd";
import IVRTransactionService from "../service/ivrTransactionService";
import {
  SearchOutlined,
  SyncOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

import Moment from 'moment';
const { RangePicker } = DatePicker;

const iVRTransactionService = new IVRTransactionService();

let currentPage = 1;
let pageSize = 10;
let sortBy = "";
let sortDirection = "";
let total = 0;

const IVRTransactionList = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useNavigate();
  const [pageValue, setPageValue] = useState({
    page: currentPage,
    pageSize: pageSize,
    total: 0,
  });
  const [dateRange, setDateRange] = useState([]);
  const [dateRangeString, setDateRangeString] = useState();

  // let newData = testData[0];
  // newData.id = 4;
  // // testData.push(newData)
  // // console.log(testData);

  const columns = [
    {
      title: "No",
      // dataIndex: "id",
      width: '5%',
      //render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
      render: (_, __, index) => (pageValue.page - 1) * pageValue.pageSize + index + 1,
    },
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   sorter: true,
    // },
    {
      title: "Customer Name",
      width: '10%',
      dataIndex: "customerName",
      sorter: true,
    },
    {
      title: "Phone No",
      width: '10%',
      dataIndex: "phoneNumber",
      sorter: true,
    },
    // {
    //   title: "Agent ID",
    //   width: '10%',
    //   dataIndex: "agentId",
    //     sorter: true,
    // },
    {
      title: "Call UUID",
      width: '10%',
      dataIndex: "callUuId",
        sorter: true,
    },
    {
      title: "Call Flow Name",
      width: '10%',
      dataIndex: "callFlowName",
        sorter: true,
    },
    {
      title: "DNIS",
      width: '10%',
      dataIndex: "dnis",
      // render: (text, record, index) => record.isRead ? 'R' : '-'
      //   sorter: true,
    },
    {
      title: "Block Name",
      width: '10%',
      dataIndex: "blockName",
      // render: (text, record, index) => record.isAnswered ? 'A' : '-'
      //   sorter: true,
    },
    {
      title: "Create Date",
      width: '10%',
      dataIndex: "createdDateDisplay",
        sorter: true,
    },
   
    // {
    //   title: "Action",
    //   width: 150,
    //   render: (text, record) => (
    //     <span style={{ whiteSpace: "nowrap" }}>
    //       {/* {role[0].roleKey.includes("web-admin") && ( */}
    //       <Tooltip placement="bottom" title="Reset Password">
    //         <IconView
    //           style={{ marginRight: 10 }}
    //           onClick={() => {
    //             history(
    //               {
    //                 pathname: `/survey-tranaction-detail`,

    //                 // pagedata: pageValue,
    //               },
    //               { state: { id: record.id } }
    //             );
    //           }}
    //           twoToneColor="#98A2B3"
    //           onMouseEnter={()=>{
    //             // alert("I am an alert box!");
    //           }}
    //         />
    //       </Tooltip>
    //       {/* )} */}
    //     </span>
    //   ),
    // },
  ];

  const { Option } = Select;

  const query = useQuery();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [Keywords, setKeywords] = useState("");

  const { Search, TextArea } = Input;

  const onSearch = (value, _e, info) => {
    // console.log(info?.source, value);
    let startDate = "";
    let endDate = "";
    if(dateRange.length >= 2){
      startDate = Moment(dateRangeString[0]).format('YYYY-MM-DD');
      endDate = Moment(dateRangeString[1]).format('YYYY-MM-DD');
    }
    console.log("Result : ",startDate);
    const pageValues = {
      startDate: startDate,
      endDate: endDate,
      page: currentPage,
      pageSize: pageSize,
      sortBy: sortBy,
      sortDirection: sortDirection,
      total: total,
      keywords: Keywords,
    };
    setPageValue(pageValues);
    fetchData(pageValues);
  };

  const handleSearchChange = (event) => {
    setKeywords(event.target.value);
  };

  const [data, setData] = useState();
  const [dataList, setDataList] = useState();

  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: pageSize,
      position: ['bottomCenter'],
      total: 100,
      showSizeChanger: true,
    },
  });

  function fetchData(pageValue){
    setLoading(true);
    iVRTransactionService.getIVRTransactionList(pageValue).then((response) => {
      setLoading(false);
      if(response.data != undefined){
        total = response.data.pagination.totalCount;
        setTableParams({
          pagination: {
            current: currentPage,
            pageSize: pageSize,
            position: ['bottomCenter'],
            total: total,
            showSizeChanger: true,
          },
        });
        setData(response.data.items);
      }
      // if (response.data.objectValue) setDataList(response.data.objectValue);
      // console.log("ddddd", response.data.objectValue);
    });
  }

  const getDropdownConnector = (value) => {
    // userListService.getUserList().then((response) => {
    //   if (response.data.objectValue) setDataList(response.data.objectValue);
    //   console.log("ddddd",response.data.objectValue);
    // });
  };

  function useQuery() {
    const { search } = useLocation();

    // return React.useMemo(() => new URLSearchParams(search), [search]);
  }



  useEffect(() => {
    fetchData(pageValue);
    //   }, [JSON.stringify(tableParams)]);
  }, []);

  const onRangeChange = (value, dateString) => {
    console.log(value, dateString);
    setDateRange(value);
    setDateRangeString(dateString);
    // let result = Moment(value[0]).format('YYYY-MM-DD');
  };

  function capitalize(str){
    if(str == undefined){
      return;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const onPageChange = async (pagination, filters, sorter) => {
    let sortBy = capitalize(sorter.field);
    if("createdDateDisplay" === sorter.field){
      sortBy = "CreatedDate";
    }
    let startDate = "";
    let endDate = "";
    if(dateRange.length >= 2){
      startDate = Moment(dateRangeString[0]).format('YYYY-MM-DD');
      endDate = Moment(dateRangeString[1]).format('YYYY-MM-DD');
    }
    currentPage = pagination.current;
    pageSize = pagination.pageSize;
    const pageValues = {
      keywords: Keywords,
      startDate: startDate,
      endDate: endDate,
      page: pagination.current,
      pageSize: pagination.pageSize,
      sortBy: sortBy,
      sortDirection:
        sorter && sorter.order
          ? sorter.order.substring(0, 3).toUpperCase() === "ASC"
            ? "asc"
            : "desc"
          : "asc",
      total: pageValue.total,
    };
    setPageValue(pageValues);
    fetchData(pageValues);
  };

  const handleSortBy = async (pagination, filters, sorter) => {
    let sortBy = capitalize(sorter.field);
    if("createdDateDisplay" === sorter.field){
      sortBy = "CreatedDate";
    }
    let startDate = "";
    let endDate = "";
    if(dateRange.length >= 2){
      startDate = Moment(dateRangeString[0]).format('YYYY-MM-DD');
      endDate = Moment(dateRangeString[1]).format('YYYY-MM-DD');
    }
    const pageValues = {
      keywords: Keywords,
      startDate: startDate,
      endDate: endDate,
      page: pageValue.page,
      pageSize: pageValue.pageSize,
      sortBy: sortBy,
      sortDirection:
        sorter && sorter.order
          ? sorter.order.substring(0, 3).toUpperCase() === "ASC"
            ? "asc"
            : "desc"
          : "asc",
      total: pageValue.total,
    };
    setPageValue(pageValues);
    fetchData(pageValues);
  }

  const handlePageChange = async (page, pageSize) => {
    let startDate = "";
    let endDate = "";
    if(dateRange.length >= 2){
      startDate = Moment(dateRangeString[0]).format('YYYY-MM-DD');
      endDate = Moment(dateRangeString[1]).format('YYYY-MM-DD');
    }
    const pageValues = {
      keywords: Keywords,
      startDate: startDate,
      endDate: endDate,
      page: page,
      pageSize: pageSize,
      sortBy: pageValue.sortBy,
      sortDirection:pageValue.sortDirection,
      total: pageValue.total,
    };
    setPageValue(pageValues);
    fetchData(pageValues);
  };

  const itemRender = (_, type, originalElement) => {
    const { page, total, pageSize } = pageValue;
    const totalPages = Math.ceil(tableParams.pagination?.total / pageSize);
    if (type === "prev") {
      if (page === 1) {
        return (
          <a style={{ pointerEvents: "none" }}>
            <LeftOutlined
              style={{
                color: "#d9d9d9",
              }}
            />
          </a>
        );
      }
      return (
        <a>
          <LeftOutlined
            style={{
              color: "#FF7F07",
            }}
          />
        </a>
      );
    }
    if (type === "next") {
      if (page === totalPages) {
        return (
          <a style={{ pointerEvents: "none" }}>
            <RightOutlined
              style={{
                // fontSize: "",
                color: "#d9d9d9",
              }}
            />
          </a>
        );
      }
      return (
        <a>
          <RightOutlined
            style={{
              // fontSize: "",
              color: "#FF7F07",
            }}
          />
        </a>
      );
    }
    return originalElement;
  };

  const handlePageSizeChange = (current, pageSize) => {
    const pageValues = {
      keywords: Keywords,
      page: 1,
      pageSize: pageSize,
      sortBy: pageValue.sortBy,
      sortDirection:pageValue.sortDirection,
      total: pageValue.total,
    };
    setPageValue(pageValues);
    fetchData(pageValues);
  };

  return (
    <>
      
        {contextHolder}
        <label style={{ fontSize: 24, fontWeight: 700 }}>IVR Transaction</label>
        <div className="bg-white rounded-lg p-4 mb-3 mt-7 pt-5">
          <Form form={form} layout="vertical">
            <div className="flex flex-row ">
              <div className="flex-1 me-0 mt-3">
                <div >
                  <label className="font-medium">Date</label>
                </div>
                <div className="mt-3 me-4" style={{paddingTop:6}}>
                  <RangePicker className="w-full"
                    size="large"
                    onChange={onRangeChange}
                    value={dateRange}
                    placeholder={["Select Date", "End Date"]}
                  />
                </div>
              </div>
              <div className="flex-1  mt-0 pt-1">
                <Form.Item label="Search" name="chargerId" colon={false}>
                  <Input size="large"
                    placeholder="Search Customer name, Mobile No, Agent ID, Call uuid...."
                    onChange={(evt) => handleSearchChange(evt)}
                    suffix={<SearchOutlined />}
                  />
                  {/* <Search placeholder="Search ID, Title,Customer name, Mobile No., Email..." onSearch={onSearch} /> */}
                </Form.Item>
              </div>
            </div>

            <div className="flex flex-row justify-end mb-2">
              <div className="mt-0 mb-0">
                <Button
                  size="large"
                  className=" ml-2"
                  onClick={() => {
                    setDateRange([]);
                    form.resetFields();
                    const searchValues = {
                      chargerId: "",
                      landlordId: [],
                      merchantId: [],
                    };
                    currentPage = 1;
                    const pageValues = {
                      keywords: "",
                      page: currentPage,
                      pageSize: pageSize,
                      sortBy: "",
                      sortDirection: "",
                    };
                    fetchData(pageValues);

                    // setSearchValue(searchValues);
                    // getLogCharging(searchValues, pageValues);
                  }}
                  shape="default"
                  icon={<SyncOutlined />}
                >
                  Clear
                </Button>
              </div>
              <div className="mt-0 mb-0">
                <Button
                  size="large"
                  className="ml-4"
                  onClick={onSearch}
                  type="primary"
                  shape="default"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                >
                  Search
                </Button>
              </div>
            </div>
          </Form>

        </div>

        <div className="bg-white rounded-lg p-4 mb-3 mt-5 pt-5">
          <div>
            <Row>
              <Col span={20} style={{ marginRight: 0 }}>
                <label style={{ fontSize: 20, fontWeight: 700 }}>
                  IVR Transaction
                </label>
              </Col>
              <Col span={4} style={{ marginRight: 0 }}>
                {/* <Flex justify="flex-end" style={{ marginRight: 0 }}>
                <Button
                  className="w-28"
                  style={{
                    marginRight: 10,
                    backgroundColor: "#FF7F07",
                    color: "#ffffff",
                    border: "2px solid #FF7F07",
                  }}
                  onClick={onSearch}
                  // type="primary"
                  shape="default"
                  htmlType="submit"
                  icon={<PlusOutlined />}
                >
                  Add User
                </Button>
              </Flex> */}
              </Col>
            </Row>
          </div>

          <Table
            className="mt-8"
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            // pagination={{
            //   ...tableParams.pagination,
            //   showTotal: (total, range) => <b>Total : {total} items</b>,
            // }}
            pagination={false}
            loading={loading}
            onChange={handleSortBy}
            scroll={{
              x: 1024,
            }}
          />
          {data &&data.length !== 0 ? (
          <Row justify="space-between" align="middle" style={{ marginTop: 16 }}>
            <Col>
              <span
                style={{
                  color: "#667085",
                }}
              >
                <b>Total : {tableParams.pagination?.total} items</b>
              </span>
            </Col>
            <Col>
              <Pagination
                style={{ pointerEvents: loading ? "none" : "auto" }}
                current={pageValue?.page}
                pageSize={pageValue?.pageSize}
                total={tableParams.pagination?.total}
                onChange={handlePageChange}
                itemRender={itemRender}
              />
            </Col>
            <Col>
              <Select
                size="middle"
                value={pageValue.pageSize}
                onChange={(pageSize) =>
                  handlePageSizeChange(
                    tableParams.pagination?.current,
                    pageSize
                  )
                }
                style={{
                  width: "130px",
                }}
              >
                <Option value={10}>10 / page</Option>
                <Option value={25}>25 / page</Option>
                <Option value={50}>50 / page</Option>
                <Option value={100}>100 / page</Option>
              </Select>
            </Col>
          </Row>
        ) : null}
        </div>
    </>
  );
};

export default IVRTransactionList;
