import environment from "../environment/environment";
import httpRequest from "./httpService";
import axios from "axios";

export default class SurveyFormService {
  url = environment.SERVICE_URL_EV;
  urldev = environment.API_SURVEY_FORM;
  urlHisroy = environment.API_SURVEY_FORM_HISTORY;

  getSurveyList(value) {
    let keywords = value.keywords
    if(keywords === undefined){
      keywords = "";
    }
    let sortBy = value.sortBy
    if(sortBy === undefined){
      sortBy = "";
    }
    let sortDestination = value.sortDirection
    if(sortDestination === undefined){
      sortDestination = "";
    }
    let isActivated = value.isActivated
    if(isActivated === undefined){
      isActivated = "true";
    }
    // return axios.get("https://localhost:7285/users");
    // return httpRequest.get(this.url + this.urldev+"?Keywords=&Pagination.Page=1&Pagination.PageSize=10");
    return httpRequest.get(this.url + this.urldev+"?Keywords="
    +keywords+"&Pagination.Page="+value.page+"&Pagination.PageSize="
    +value.pageSize+"&Pagination.SortBy="+sortBy+"&Pagination.SortDestination="+sortDestination+"&isActivated="+isActivated);
   
  }

  getSurveyDetail(id) {
    return httpRequest.get(this.url + this.urldev+"/" + id);
  }

  getSurveyFormHistoryList(value) {
    let keywords = value.keywords
    if(keywords === undefined){
      keywords = "";
    }
    let sortBy = value.sortBy
    if(sortBy === undefined){
      sortBy = "";
    }
    let sortDestination = value.sortDirection
    if(sortDestination === undefined){
      sortDestination = "";
    }
    let surveyFormId = value.SurveyFormId
    if(surveyFormId === undefined){
      surveyFormId = "";
    }
    // return axios.get("https://localhost:7285/users");
    // return httpRequest.get(this.url + this.urldev+"?Keywords=&Pagination.Page=1&Pagination.PageSize=10");
    return httpRequest.get(this.url + this.urlHisroy+"?Keywords="
    +keywords+"&Pagination.Page="+value.page+"&Pagination.PageSize="
    +value.pageSize+"&Pagination.SortBy="+sortBy+"&Pagination.SortDestination="+sortDestination+"&SurveyFormId="+surveyFormId);
  }

  getSurveyHistoryDetail(id) {
    return httpRequest.get(this.url + this.urlHisroy+"/" + id);
  }

  getChannel() {
    return httpRequest.get(this.url + "/survey-channels");
  }

  getValidation() {
    return httpRequest.get(this.url + "/survey-response-validations");
  }

  submitData(request) {
    return httpRequest.post(this.url+"/survey-forms",request);
  }

  updateData(request, id) {
    return httpRequest.put(this.url+"/survey-forms/"+id,request);
  }

  delteData(id) {
    return httpRequest.delete(this.url+"/survey-forms/"+id);
  }
}
