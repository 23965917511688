import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button, ConfigProvider, Space, Form } from 'antd';

const Login = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [credentials, setCredentials] = useState({
    username: 'Jpark@yopmail.com',
    password: '6uJsaxrD'
  });

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://localhost:7285/auth/login', credentials);
      const { accessToken, refreshToken, userInfo, permissions } = response.data;

      // Store the tokens in localStorage or secure cookie for later use
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      localStorage.setItem('permissions', JSON.stringify(permissions));

      // Redirect or perform other actions upon successful login
      navigate("/user1");
    } catch (error) {
      // Handle login error
    }
  };

  return (
    <form form={form} onSubmit={handleSubmit}>
      <input
        type="email"
        name="username"
        value={credentials.username}
        onChange={handleChange}
      />
      <input
        type="password"
        name="password"
        value={credentials.password}
        onChange={handleChange}
      />
      {/* <Button type="primary">Primary</Button> */}
      <Button
        type="primary"
        htmlType="submit"
        onClick={() => form.submit()}>
        Login
      </Button>
    </form>
  );
};

export default Login;