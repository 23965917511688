import Icon from "@ant-design/icons";

const icon = () => (
  

<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8.8418" r="8" fill="#E6F7FF"/>
<path d="M0.5 8.8418C0.5 4.69966 3.85786 1.3418 8 1.3418C12.1421 1.3418 15.5 4.69966 15.5 8.8418C15.5 12.9839 12.1421 16.3418 8 16.3418C3.85786 16.3418 0.5 12.9839 0.5 8.8418Z" fill="white" stroke="#667085"/>
<rect x="4" y="4.8418" width="8" height="8" rx="4" fill="#667085"/>
</svg>

  


);

const Icon_Radio_Unselect = (props) => <Icon component={icon} {...props} />;

export default Icon_Radio_Unselect;

