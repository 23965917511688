import Icon from "@ant-design/icons";

const icon = () => (
  

<svg width="32" height="35" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_887_19633)">
<path d="M0 8.2627C0 3.84442 3.58172 0.262695 8 0.262695H24C28.4183 0.262695 32 3.84442 32 8.2627V24.2627C32 28.681 28.4183 32.2627 24 32.2627H8C3.58172 32.2627 0 28.681 0 24.2627V8.2627Z" fill="white"/>
<path d="M0.5 8.2627C0.5 4.12056 3.85786 0.762695 8 0.762695H24C28.1421 0.762695 31.5 4.12056 31.5 8.2627V24.2627C31.5 28.4048 28.1421 31.7627 24 31.7627H8C3.85786 31.7627 0.5 28.4048 0.5 24.2627V8.2627Z" stroke="#98A2B3"/>
<path d="M18.2837 13.7627L17.9952 21.2627M14.0048 21.2627L13.7163 13.7627M22.023 11.0882C22.308 11.1312 22.592 11.1773 22.875 11.2263M22.023 11.0882L21.1332 22.6565C21.058 23.6334 20.2434 24.3877 19.2637 24.3877H12.7363C11.7566 24.3877 10.942 23.6334 10.8668 22.6565L9.97696 11.0882M22.023 11.0882C21.0677 10.9439 20.1013 10.8334 19.125 10.758M9.125 11.2263C9.40798 11.1773 9.69198 11.1312 9.97696 11.0882M9.97696 11.0882C10.9323 10.9439 11.8987 10.8334 12.875 10.758M19.125 10.758V9.99452C19.125 9.01172 18.3661 8.19122 17.3838 8.1598C16.9244 8.1451 16.463 8.1377 16 8.1377C15.537 8.1377 15.0756 8.1451 14.6162 8.1598C13.6339 8.19122 12.875 9.01172 12.875 9.99452V10.758M19.125 10.758C18.0938 10.6783 17.0516 10.6377 16 10.6377C14.9484 10.6377 13.9062 10.6783 12.875 10.758" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<filter id="filter0_d_887_19633" x="0" y="0.262695" width="32" height="34" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.043 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_887_19633"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_887_19633" result="shape"/>
</filter>
</defs>
</svg>



  


);

const Icon_Bin_Delete = (props) => <Icon component={icon} {...props} />;

export default Icon_Bin_Delete;

