import { notification } from "antd";
import axios from "axios";
import moment from "moment";
import environment from "../environment/environment";
import AuthService from "./authService";

const authService = new AuthService();
const refreshAndRetryQueue = [];
let isCallService = false;


axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },

  async (error) => {
    let originalRequest = error?.config;
      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        // let isCallService = localStorage.getItem("isCallService");
        let refreshToken = await JSON.parse(localStorage.getItem("refreshToken"))
        if (null != refreshToken) {
          // && null === isFirstTime || isFirstTime === 'true'
          // if(null === isCallService || isCallService === 'false' ){
          if(!isCallService){
            localStorage.setItem('isCallService', "true")
            isCallService = true;
            try {
              const res = await axios.post(
                environment.SERVICE_URL_EV + "/auth/refresh-token1",
                { refreshToken: refreshToken }
                // { "Content-Type": "application/json" }
              )

              if (res?.status === 200) {
                let date = moment();
                localStorage.setItem("isCallService", "false");
                localStorage.setItem(
                  "accessToken",
                  JSON.stringify(res.data.accessToken)
                );
                localStorage.setItem(
                  "refreshToken",
                  JSON.stringify(res.data.refreshToken)
                );
                // localStorage.setItem("currentUser", JSON.stringify(res.data));
                // localStorage.setItem("oldExpireTime", JSON.stringify(date));
                originalRequest = error?.config;
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${res.data.accessToken}`;
              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${res.data.accessToken}`;
           
              }


              refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                const accessToken = JSON.parse(localStorage.getItem("accessToken"));
                config.headers[
                  "Authorization"
                ] = `Bearer ${accessToken}`;
                axios
                  .request(config)
                  .then((response) => {
                    console.log("queue success response")
                    resolve(response);
                  })
                  .catch((err) => {
                    console.log("queue err response")
                    reject(err);
                  });
              });
              
              refreshAndRetryQueue.length = 0;

              return axios(originalRequest);

              // .then((res) => {
              //   if (res?.status === 200) {
              //     let date = moment();
              //     localStorage.setItem('isCallService', "false")
              //     localStorage.setItem('accessToken', JSON.stringify(res.data.accessToken))
              //     localStorage.setItem('refreshToken', JSON.stringify(res.data.refreshToken))
              //     // localStorage.setItem("currentUser", JSON.stringify(res.data));
              //     // localStorage.setItem("oldExpireTime", JSON.stringify(date));

              //     axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`;
              //     originalRequest = error?.config;
              //     originalRequest.headers['Authorization'] = `Bearer ${res.data.accessToken}`;

              //     axios
              //     .request(originalRequest)
              //     .then((response) => {
              //       // resolve(response)
              //       return Promise.resolve(response);
              //     })
              //     .catch((err) => {
              //       // reject(err)
              //       return Promise.reject(err);
              //     });

              //     return axios(originalRequest);
              //   }
              // }).catch(async (err) => {
              //   localStorage.setItem('isCallService', "false")
              //   console.log("err", err);
              //   return Promise.reject(error?.response || error);
              // });
            } catch (refreshError) {
              if (refreshError.status === 401) {
                window.location.replace("/login");
                authService.logout();
              }
              throw refreshError;
            } finally {
              localStorage.setItem("isCallService", "false");
              isCallService = false;
            }
            // return new Promise((resolve, reject) => {
            //   refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
            // });
          }
          new Promise((resolve, reject) => {
            refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
          });
          
        }
      } 
      // else if (error?.response?.status === 401 && originalRequest._retry) {
      //   notification["error"]({
      //     message: "Something went wrong !",
      //     description: "Please re-login or contact web admin.",
      //   });
      //   window.location.replace('/login')
      //   return Promise.reject(error?.response || error) && authService.logout();
      // } else{
      //   // window.location.replace('/login')
      //   return Promise.reject(error?.response || error);
      // }
      return Promise.reject(error?.response || error);
    
  }
);


axios.interceptors.request.use(
  (config) => {
    JSON.parse(localStorage.getItem("currentUser"))
    const accessToken = JSON.parse(localStorage.getItem("accessToken"));

    let isLogIn = config.url.includes("login");
    if (accessToken && !isLogIn) {
      config.headers.Authorization = `Bearer ${accessToken}`; 
      // config.headers['Access-Control-Allow-Origin'] = '*';
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


const httpRequest = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};

const RetryQueueItem = {
  resolve: (value) => {},
  reject: (error) => {},
  config: {}
};


export default httpRequest;
