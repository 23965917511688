import React, { useEffect,useState, } from "react";
import { useNavigate  } from "react-router-dom";
import { Col, Row, Card,Flex, Tag ,Select,Radio,Button,Progress,Tooltip } from 'antd';
import NonVoiceReport from './NonVoiceReport';
import VoiceReport from './VoiceReport';

import {getChannelOption,getSurveyForm,getNonVoiceReport,getVoiceReport} from '../../service/dashboardService';




const Dashboard = () => {

  const navigate = useNavigate();
  const _ = require("lodash");

  const [surveyFormList,setSurveyFormList] = useState([]);
  const [nonVoiceReportList,setNonVoiceReportList] = useState([]);
  const [nonVoiceReportVersion, setNonVoiceReportVersion] = useState(null);
  const [voiceReportList,setVoiceReportList] = useState([]);
  const [channels, setChannels] = useState([]);
  
  const [selectedOptionsServeyFrom, setSelectedOptionsServeyFrom] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);

  const monthYearStr = new Date().toLocaleString('en-US', { month: 'long' }) +" "+ new Date().getFullYear();


  useEffect(() => {
    fetchData();
  }, []);

  const { Option } = Select;
  const optionsServeyFrom = [
    {
      label: 'Last 7 days',
      value: '7days',
    },
    {
      label: 'Monthly',
      value: 'Monthly',
    },
    {
      label: 'Yearly',
      value: 'Yearly',
    },
  ];


  const fetchData = async () => {
    try {

      setSelectedOptionsServeyFrom("7days")
      setSelectedChannel('1')
      const surveyFormResponse = await getSurveyForm("day",7);
      setSurveyFormList(surveyFormResponse)
     
      const nonVoiceReportResponse = await getNonVoiceReport(1);
      setNonVoiceReportList(nonVoiceReportResponse)
      let sorted_obj = _.sortBy(nonVoiceReportResponse,[function (o) { return o.version; }]);
      setNonVoiceReportVersion(sorted_obj[sorted_obj.length-1])

      const voiceReportResponse = await getVoiceReport(1);
      setVoiceReportList(voiceReportResponse)

      //set option [channels]
      const channelsResponse = await getChannelOption();
      setChannels(channelsResponse)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleOptionsServeyFromChange = (value) => {
    setSurveyFormList([])
    let selectedValue = value.target.value
    setSelectedOptionsServeyFrom(selectedValue)

    if(selectedValue == "7days"){
      getSurveyForm("day",7).then((data)=>{
        setSurveyFormList(data)
      })
    } else if(selectedValue == "Monthly"){
      getSurveyForm("month",1).then((data)=>{
        setSurveyFormList(data)
      })
    } else if(selectedValue == "Yearly"){
      getSurveyForm("year",1).then((data)=>{
        setSurveyFormList(data)
      })
    }
    
    
  };

  const handleChannelChange = async (value) => {
    setSelectedChannel(value);

    const nonVoiceReportResponse = await getNonVoiceReport(value);
    setNonVoiceReportList(nonVoiceReportResponse)
    let sorted_obj = _.sortBy(nonVoiceReportResponse,[function (o) { return o.version; }]);
    setNonVoiceReportVersion(sorted_obj[sorted_obj.length-1])

    const voiceReportResponse = await getVoiceReport(value);
    setVoiceReportList(voiceReportResponse)
  };

  const handleSeeAllNonvoice = () => {
    //console.log("handleSeeAllNonvoice");
    navigate(`/report-list`,{state: { reportName: "nonVoiceSummary"}} );
  }

  const handleSeeAllVoice = () => {
    //console.log("handleSeeAllVoice");
    navigate(`/report-list`,{state: { reportName: "voiceSummary"}} );
  }

  return (
    <>
       <div className="bg-white rounded-lg p-6  ">
        <div className="flex justify-between items-center mb-5 pl-3 pr-3">
          <span className="text-lg font-bold" style={{fontSize: 20,color:'#7A7A7A'}}>Survey Form
            <span style={{paddingLeft:"1rem"}}> 
            {(surveyFormList.diffType == "positive") ? <Tag color="cyan" size="small">{surveyFormList.diffPercentage}</Tag> : null }
            {(surveyFormList.diffType == "negative") ? <Tag color="red"><b>{surveyFormList.diffPercentage}</b></Tag> : null }
            </span>
            
          </span>
         
          <div className="">
          <Radio.Group options={optionsServeyFrom} 
                      size="large"
                      onChange={handleOptionsServeyFromChange} 
                      value={selectedOptionsServeyFrom} 
                      optionType="button" 
                    />
          </div>
        </div>


        <div className="pl-3 pr-3" style={{paddingBottom:"1rem",alignItems:"end"}}>
          <Tooltip title="Answered"><span style={{fontSize: 50,paddingRight:"1rem"}}>{surveyFormList.answerTotal}</span></Tooltip>
          <span style={{fontSize: 20,paddingRight:"1rem"}}> of </span>
          <Tooltip title="Sent"><span style={{fontSize: 50,paddingRight:"1rem"}}>{surveyFormList.surveyTotal}  </span></Tooltip>
        </div>

        <Flex className="pl-3 pr-3" style={{paddingBottom:"1rem",width:"100%"}} gap="small" >
          {(surveyFormList && surveyFormList.channels && surveyFormList.channels[0] && (surveyFormList.channels[0].answerTotal > 0)) ?  <Progress type="line" showInfo={false} percent={100} strokeColor={'#FFA958'} strokeWidth={6} style={{width: (surveyFormList.channels[0].answerTotal/surveyFormList.answerTotal)*100 +"%" }}/> : null}
          {(surveyFormList && surveyFormList.channels && surveyFormList.channels[1] && (surveyFormList.channels[1].answerTotal > 0)) ?  <Progress type="line" showInfo={false} percent={100} strokeColor={'#8DDFCB'} strokeWidth={6} style={{width: (surveyFormList.channels[1].answerTotal/surveyFormList.answerTotal)*100 +"%" }}/> : null}
          {(surveyFormList && surveyFormList.channels && surveyFormList.channels[2] && (surveyFormList.channels[2].answerTotal > 0)) ?  <Progress type="line" showInfo={false} percent={100} strokeColor={'#F6C765'} strokeWidth={6} style={{width: (surveyFormList.channels[2].answerTotal/surveyFormList.answerTotal)*100 +"%" }}/> : null}
          {(surveyFormList && surveyFormList.channels && surveyFormList.channels[3] && (surveyFormList.channels[3].answerTotal > 0)) ?  <Progress type="line" showInfo={false} percent={100} strokeColor={'#697BE9'} strokeWidth={6} style={{width: (surveyFormList.channels[3].answerTotal/surveyFormList.answerTotal)*100 +"%" }}/> : null}
          {(surveyFormList && surveyFormList.channels && surveyFormList.channels[4] && (surveyFormList.channels[4].answerTotal > 0)) ?  <Progress type="line" showInfo={false} percent={100} strokeColor={'#FC726A'} strokeWidth={6} style={{width: (surveyFormList.channels[4].answerTotal/surveyFormList.answerTotal)*100 +"%" }}/> : null}
          
          {/* <Progress type="line" showInfo={false} percent={100} strokeColor={'#FFA958'} strokeWidth={6} style={{width:(surveyFormList && surveyFormList.channels && surveyFormList.channels[0]) ?  (surveyFormList.channels[0].answerTotal/surveyFormList.answerTotal)*100 +"%" : "0%"}} />
          <Progress type="line" showInfo={false} percent={100} strokeColor={'#8DDFCB'} strokeWidth={6} style={{width:(surveyFormList && surveyFormList.channels && surveyFormList.channels[1]) ?  (surveyFormList.channels[1].answerTotal/surveyFormList.answerTotal)*100 +"%" : "0%"}} />
          <Progress type="line" showInfo={false} percent={100} strokeColor={'#F6C765'} strokeWidth={6} style={{width:(surveyFormList && surveyFormList.channels && surveyFormList.channels[2]) ?  (surveyFormList.channels[2].answerTotal/surveyFormList.answerTotal)*100 +"%" : "0%"}} />
          <Progress type="line" showInfo={false} percent={100} strokeColor={'#697BE9'} strokeWidth={6} style={{width:(surveyFormList && surveyFormList.channels && surveyFormList.channels[3]) ?  (surveyFormList.channels[3].answerTotal/surveyFormList.answerTotal)*100 +"%" : "0%"}} />
          <Progress type="line" showInfo={false} percent={100} strokeColor={'#FC726A'} strokeWidth={6} style={{width:(surveyFormList && surveyFormList.channels && surveyFormList.channels[4]) ?  (surveyFormList.channels[4].answerTotal/surveyFormList.answerTotal)*100 +"%" : "0%"}} /> */}
        </Flex>
        
        
        <Row gutter={8} style={{marginBottom: 15}} >
        {(surveyFormList.channels && surveyFormList.channels[0]) ? 
          <Col span={12} >
            <Card size="small">
              <span style={{alignItems:"center"}}>
                <svg width="20" height="20" ><circle cx="12" cy="12" r="6" fill="#FFA958" /></svg>
                <b>  {surveyFormList.channels[0].channelName}  </b> 
                  {(surveyFormList.channels[0].diffType == "positive") ? <Tag color="cyan"><b>{surveyFormList.channels[0].diffPercentage}</b></Tag> : null }
                  {(surveyFormList.channels[0].diffType == "negative") ? <Tag color="red"><b>{surveyFormList.channels[0].diffPercentage}</b></Tag> : null }
              </span>
              <Flex gap="4px 0" wrap="" style={{alignItems:"center"}}>
                <div style={{alignItems:"end"}}>
                  <Tooltip title="Answered"><span style={{fontSize: 24,paddingRight:"2px",paddingLeft:"5px"}} ><b>{surveyFormList.channels[0].answerTotal}</b></span></Tooltip>
                  <span style={{fontSize: 12,paddingRight:"5px",paddingLeft:"5px"}} >of</span>
                  <Tooltip title="Sent"><span style={{fontSize: 24,paddingRight:"5px",paddingLeft:"2px"}} ><b>{surveyFormList.channels[0].surveyTotal}</b></span></Tooltip>
                </div>
              </Flex>
            </Card>
          </Col>
          : null
        }

        {(surveyFormList.channels && surveyFormList.channels[1]) ? 
          <Col span={12} >
          <Card size="small">
            <span style={{alignItems:"center"}}>
              <svg width="20" height="20" ><circle cx="12" cy="12" r="6" fill="#8DDFCB" /></svg>
              <b>  {surveyFormList.channels[1].channelName}  </b> 
                {(surveyFormList.channels[1].diffType == "positive") ? <Tag color="cyan"><b>{surveyFormList.channels[1].diffPercentage}</b></Tag> : null }
                {(surveyFormList.channels[1].diffType == "negative") ? <Tag color="red"><b>{surveyFormList.channels[1].diffPercentage}</b></Tag> : null }
            </span>
            <Flex gap="4px 0" wrap="" style={{alignItems:"center"}}>
              <div style={{alignItems:"end"}}>
                <Tooltip title="Answered"><span style={{fontSize: 24,paddingRight:"2px",paddingLeft:"5px"}} ><b>{surveyFormList.channels[1].answerTotal}</b></span></Tooltip>
                <span style={{fontSize: 12,paddingRight:"5px",paddingLeft:"5px"}} >of</span>
                <Tooltip title="Sent"><span style={{fontSize: 24,paddingRight:"5px",paddingLeft:"2px"}} ><b>{surveyFormList.channels[1].surveyTotal}</b></span></Tooltip>
              </div>
            </Flex>
          </Card>
        </Col>
        
        : null
        }
        </Row>

        <Row gutter={8}>

        {(surveyFormList.channels && surveyFormList.channels[2]) ? 
          <Col span={8} >
          <Card size="small">
            <span style={{alignItems:"center"}}>
              <svg width="20" height="20" ><circle cx="12" cy="12" r="6" fill="#F6C765" /></svg>
              <b>  {surveyFormList.channels[2].channelName}  </b> 
                {(surveyFormList.channels[2].diffType == "positive") ? <Tag color="cyan"><b>{surveyFormList.channels[2].diffPercentage}</b></Tag> : null }
                {(surveyFormList.channels[2].diffType == "negative") ? <Tag color="red"><b>{surveyFormList.channels[2].diffPercentage}</b></Tag> : null }
            </span>
            <Flex gap="4px 0" wrap="" style={{alignItems:"center"}}>
              <div style={{alignItems:"end"}}>
                <Tooltip title="Answered"><span style={{fontSize: 24,paddingRight:"2px",paddingLeft:"5px"}} ><b>{surveyFormList.channels[2].answerTotal}</b></span></Tooltip>
                <span style={{fontSize: 12,paddingRight:"5px",paddingLeft:"5px"}} >of</span>
                <Tooltip title="Sent"><span style={{fontSize: 24,paddingRight:"5px",paddingLeft:"2px"}} ><b>{surveyFormList.channels[2].surveyTotal}</b></span></Tooltip>
              </div>
            </Flex>
          </Card>
        </Col>
          : null
        }

          {(surveyFormList.channels && surveyFormList.channels[3]) ? 
          <Col span={8} >
          <Card size="small">
            <span style={{alignItems:"center"}}>
              <svg width="20" height="20" ><circle cx="12" cy="12" r="6" fill="#697BE9" /></svg>
              <b>  {surveyFormList.channels[3].channelName}  </b> 
                {(surveyFormList.channels[3].diffType == "positive") ? <Tag color="cyan"><b>{surveyFormList.channels[3].diffPercentage}</b></Tag> : null }
                {(surveyFormList.channels[3].diffType == "negative") ? <Tag color="red"><b>{surveyFormList.channels[3].diffPercentage}</b></Tag> : null }
            </span>
            <Flex gap="4px 0" wrap="" style={{alignItems:"center"}}>
              <div style={{alignItems:"end"}}>
                <Tooltip title="Answered"><span style={{fontSize: 24,paddingRight:"2px",paddingLeft:"5px"}} ><b>{surveyFormList.channels[3].answerTotal}</b></span></Tooltip>
                <span style={{fontSize: 12,paddingRight:"5px",paddingLeft:"5px"}} >of</span>
                <Tooltip title="Sent"><span style={{fontSize: 24,paddingRight:"5px",paddingLeft:"2px"}} ><b>{surveyFormList.channels[3].surveyTotal}</b></span></Tooltip>
              </div>
            </Flex>
          </Card>
        </Col>
          : null
        }

          {(surveyFormList.channels && surveyFormList.channels[4]) ? 
          <Col span={8} >
          <Card size="small">
            <span style={{alignItems:"center"}}>
              <svg width="20" height="20" ><circle cx="12" cy="12" r="6" fill="#FC726A" /></svg>
              <b>  {surveyFormList.channels[4].channelName}  </b> 
                {(surveyFormList.channels[4].diffType == "positive") ? <Tag color="cyan"><b>{surveyFormList.channels[4].diffPercentage}</b></Tag> : null }
                {(surveyFormList.channels[4].diffType == "negative") ? <Tag color="red"><b>{surveyFormList.channels[4].diffPercentage}</b></Tag> : null }
            </span>
            <Flex gap="4px 0" wrap="" style={{alignItems:"center"}}>
              <div style={{alignItems:"end"}}>
                <Tooltip title="Answered"><span style={{fontSize: 24,paddingRight:"2px",paddingLeft:"5px"}} ><b>{surveyFormList.channels[4].answerTotal}</b></span></Tooltip>
                <span style={{fontSize: 12,paddingRight:"5px",paddingLeft:"5px"}} >of</span>
                <Tooltip title="Sent"><span style={{fontSize: 24,paddingRight:"5px",paddingLeft:"2px"}} ><b>{surveyFormList.channels[4].surveyTotal}</b></span></Tooltip>
              </div>
            </Flex>
          </Card>
        </Col>
          : null
        }
          
        </Row>
            
      </div>



      <br></br>
      <div className="bg-white rounded-lg p-6 ">
        <div className="flex justify-between items-center mb-4 pl-3 pr-3">
          <div>
            <span className="text-lg font-bold" style={{fontSize: 24}}>Non Voice Monthly Report</span>
            <br/>
            <span className="text-lg font-bold text-orange-500">{monthYearStr}</span>
          </div>
          <div className="relative flex items-center">
              <Select
                  value={selectedChannel}
                  style={{ width: '250px' }}
                  size="large"
                  onChange={handleChannelChange}
              >
                {channels.map((channel) => (<Option key={channel.id} value={channel.id}>{channel.name}</Option>))}
              </Select>
              {/* <select className="block appearance-none bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded-md shadow-sm focus:outline-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" style={{ width: '300px' }}>
              <option>Option 1</option>
              <option>Option 2</option>
              <option>Option 3</option>
            </select> */}
             {/* <div className="ml-4">
                <Button size="large" onClick={handleSeeAllNonvoice} type="primary" shape="default">
                  See All
                </Button>
              </div> */}
          </div>
        </div>

        <div className="flex justify-between items-right mb-4 pl-3 " style={{justifyContent: "flex-end"}}>
          <div className="relative flex items-right ">
            <span style={{paddingRight:"20px",color:"#65707A",fontSize: 14}}>
                 {nonVoiceReportVersion ? "Version : " + nonVoiceReportVersion.version : ""}
            </span>
            
          </div>
        </div>

       
        <NonVoiceReport nonVoiceReportList={nonVoiceReportList}/> 
      </div>

      <br></br>
      <div className="bg-white rounded-lg p-6 flex flex-col">
        <div className="flex justify-between items-center mb-3 pl-3 pr-3">
          <div className="">
            <span className="text-lg font-bold " style={{fontSize: 24}}>Voice Monthly Report</span>
            <br />
            <span className="text-lg font-bold text-orange-500">{monthYearStr}</span>
          </div>
          <div className="relative flex items-center">
            {/* <button className="ml-4 bg-orange-500 text-white py-2 px-4 rounded-md">See All</button> */}
            {/* <div className="ml-4">
                <Button size="large" onClick={handleSeeAllVoice} type="primary" shape="default">
                  See All
                </Button>
              </div> */}
          </div>
        </div>
        <div className="relative flex items-center pl-2 pb-3">
          <span>
            <svg width="20" height="24" >
                <circle cx="12" cy="17" r="7" fill="#A9CAF9" />
              </svg>
              <b style={{paddingRight:"20px"}}> Incomplete</b> 
          </span>
          <span>
          <svg width="20" height="24" >
                <circle cx="12" cy="17" r="7" fill="#1677FF" />
              </svg>
              <b style={{paddingRight:"20px"}}> Complete</b> 
          </span>
        </div>
          <VoiceReport voiceReportList={voiceReportList}/> 
      </div>
    </>
  );
};

export default Dashboard;
