import Icon from "@ant-design/icons";

const icon = () => (
    <svg width="32" height="35" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_6448_2542)">
    <path d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5H24C28.4183 0.5 32 4.08172 32 8.5V24.5C32 28.9183 28.4183 32.5 24 32.5H8C3.58172 32.5 0 28.9183 0 24.5V8.5Z" fill="white"/>
    <path d="M0.5 8.5C0.5 4.35786 3.85786 1 8 1H24C28.1421 1 31.5 4.35786 31.5 8.5V24.5C31.5 28.6421 28.1421 32 24 32H8C3.85786 32 0.5 28.6421 0.5 24.5V8.5Z" stroke="#98A2B3"/>
    <path d="M11.4331 20.4163L12.6948 17.2618C12.896 16.7589 13.1973 16.302 13.5803 15.919L20.4995 9.00023C21.3279 8.1718 22.6711 8.1718 23.4995 9.00023C24.3279 9.82865 24.3279 11.1718 23.4995 12.0002L16.5803 18.919C16.1973 19.302 15.7404 19.6033 15.2375 19.8044L12.083 21.0662C11.6748 21.2295 11.2698 20.8244 11.4331 20.4163Z" fill="#475467"/>
    <path d="M9.5 12.2495C9.5 11.5592 10.0596 10.9995 10.75 10.9995H16C16.4142 10.9995 16.75 10.6637 16.75 10.2495C16.75 9.8353 16.4142 9.49951 16 9.49951H10.75C9.23122 9.49951 8 10.7307 8 12.2495V21.7495C8 23.2683 9.23122 24.4995 10.75 24.4995H20.25C21.7688 24.4995 23 23.2683 23 21.7495V16.4995C23 16.0853 22.6642 15.7495 22.25 15.7495C21.8358 15.7495 21.5 16.0853 21.5 16.4995V21.7495C21.5 22.4399 20.9404 22.9995 20.25 22.9995H10.75C10.0596 22.9995 9.5 22.4399 9.5 21.7495V12.2495Z" fill="#475467"/>
    </g>
    <defs>
    <filter id="filter0_d_6448_2542" x="0" y="0.5" width="32" height="34" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="2"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.043 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6448_2542"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6448_2542" result="shape"/>
    </filter>
    </defs>
    </svg>
    
);

const Icon_Edit = (props) => <Icon component={icon} {...props} />;

export default Icon_Edit;