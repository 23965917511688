import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined, UnlockOutlined , MailOutlined  } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { notification , Modal} from "antd";
import Swal from "sweetalert2";
import Save from "../assets/img/save.png";
import ExclamationMark from "../assets/img/ExclamationMark.png";
import IconConfirmSubmit from "../assets/icon/icon_confirm_submit";
import IconSuccessDialog from "../assets/icon/icon_success_dialog";
import IconFailedDialog from "../assets/icon/icon_failed_dialog";
import IconExclamationmarkDialog from "../assets/icon/icon_exclamationmark_dialog";

import { Button, Form, Input, Row, Col, Select, Switch, ConfigProvider , Tooltip } from "antd";

import UserListService from "../service/userListService";
import AuthService from "../service/authService";

const userListService = new UserListService();
const authService = new AuthService();


const UserDetail = () => {
  const navigate = useNavigate();
  const { Option } = Select;
  const history = useLocation();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [userDetail, setUserDetail] = useState([]);
  const [statusChange, setStatusChange] = useState(true);
  const [isNewUser, setIsNewUser] = useState(true);
  const [unsaveChange, setUnsavedChanges] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const { resetFields } = form;
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [isModalDiscardChangeOpen, setIsModalDiscardChangeOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({ id: null, status: null });
  const currentUser = localStorage.getItem("userId");
  const [isModalChangePasswordOpen, setIsModalChangePasswordOpen] = useState(false);

  const getUserList = (value) => {
    userListService.getUserDetail(value).then((response) => {
      setUserDetail(response.data);
      setStatusChange(response.data.isActivated);
      form.setFieldsValue({
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        email: response.data.email,
        tel: response.data.tel,
        roleId: response.data.roleId,
        isActivated: response.data.isActivated,
      });
    });
  };

  useEffect(() => {
    if (id) {
      setIsNewUser(false);
      getUserList(id);
    } else {
      setIsNewUser(true);
    }
    
    const onBeforeUnload = (ev) => {
      const { resetFields } = form;
      Swal.fire({
        html:
          '<div style="position: relative; display: flex; flex-direction: column; align-items: center;">' +
          '<img src="' +
          ExclamationMark +
          '" alt="save icon" style="width: 100px; height: auto; display: block; margin: auto;" />' +
          '<div style="margin-top: 20px; text-align: center; color: black; font-weight: bold;">Discard changes</div>' +
          '<div style="margin-top: 10px; text-align: center; color: black;">Changes will not be saved. Do you want to proceed ?</div>' +
          "</div>",
        showCancelButton: true,
        confirmButtonColor: "#ff7f07",
        cancelButtonColor: "#eae4e4",
        cancelButtonText:
          "<span style='color: black;'>Cancel</span>",
        confirmButtonText: "Confirm",
        reverseButtons: true,
        customClass: {
          popup: "my-popup-class",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          resetFields();
          navigate("/user-list");
        }
      })
      ev.preventDefault();
      ev.returnValue = "";

    };

    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    }; 
  }, [id]);

  const save = (values) => {
    setFormValues(values);
    setIsModalOpen(true);
  };

  const onSubmit = async () => {
    const values = formValues;
    const temp = {
        ...values,
        id: userDetail.id ? userDetail.id : undefined,
        firstName: values.firstName || "",
        lastName: values.lastName || "",
        email: values.email || "",
        tel: values.tel || "",
        roleId: values.roleId || "3",
        status: statusChange,
    };

    setIsModalOpen(false);

    try {
        console.log("isNewUser", isNewUser);

        if (isNewUser) {
            const res = await userListService.createUser(temp);
            if (res.status === 201) {
              console.log("true")
              setIsModalSuccessOpen(true); 
            } else {
              setIsModalFailedOpen(true);
            }
        } else {
            const res = await userListService.updateUser(temp);
            if (res.status === 204) {
              console.log("true")
              setIsModalSuccessOpen(true); 
            } else {
              setIsModalFailedOpen(true);
            }
        }
    } catch (err) {
      setIsModalFailedOpen(true);
    }
};


  const onFinish = (values) => {
    save(values);
  };

  const onChangeLock = (id) => {
    userListService
      .lockUser(id)
      .then((response) => {
        if (response.status === 204) {
          setIsModalSuccessOpen(true); 
        } else {
          setIsModalFailedOpen(true);
        }
      })
      .catch((err) => {
        setIsModalFailedOpen(true);
      });
  };

  const DiscardChange = () => {
    console.log("form.getFieldValue()",form.getFieldValue())
    console.log("userDetail",userDetail)
    console.log("isActivated >>> statusChange",statusChange)
    let newValue = form.getFieldValue()

    const { resetFields } = form;

    if(!isNewUser
      && (userDetail.firstName != newValue.firstName
        || userDetail.lastName != newValue.lastName
        || userDetail.roleId != newValue.roleId
        || userDetail.isActivated != statusChange
      )){
        setIsModalDiscardChangeOpen(true);
    }else{
      resetFields();
      navigate("/user-list")
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleisModalDiscardChangeOpenCancel = () => {
    setIsModalDiscardChangeOpen(false);
  };
  const handleisModalChangePasswordOpenCancel= () => {
    setIsModalChangePasswordOpen(false);
  };
  const handleBack = () => {
    resetFields();
    navigate("/user-list");
  };
  
  const handleConfirmPassword = () => {
    onChangeLock(id);
    setIsModalChangePasswordOpen(false); 
  };



  const closeModal = () => {
    setIsModalSuccessOpen(false);
  };
     useEffect(() => {
      let timeoutId;
      if (isModalSuccessOpen) {
          timeoutId = setTimeout(closeModal, 800);
      }
      return () => clearTimeout(timeoutId);
  }, [isModalSuccessOpen]);

  return (
    <div>

{/* Modal Confirm Save */}
<Modal title="" open={isModalOpen} footer={null} closable={false} centered>
        <div className="flex flex-col justify-center items-center">
        <IconConfirmSubmit />
          <p style={{ fontWeight: 600, fontSize: 22, marginTop: 12 }}>
            Confirm Save
          </p>
          <p style={{ fontSize: 15, marginTop: -10 }}>Do you want to save data ?</p>
         
          <div className="flex flex-row items-center justify-center mt-[20px]">
            <Button
              className="w-[150px] h-[38px]"
              type="primary"
              shape="round"
              style={{
                marginRight: 15,
                borderRadius: 10.5,
                border: "1px solid #D9D9D9",
                color: "#000",
                backgroundColor: "#FFF",
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="w-[150px] h-[38px]"
              style={{
                marginRight: 10,
                backgroundColor: "#FF7F07",
                color: "#ffffff",
                border: "2px solid #FF7F07",
              }}
              onClick={onSubmit}
            >
              Save
            </Button>
          </div>
        </div>
</Modal>

{/* Modal After Save - Success */}
<Modal
    title=""
    open={isModalSuccessOpen} 
    footer={null}
    closable={false}
    destroyOnClose={true}
    afterClose={() => {
        navigate(
            {
                pathname: `/user-list`,
            },
            { state: { updateDate: new Date() } }
        );
    }}
    centered
>
    <div className="flex flex-col justify-center items-center">
        <IconSuccessDialog />
        <p
            style={{
                fontWeight: 600,
                fontSize: 22,
                marginTop: 12,
            }}
        >
            Success
        </p>
        <p
            style={{
                fontWeight: 500,
                fontSize: 16,
                marginTop: 8,
            }}
        >
            Successfully saved data.
        </p>
    </div>
</Modal>

{/* Modal After Save - Failed */}
<Modal
    title=""
    open={isModalFailedOpen} 
    footer={null}
    closable={false}
    destroyOnClose={true}
    afterClose={() => {
        navigate(
            {
                pathname: `/user-list`,
            },
            { state: { updateDate: new Date() } }
        );
    }}
    centered
      >
        <div className="flex flex-col justify-center items-center">
          <IconFailedDialog />
          <p
            style={{
              fontWeight: 600,
              fontSize: 22,
              marginTop: 12,
            }}
          >
            Failed
          </p>
          <p
            style={{
              fontWeight: 500,
              fontSize: 16,
              marginTop: 8,
            }}
          >
            Something went wrong. Please try again.
          </p>
          <Button
            className="w-[150px] h-[38px] mt-[20px]"
            style={{
              marginRight: 10,
              backgroundColor: "#FF7F07",
              color: "#ffffff",
              border: "2px solid #FF7F07",
            }}
            onClick={() => {
              setIsModalFailedOpen(false);
              onSubmit();
              // navigate("/survey-form-list");
            }}
            // type="primary"
          >
            Try Again
          </Button>
        </div>
</Modal>

{/* Modal DiscardChange */}
<Modal title="" open={isModalDiscardChangeOpen} footer={null} closable={false} centered>
        <div className="flex flex-col justify-center items-center">
          <IconExclamationmarkDialog />
          <p style={{ fontWeight: 600, fontSize: 22, marginTop: 12 }}>Discard changes</p>
          <p style={{ fontSize: 15, marginTop: -10 }}>Changes will not be saved. Do you want to proceed ?</p>
          <div className="flex flex-row items-center justify-center mt-[20px]">
            <Button
              size="large"
              type="default"
              shape="default"
              onClick={handleisModalDiscardChangeOpenCancel}
            >Cancel
            </Button>
            <Button
              className="ml-4"
              size="large"
              type="primary"
              shape="default"
              onClick={handleBack}>
              Confirm
            </Button>
          </div>
        </div>
</Modal>



      {/* Modal Confirm Reset Password*/}
 <Modal title="" visible={isModalChangePasswordOpen} footer={null} closable={false} onCancel={handleisModalChangePasswordOpenCancel} centered>
  <div className="flex flex-col justify-center items-center">
  <IconExclamationmarkDialog />
    <p style={{ fontWeight: 600, fontSize: 22, marginTop: 12 }}>Confirm Reset Password</p>
    <p style={{ fontSize: 15, marginTop: -10 }}>Do you want to reset password ?</p>
    <div className="flex flex-row items-center justify-center mt-[20px]">
      <Button
        size="large"
        type="default"
        shape="default"
        onClick={handleisModalChangePasswordOpenCancel}
      >
        Cancel
      </Button>
      <Button
        className="ml-4"
        size="large"
        type="primary"
        shape="default"
        onClick={handleConfirmPassword} // Call handleConfirm function instead
      >
        Confirm
      </Button>
    </div>
  </div>
</Modal>


      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className="mt-5" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <ConfigProvider wave={{ disabled: true }}>
          <Button type="button" class="ant-btn css-var-R15alupimba ant-btn-link" style={{ textDecoration: "none", outline: "none"}} wave={{ disabled: true }}
          onClick={() => { DiscardChange()}}>
            <label
              style={{
                fontSize: 20,
                fontWeight: 600,
                marginBottom: "20px",
                display: "block",
                color: "#000000",
              }}
              onMouseEnter={(e) => (e.target.style.color = "#000000")}
              onMouseLeave={(e) => (e.target.style.color = "#000000")}
            >
              <ArrowLeftOutlined style={{ marginRight: "8px" }} to={"/user-list"} /> {userDetail.id ? 'Edit' : 'Add'} user
            </label>
          </Button>
        </ConfigProvider>
          <div style={{ display: "flex", alignItems: "center" }}>
            {userDetail.id && userDetail.id !== currentUser && statusChange && (
              <Button
                className="mr-4"
                size="large"
                type="default"
                shape="default"
                icon={<UnlockOutlined />}
                onClick={() => {
                  setIsModalChangePasswordOpen(true);
                }}
              >
                Reset password
              </Button>
            )}
            <Button
              className="mr-4"
              type="default"
              shape="default"
              onClick={() => { DiscardChange()}}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              shape="default"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </div>

        <div className="bg-white rounded-lg p-7 pt-8 mb-4 mt-7 ">
          <div className="contact">
             <Row align="middle" gutter={24}>
              <Col className="gutter-row" span={8}>
                <div style={{ marginBottom: 5 }}>
                  <label style={{ fontWeight: 500 }}>
                    Name<span style={{ color: "red" }}> *</span>
                  </label>
                </div>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your name",
                    },
                  ]}
                >
                  <Input placeholder="Name.." id="firstname" allowClear onChange={() => setUnsavedChanges(true)}/>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <div style={{ marginBottom: 5 }}>
                  <label style={{ fontWeight: 500 }}>
                    Surname<span style={{ color: "red" }}> *</span>
                  </label>
                </div>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your surname",
                    },
                  ]}
                >
                  <Input placeholder="Surname.." id="lastname" allowClear onChange={() => setUnsavedChanges(true)}/>
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle" gutter={24}>
              <Col className="gutter-row" span={8}>
                <div style={{ marginBottom: 5 }}>
                  <label style={{ fontWeight: 500 }}>
                    Email<span style={{ color: "red" }}> *</span>
                  </label>
                </div>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                    {
                      required: true,
                      message: "Please enter your email",
                    },
                  ]}
                >
                  
                  <Input prefix={<MailOutlined />} placeholder="abc@gmail.com" id="email" allowClear disabled={!isNewUser} onChange={() => setUnsavedChanges(true)}/>
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={8}>
                <div style={{ marginBottom: 5 }}>
                  <label style={{ fontWeight: 500 }}>
                    Role<span style={{ color: "red" }}> *</span>
                  </label>
                </div>
                <Form.Item
                  name="roleId"
                  rules={[
                    {
                      required: false,
                      message: "Please select a role",
                    },
                  ]}
                >
                  <Select
                    className="mt-2"
                    placeholder="Role"
                    showSearch
                    maxTagCount="responsive"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    defaultValue={"3"}
                  >
                    /* <Option key="1" value="1">
                      Administrator
                    </Option>
                    <Option key="2" value="2">
                      Moderator
                    </Option>
                    <Option key="3" value="3">
                      Editor
                    </Option>
                    <Option key="4" value="4">
                      Observer
                    </Option> */
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle" gutter={24}>
              <Col className="gutter-row" span={8}>
              <Form.Item label="Status" name="status" colon={false} style={{ display: isNewUser ? 'none' : 'block' }}>
                  <Row>
                  <Tooltip placement="bottom" title={statusChange ? "Active" : "Inactive"}>
                    <Switch
                      style={{
                        backgroundColor: statusChange === true ? "#1890FF" : "#d9d9d9",
                        marginBottom: 10,
                      }}
                      checked={statusChange}
                      onChange={(value) => {
                        setStatusChange(value);
                      }}
                      disabled={!userDetail.id || userDetail.id == currentUser}
                    />
                  </Tooltip>
                  </Row>
                  <Row>
                    <label style={{ color: "#475467" }}>Status “Active”</label>
                  </Row>
                </Form.Item>
              </Col>
            </Row>

          </div>
        </div>
      </Form>

    </div>
  );
};

export default UserDetail;
