import Icon from "@ant-design/icons";

const icon = () => (
  

<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 9.4209L1 9.4209" stroke="#8187A6" stroke-width="1.68421" stroke-linecap="round"/>
<path d="M13 5.21045L1 5.21045" stroke="#8187A6" stroke-width="1.68421" stroke-linecap="round"/>
<path d="M13 1L1 0.999999" stroke="#8187A6" stroke-width="1.68421" stroke-linecap="round"/>
</svg>
  


);

const Icon_Drag_Survey = (props) => <Icon component={icon} {...props} />;

export default Icon_Drag_Survey;

