import React, { useEffect, useState } from 'react';
import { Table , ConfigProvider,Row,Col,Pagination, Select } from 'antd';
import NoData from "../assets/img/NoData.png";
import { getVoiceTransaction } from '../service/reportService';
import {LeftOutlined,RightOutlined,} from "@ant-design/icons";

const VoiceTransactionReportTable = ({ data , onPageChange , dateRange }) => {
  const { Option } = Select;
  const columns = [
    {
      title: 'Date',
      dataIndex: 'dateTime', 
      key: 'dateTime',
      render: dateTime => dateTime || '-',
      sorter: (a, b) => a.dateTime.localeCompare(b.dateTime),
    },
    {
      title: 'Agent ID',
      dataIndex: 'agentId', 
      key: 'agentId',
      render: agentId => agentId || '-',
      sorter: (a, b) => a.agentId.localeCompare(b.agentId),
    },
    {
      title: 'Agent Name',
      dataIndex: 'agentName', 
      key: 'agentName',
      render: agentName => agentName || '-',
      sorter: (a, b) => a.agentName.localeCompare(b.agentName),
    },
    {
      title: 'Survey Type',
      dataIndex: 'surveyType', 
      key: 'surveyType',
      render: surveyType => surveyType || '-',
    },
    {
      title: 'Customer Name ',
      dataIndex: 'customerName', 
      key: 'customerName',
      render: customerName => customerName || '-',
      sorter: (a, b) => a.customerName.localeCompare(b.customerName),
    },
    {
      title: 'Mobile No.',
      dataIndex: 'tel', 
      key: 'tel',
      render: tel => tel || '-',
    },
    {
      title: 'Q1',
      dataIndex: 'q1', 
      key: 'q1',
      render: q1 => q1 || '-',
    },
    {
      title: 'Q2',
      dataIndex: 'q2', 
      key: 'q2',
      render: q2 => q2 || '-',
    },
    {
      title: 'Q3',
      dataIndex: 'q3', 
      key: 'q3',
      render: q3 => q3 || '-',
    },
    {
      title: 'Q4',
      dataIndex: 'q4', 
      key: 'q4',
      render: q4 => q4 || '-',
    },
    {
      title: 'Q5',
      dataIndex: 'q5', 
      key: 'q5',
      render: q5 => q5 || '-',
    },
  ];

  const [summaryText, setSummaryText] = useState('');


  useEffect(() => {
    if (data && data.pagination && data.pagination.totalCount) {
      const summaryText = `Total: ${data.pagination.totalCount} items`;
      setSummaryText(summaryText);
      console.log(summaryText); 
    }
  }, [data]);

  const handlePageChange = async (page, pageSize) => {
    try {
      if (dateRange && dateRange.length === 2) {
        const start = dateRange[0]?.format('YYYY-MM-DD');
        const end = dateRange[1]?.format('YYYY-MM-DD');

        const result = await getVoiceTransaction(start, end, page, pageSize);
        if (result && result.items && result.pagination) {
          onPageChange({ items: result.items, pagination: result.pagination });
        } else {
          console.error('Error: Incomplete data received.');
        }
      } else {
        console.error('Please select date range.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const itemRender = (_, type, originalElement) => {
   
    const { page, total, pageSize } = data.pagination;
    const totalPages = Math.ceil(data.pagination?.totalCount / pageSize);
    if (type === "prev") {
      if (page === 1) {
        return (
          <a style={{ pointerEvents: "none" }}>
            <LeftOutlined
              style={{
                color: "#d9d9d9",
              }}
            />
          </a>
        );
      }
      return (
        <a>
          <LeftOutlined
            style={{
              color: "#FF7F07",
            }}
          />
        </a>
      );
    }
    if (type === "next") {
      if (page === totalPages) {
        return (
          <a style={{ pointerEvents: "none" }}>
            <RightOutlined
              style={{
                // fontSize: "",
                color: "#d9d9d9",
              }}
            />
          </a>
        );
      }
      return (
        <a>
          <RightOutlined
            style={{
              // fontSize: "",
              color: "#FF7F07",
            }}
          />
        </a>
      );
    }
    return originalElement;
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#ffffff",
        },
        components: {
          Pagination: {
            itemActiveBg: "#FF7F07",
          },
        },
        pagination: {
          itemRender: (page, type, originalElement) => {
            if (type === 'prev') {
              return <a>Previous</a>;
            }
            if (type === 'next') {
              return <a>Next</a>;
            }
            return originalElement;
          },
        }
      }}
    >
      {data && data.items && data.items.length > 0 ? (
        <div>
        <Table
        columns={columns}
        dataSource={data.items}
        pagination={false}
        // pagination={{
        //   position: ['bottomCenter'],
        //   total: data.pagination.totalCount,
        //   showSizeChanger: true,
        //   pageSizeOptions: ['10', '25', '50' , '100'],
        //   onChange: handlePageChange, 
        //   current: data.pagination.currentPage, 
        //   pageSize: data.pagination.pageSize, 
        //   showTotal: (total, range) => <b>Total : {total} items</b>
        //   }}
          // summary={() => (
          //   <Table.Summary.Row>
          //     <Table.Summary.Cell index={0}>{summaryText}</Table.Summary.Cell>
          //   </Table.Summary.Row>
          // )}
        />
        <Row justify="space-between" align="middle" style={{ marginTop: 16 }}>
            <Col>
              <span
                style={{
                  color: "#667085",
                }}
              >
                <b>Total : {data.pagination?.totalCount} items</b>
              </span>
            </Col>
            <Col>
              <Pagination
                
                current={data.pagination?.page}
                pageSize={data.pagination?.pageSize}
                total={data.pagination?.totalCount}
                onChange={handlePageChange}
                itemRender={itemRender}
              />
            </Col>
            <Col>
              <Select
                size="middle"
                value={data.pagination?.pageSize}
                onChange={(pageSize) =>
                  handlePageChange(
                    data.pagination?.page,
                    pageSize
                  )
                }
                style={{
                  width: "130px",
                }}
              >
                <Option value={10}>10 / page</Option>
                <Option value={25}>25 / page</Option>
                <Option value={50}>50 / page</Option>
                <Option value={100}>100 / page</Option>
              </Select>
            </Col>
          </Row>
        </div>
      ) : (
        <div style={{ textAlign: 'center', padding: '20px', color: '#666' }}>
          <img style={{ margin: "auto"}} src={NoData} alt="No data" />
        </div>
      )}
    </ConfigProvider>
  );
}

export default VoiceTransactionReportTable;
